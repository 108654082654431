import { useRef } from 'react';
var useFocus = function() {
    var ref = useRef(null);
    var setFocus = function() {
        ref.current && ref.current.focus();
    };
    return [
        ref,
        setFocus
    ];
};
export default useFocus;
