import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getJob } from '../redux/actions';
/**
 * Sets `job` in the Redux store to the job with the ID `jobId`, if `jobId` is not in the URL, this hook does nothing
 */ var useCurrentJob = function() {
    var jobId = useParams().jobId;
    var job = useSelector(function(state) {
        return state.jobsReducer;
    }).job;
    var dispatch = useDispatch();
    useEffect(function() {
        if (!jobId) {
            return;
        }
        if (job && job.id === jobId) {
            return;
        }
        dispatch(getJob(jobId));
    }, [
        jobId
    ]);
    return job;
};
export default useCurrentJob;
