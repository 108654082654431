function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import _ from 'lodash/fp';
import { DRAW_LAYERS_MERGED } from '../components/mapView/constants';
import { computeDifference, computeIntersection, computeUnion } from '../components/mapView/geometry';
import { PRODUCT_TYPE } from '../constants/product';
import { selectionToGeoJsonNoGM } from './utilities';
var ADDON_TYPES = [
    PRODUCT_TYPE.BIM_EXTERIOR,
    PRODUCT_TYPE.BIM_INTERIOR,
    PRODUCT_TYPE.TWOD_PLAN_EXTERIOR,
    PRODUCT_TYPE.TWOD_PLAN_INTERIOR
];
var newLayer = function(geometry, productType, hovered, focused, selected) {
    return {
        geometry: geometry,
        productType: productType,
        hovered: hovered,
        focused: focused,
        selected: selected
    };
};
// given coverage of a particular product (e.g. Streetscape, Aerial, etc...)
//  crop the coverage layers to be within the provided `drawRegion`
// new layers wil adopt the provided `hovered`, `focused`, `selected` as object properties
export var drawProductLayers = function(productCoverage, drawRegion, hovered, focused, selected) {
    if (DRAW_LAYERS_MERGED[productCoverage.category_name]) {
        var mergedLayerGeometry;
        _.forEach(function(layer) {
            var layerGeometry = computeIntersection(layer.geometry, drawRegion);
            if (!layerGeometry) {
                return;
            }
            mergedLayerGeometry = mergedLayerGeometry ? computeUnion(mergedLayerGeometry, layerGeometry) : layerGeometry;
        })(productCoverage.layers);
        if (!mergedLayerGeometry) {
            console.warn('no merged layer geometry found');
            return [];
        }
        return [
            newLayer(mergedLayerGeometry, productCoverage.category_name, hovered, focused, selected)
        ];
    } else {
        return _.flow(_.map(function(layer) {
            var layerGeometry = computeIntersection(layer.geometry, drawRegion);
            return {
                layer: layer,
                layerGeometry: layerGeometry
            };
        }), _.filter(function(param) {
            var layerGeometry = param.layerGeometry;
            return !_.isEmpty(layerGeometry);
        }), _.map(function(param) {
            var layer = param.layer, layerGeometry = param.layerGeometry;
            return newLayer(layerGeometry, layer.category_name, hovered, focused, selected);
        }))(productCoverage.layers);
    }
};
var cachedDrawingLayers = {};
var drawSelectionProducts = function(param) {
    var coverage = param.coverage, selections = param.selections, activeSelection = param.activeSelection, _param_useCached = param.useCached, useCached = _param_useCached === void 0 ? false : _param_useCached;
    var selectionsByCategoryName = _.groupBy('category_name')(selections);
    var layers = [];
    var getSelectionRegion = function(selection) {
        var _selectionToGeoJsonNoGM;
        var isActiveSelectionAnAddon = ADDON_TYPES.includes(selection.category_name);
        if (isActiveSelectionAnAddon) {
            var _selectionToGeoJsonNoGM1, _selectionToGeoJsonNoGM2;
            var addOnParentId = selection.parent_selection_id;
            var parent = selections.find(function(selection) {
                return selection.id === addOnParentId;
            });
            // safe fallback
            if (!parent) {
                var _selectionToGeoJsonNoGM3;
                return (_selectionToGeoJsonNoGM3 = selectionToGeoJsonNoGM(selection)) === null || _selectionToGeoJsonNoGM3 === void 0 ? void 0 : _selectionToGeoJsonNoGM3.geometry;
            }
            return computeIntersection((_selectionToGeoJsonNoGM1 = selectionToGeoJsonNoGM(parent)) === null || _selectionToGeoJsonNoGM1 === void 0 ? void 0 : _selectionToGeoJsonNoGM1.geometry, (_selectionToGeoJsonNoGM2 = selectionToGeoJsonNoGM(selection)) === null || _selectionToGeoJsonNoGM2 === void 0 ? void 0 : _selectionToGeoJsonNoGM2.geometry);
        }
        return (_selectionToGeoJsonNoGM = selectionToGeoJsonNoGM(selection)) === null || _selectionToGeoJsonNoGM === void 0 ? void 0 : _selectionToGeoJsonNoGM.geometry;
    };
    _.forEach(function(productCoverage) {
        var draw = function(drawRegion, activeDrawRegion) {
            if (drawRegion) {
                var _layers;
                (_layers = layers).push.apply(_layers, _to_consumable_array(drawProductLayers(productCoverage, drawRegion, false, false, true)));
            }
            if (activeDrawRegion) {
                var _layers1;
                (_layers1 = layers).push.apply(_layers1, _to_consumable_array(drawProductLayers(productCoverage, activeDrawRegion, false, true, true)));
            }
        };
        if (useCached) {
            var cached = cachedDrawingLayers[productCoverage.category_name];
            if (cached) {
                draw(cached.drawRegion, cached.activeDrawRegion);
                return;
            }
        }
        var selections = _.filter('visible', selectionsByCategoryName[productCoverage.category_name]);
        if (!selections || selections.length === 0) {
            return;
        }
        var drawRegion;
        var activeDrawRegion = activeSelection && activeSelection.category_name === productCoverage.category_name ? getSelectionRegion(activeSelection) : null;
        _.forEach(function(selection) {
            var selectionRegion = getSelectionRegion(selection);
            if (!selectionRegion) {
                console.warn("no selection region found for ".concat(selection.name));
                return;
            }
            var isActive = !!activeSelection && selection.id === activeSelection.id;
            if (!isActive) {
                if (!drawRegion) {
                    drawRegion = selectionRegion;
                } else {
                    drawRegion = computeUnion(drawRegion, selectionRegion);
                }
            }
        })(selections);
        if (drawRegion && activeDrawRegion) {
            drawRegion = computeDifference(drawRegion, activeDrawRegion);
        }
        draw(drawRegion, activeDrawRegion);
        cachedDrawingLayers[productCoverage.category_name] = {
            drawRegion: drawRegion,
            activeDrawRegion: activeDrawRegion
        };
    })(coverage);
    return layers;
};
self.onmessage = function(e) {
    var _e_data = e.data, coverage = _e_data.coverage, selections = _e_data.selections, activeSelection = _e_data.activeSelection, useCached = _e_data.useCached;
    self.postMessage(drawSelectionProducts({
        coverage: coverage,
        selections: selections,
        activeSelection: activeSelection,
        useCached: useCached
    }));
};
export { };
