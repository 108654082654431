import _ from 'lodash/fp';
import flowFP from 'lodash/fp/flow';
import setFP from 'lodash/fp/set';
import { FETCH_STATUS } from '../../constants/project';
import { DOWNLOAD_ALL_STATUS, DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID, DOWNLOAD_ALL_UPDATE_STATUS, DOWNLOAD_ALL_URL, DOWNLOAD_ALL_URL_FAILURE, DOWNLOAD_ALL_URL_SUCCESS, GET_CURRENT_PROJECT, GET_CURRENT_PROJECT_FAILURE, GET_CURRENT_PROJECT_SUCCESS, LIST_PROJECTS, LIST_PROJECTS_FAILURE, LIST_PROJECTS_SUCCESS, LIST_PROJECT_USERS, LIST_PROJECT_USERS_SUCCESS, REGISTER_PROJECT_TRIAL_MEMBERS, REGISTER_PROJECT_TRIAL_MEMBERS_SUCCESS, SET_CURRENT_PROJECT, UPDATE_USER_PROJECT_TRIAL, UPDATE_USER_PROJECT_TRIAL_SUCCESS } from '../constants/project';
var defaultState = {
    downloadAll: {},
    getCurrentProjectStatus: FETCH_STATUS.IDLE,
    currentProject: undefined,
    projectTrialMembers: {
        isLoading: false,
        members: undefined
    },
    projectUsers: {},
    projects: {
        loading: false,
        error: null,
        data: []
    }
};
var projectReducer = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : defaultState, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case DOWNLOAD_ALL_UPDATE_STATUS:
            {
                var _action_payload = action.payload, projectId = _action_payload.projectId, status = _action_payload.status;
                return flowFP([
                    setFP([
                        'downloadAll',
                        projectId,
                        'status'
                    ], status)
                ])(state);
            }
        case DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID:
            {
                var _action_payload1 = action.payload, projectId1 = _action_payload1.projectId, queueJobId = _action_payload1.queueJobId;
                return flowFP(setFP([
                    'downloadAll',
                    projectId1,
                    'queueJobId'
                ], queueJobId))(state);
            }
        case DOWNLOAD_ALL_URL:
            {
                return flowFP([
                    setFP([
                        'downloadAll',
                        action.payload.projectId,
                        'isLoading'
                    ], true)
                ])(state);
            }
        case DOWNLOAD_ALL_URL_SUCCESS:
            {
                return flowFP([
                    setFP([
                        'downloadAll',
                        action.payload.projectId,
                        'isLoading'
                    ], false),
                    setFP([
                        'downloadAll',
                        action.payload.projectId,
                        'status'
                    ], DOWNLOAD_ALL_STATUS.IDLE)
                ])(state);
            }
        case DOWNLOAD_ALL_URL_FAILURE:
            {
                return flowFP([
                    setFP([
                        'downloadAll',
                        action.payload.projectId,
                        'isLoading'
                    ], false),
                    setFP([
                        'downloadAll',
                        action.payload.projectId,
                        'status'
                    ], DOWNLOAD_ALL_STATUS.IDLE)
                ])(state);
            }
        case REGISTER_PROJECT_TRIAL_MEMBERS:
            {
                return flowFP([
                    setFP([
                        'projectTrialMembers',
                        'isLoading'
                    ], true)
                ])(state);
            }
        case REGISTER_PROJECT_TRIAL_MEMBERS_SUCCESS:
            {
                return flowFP([
                    setFP([
                        'projectTrialMembers',
                        'isLoading'
                    ], false),
                    setFP([
                        'projectTrialMembers',
                        'members'
                    ], action.payload.members)
                ])(state);
            }
        case LIST_PROJECTS:
            {
                return _.set('projects.loading', true)(state);
            }
        case LIST_PROJECTS_SUCCESS:
            {
                var data = action.payload.data;
                return _.flow(_.set('projects.loading', false), _.set('projects.data', data))(state);
            }
        case LIST_PROJECTS_FAILURE:
            {
                var error = action.payload.error;
                return _.flow(_.set('projects.loading', false), _.set('projects.error', error))(state);
            }
        case LIST_PROJECT_USERS:
            {
                var projectId2 = action.payload.projectId;
                return flowFP([
                    setFP([
                        'projectUsers',
                        projectId2,
                        'isLoading'
                    ], true)
                ])(state);
            }
        case LIST_PROJECT_USERS_SUCCESS:
            {
                var _action_payload2 = action.payload, projectId3 = _action_payload2.projectId, users = _action_payload2.users;
                return flowFP([
                    setFP([
                        'projectUsers',
                        projectId3,
                        'isLoading'
                    ], false),
                    setFP([
                        'projectUsers',
                        projectId3,
                        'users'
                    ], users)
                ])(state);
            }
        case UPDATE_USER_PROJECT_TRIAL:
            {
                var _action_payload3 = action.payload, projectId4 = _action_payload3.projectId, userId = _action_payload3.userId;
                return flowFP([
                    setFP([
                        'projectUsers',
                        projectId4,
                        'invitation',
                        userId,
                        'isLoading'
                    ], true)
                ])(state);
            }
        case UPDATE_USER_PROJECT_TRIAL_SUCCESS:
            {
                var _action_payload4 = action.payload, projectId5 = _action_payload4.projectId, userId1 = _action_payload4.userId;
                return flowFP([
                    setFP([
                        'projectUsers',
                        projectId5,
                        'invitation',
                        userId1,
                        'isLoading'
                    ], false)
                ])(state);
            }
        case SET_CURRENT_PROJECT:
            {
                return flowFP([
                    setFP([
                        'currentProject'
                    ], action.payload.project),
                    setFP([
                        'getCurrentProjectStatus'
                    ], FETCH_STATUS.DONE)
                ])(state);
            }
        case GET_CURRENT_PROJECT:
            {
                return setFP([
                    'getCurrentProjectStatus'
                ], FETCH_STATUS.LOADING)(state);
            }
        case GET_CURRENT_PROJECT_SUCCESS:
            {
                return flowFP([
                    setFP([
                        'currentProject'
                    ], action.payload.project),
                    setFP([
                        'getCurrentProjectStatus'
                    ], FETCH_STATUS.DONE)
                ])(state);
            }
        case GET_CURRENT_PROJECT_FAILURE:
            {
                return flowFP([
                    setFP([
                        'getCurrentProjectStatus'
                    ], FETCH_STATUS.ERROR)
                ])(state);
            }
        default:
            return state;
    }
};
export default projectReducer;
