/**
 * The goal of this cache is to reduce the number of requests we send to the server by caching the coverage data.
 * To minimize the amount of cache misses, we'll cache the coverage in a grid of ~square tiles,
 * that way we can easily check if some geometry is already cached, by simply checking it's GeoHash.
 */ function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import _ from 'lodash/fp';
var defaultCoverageState = {
    hashes: [],
    caches: []
};
export var ADD_TO_COVERAGE_CACHE = 'ADD_TO_CACHE';
export var ADD_MANY_TO_COVERAGE_CACHE = 'ADD_MANY_TO_CACHE';
export var SET_COVERAGE_CACHE = 'SET_COVERAGE_CACHE';
var coverageReducer = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : defaultCoverageState, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case ADD_TO_COVERAGE_CACHE:
            {
                var _action_payload = action.payload, hash = _action_payload.hash, coverage = _action_payload.coverage;
                console.assert(!state.hashes.includes(hash));
                return _.flow(_.set('hashes', _to_consumable_array(state.hashes).concat([
                    hash
                ])), _.set('caches', _to_consumable_array(state.caches).concat([
                    coverage
                ])))(state);
            }
        case ADD_MANY_TO_COVERAGE_CACHE:
            {
                var _action_payload1 = action.payload, hashes = _action_payload1.hashes, coverage1 = _action_payload1.coverage;
                console.assert(hashes.length === _.uniq(hashes).length);
                // if all the hashes we want to add
                //  are already in state, skip
                if (_.difference(hashes, state.hashes).length == 0) {
                    return state;
                }
                // hashes may contain duplicates
                //  so remove the duplicates
                var hashesAfter = _.uniq(_.concat(state.hashes, hashes));
                return _.flow(_.set('hashes', hashesAfter), _.set('caches', _to_consumable_array(state.caches).concat([
                    coverage1
                ])))(state);
            }
        case SET_COVERAGE_CACHE:
            {
                var coverage2 = action.payload.coverage;
                return _.set('caches', [
                    coverage2
                ], state);
            }
        default:
            return state;
    }
};
export default coverageReducer;
