import { useEffect, useRef } from 'react';
var useStateAsRef = function(state) {
    var ref = useRef();
    useEffect(function() {
        ref.current = state;
    }, [
        state
    ]);
    return ref;
};
export default useStateAsRef;
