import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
export var useQuery = function() {
    var search = useLocation().search;
    return useMemo(function() {
        return new URLSearchParams(search);
    }, [
        search
    ]);
};
