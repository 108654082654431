function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import _ from 'lodash/fp';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBoolean, usePrevious } from 'react-use';
import useWorkerCallback from '../../hooks/useWorkerCallback';
import { setBoundaries, setUndefinedAddress } from '../../redux/actions';
import { isArrayEqual } from '../../utilities/lodash';
import { convertGMGeometryToGeoJson, hasPlaceStreetNumber } from '../../utilities/map';
import useGeocoder from '../RightMenu/useGeocoder';
import { DRAW_LAYERS_MERGED } from './constants';
import { computeIntersection, computeUnion } from './geometry';
import { MapViewContext } from './mapViewContext';
/**
 * `useMapRendering` is responsible for rendering coverage layers, selection regions, and boundaries (e.g. lot areas) on the map.
 */ var useMapRendering = function(param) {
    var onContextMenuClick = param.onContextMenuClick;
    var _jobsState_job, _jobsState_job1;
    var dispatch = useDispatch();
    var _useContext = useContext(MapViewContext), state = _useContext.state, actions = _useContext.actions;
    var _useSelector = useSelector(function(reduxState) {
        return {
            jobsState: reduxState.jobsReducer,
            mapState: reduxState.mapReducer
        };
    }), mapState = _useSelector.mapState, jobsState = _useSelector.jobsState;
    var _useBoolean = _sliced_to_array(useBoolean(false), 2), hasLoadedBoundaries = _useBoolean[0], setHasLoadedBoundaries = _useBoolean[1];
    var _useGeocoder = useGeocoder(), geocode = _useGeocoder.geocode, geocodeReady = _useGeocoder.geocodeReady;
    var isBoundaryVisible = useMemo(function() {
        var _jobsState_job, _jobsState_job1;
        return !(!jobsState.job || !((_jobsState_job = jobsState.job) === null || _jobsState_job === void 0 ? void 0 : _jobsState_job.lot_area_state)) && ((_jobsState_job1 = jobsState.job) === null || _jobsState_job1 === void 0 ? void 0 : _jobsState_job1.lot_area_state.visibility);
    }, [
        (_jobsState_job = jobsState.job) === null || _jobsState_job === void 0 ? void 0 : _jobsState_job.lot_area_state
    ]);
    var previousSelections = usePrevious(state.selections);
    var previousCoverage = usePrevious(state.coverage);
    var previousBoundaries = usePrevious(mapState.boundaries);
    var previousIsBoundaryVisible = usePrevious(isBoundaryVisible); // mapState.isBoundaryVisible
    var previousMapState = usePrevious(state.currentMapState);
    var previousMapLoadCount = usePrevious(state.mapLoadCount);
    /**
   * Draw an individual layer.
   */ var drawLayer = function(layerGeometry, layerProductType, hovered, focused, selected) {
        var isHovered = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : false;
        if (layerGeometry.type === 'Polygon') {
            state.mapManager.drawOverlayFromCoordinates(layerGeometry.coordinates, layerProductType, hovered, focused, selected, isHovered);
        } else if (layerGeometry.type === 'MultiPolygon') {
            _.forEach(function(polygon) {
                if (!polygon) {
                    console.warn('MultiPolygon contained empty polygon');
                    return;
                }
                state.mapManager.drawOverlayFromCoordinates(polygon, layerProductType, hovered, focused, selected, isHovered);
            })(layerGeometry.coordinates);
        } else {
            console.error('Unknown geometry type:', layerGeometry.type);
        }
    };
    /* ---------- Draw selection products ---------- */ var drawSelectionProductsWorker = useMemo(function() {
        return new Worker(new URL('../../workers/drawSelectionProducts.worker.js', import.meta.url));
    }, []);
    var _useState = _sliced_to_array(useState([]), 2), layersToDraw = _useState[0], setLayersToDraw = _useState[1];
    var drawSelectionProducts_ = useWorkerCallback(drawSelectionProductsWorker, function(layers) {
        setLayersToDraw(layers);
    });
    useEffect(function() {
        if (!state.mapManager) {
            return;
        }
        state.mapManager.clearAvailableData();
        _.forEach(function(param) {
            var geometry = param.geometry, productType = param.productType, hovered = param.hovered, focused = param.focused, selected = param.selected;
            drawLayer(geometry, productType, hovered, focused, selected, false);
        }, layersToDraw);
        _.forEach(function(selection) {
            if (selection.visible) {
                state.mapManager.drawSelectionRegion(selection, state.currentMapState, actions.makeSelectionActive, onContextMenuClick, state.selections);
            }
        })(state.selections);
    }, [
        layersToDraw
    ]);
    /* -------------------- */ var drawHoveredProductLayers = function(productCoverage, drawRegion, hovered, focused, selected) {
        if (DRAW_LAYERS_MERGED[productCoverage.category_name]) {
            var mergedLayerGeometry;
            _.forEach(function(layer) {
                var layerGeometry = computeIntersection(layer.geometry, drawRegion);
                if (!layerGeometry) {
                    return;
                }
                mergedLayerGeometry = mergedLayerGeometry ? computeUnion(mergedLayerGeometry, layerGeometry) : layerGeometry;
            })(productCoverage.layers);
            if (!mergedLayerGeometry) {
                console.warn('no merged layer geometry found');
                return;
            }
            drawLayer(mergedLayerGeometry, productCoverage.category_name, hovered, focused, selected, true);
        } else {
            _.forEach(function(layer) {
                var layerGeometry = computeIntersection(layer.geometry, drawRegion);
                if (!layerGeometry) {
                    return;
                }
                drawLayer(layerGeometry, layer.category_name, hovered, focused, selected, true);
            })(productCoverage.layers);
        }
    };
    /**
   * Draw coverage in the current region selection.
   */ var drawHoveredProduct = function() {
        var __values;
        var hoveredProduct = _.find([
            'category_name',
            state.hoveredProductType
        ])(state.coverageInRegionSelection);
        if (!hoveredProduct) {
            return;
        }
        if (_.isEmpty(state.debouncedRegionSelection)) {
            return null;
        }
        var region = (__values = _.values(state.debouncedRegionSelection)) === null || __values === void 0 ? void 0 : __values[0];
        if (_.isEmpty(region)) {
            return null;
        }
        var regionFeature = convertGMGeometryToGeoJson(region);
        drawHoveredProductLayers(hoveredProduct, regionFeature, true, false, true);
    };
    var drawBoundaries = function() {
        var _mapState_boundaries_region;
        state.mapManager.drawDashedOverlay(((_mapState_boundaries_region = mapState.boundaries.region) === null || _mapState_boundaries_region === void 0 ? void 0 : _mapState_boundaries_region.coordinates[0]) || []);
    };
    /**
   * Draw available products when hovered or in selections, and draw boundaries.
   */ useEffect(function() {
        if (!state.mapManager) {
            return;
        }
        var canDrawHovered = !!(!state.hoveredProductType || state.hoveredProductType && !_.isEmpty(state.coverageInRegionSelection));
        var canDrawSelections = !!(state.selections && state.coverage);
        var canDrawBoundaries = !!mapState.boundaries;
        var updateHovered = canDrawHovered;
        var updateSelections = canDrawSelections && (!isArrayEqual(state.selections, previousSelections) || !isArrayEqual(state.coverage, previousCoverage) || !_.isEqual(state.currentMapState, previousMapState) || state.mapLoadCount !== previousMapLoadCount);
        // !_.isEqual(state.mapManager, previousMapManager));
        var updateBoundaries = canDrawBoundaries && (isBoundaryVisible !== previousIsBoundaryVisible || !_.isEqual(mapState.boundaries, previousBoundaries));
        if (!updateHovered && !updateSelections && !updateBoundaries) {
            return;
        }
        if (canDrawHovered) {
            state.mapManager.clearHoveredData();
            drawHoveredProduct();
        }
        if (canDrawSelections) {
            drawSelectionProducts_({
                coverage: state.coverage,
                selections: state.selections,
                activeSelection: state.activeSelection,
                useCached: false
            });
        }
        if (canDrawBoundaries) {
            state.mapManager.clearBoundariesData();
            if (isBoundaryVisible) {
                drawBoundaries();
            }
        }
    }, [
        state.hoveredProductType,
        // state.coverageInRegionSelection,
        state.selections,
        state.coverage,
        state.mapManager,
        state.mapLoadCount,
        // mapState.boundaries,
        state.currentMapState,
        isBoundaryVisible
    ]);
    // Observe jobsState.job.lot_area_state to be used in template
    var lotAreaState = useMemo(function() {
        var _jobsState_job, _jobsState_job1;
        if (!jobsState.job || !((_jobsState_job = jobsState.job) === null || _jobsState_job === void 0 ? void 0 : _jobsState_job.lot_area_state)) return null;
        return (_jobsState_job1 = jobsState.job) === null || _jobsState_job1 === void 0 ? void 0 : _jobsState_job1.lot_area_state;
    }, [
        (_jobsState_job1 = jobsState.job) === null || _jobsState_job1 === void 0 ? void 0 : _jobsState_job1.lot_area_state
    ]);
    // Set mapState.boundaries on first load to trigger drawBoundaries()
    useEffect(function() {
        if (!hasLoadedBoundaries && jobsState.job && jobsState.job.user_order) {
            var _jobsState_job_user_order;
            setHasLoadedBoundaries(true);
            dispatch(setBoundaries((_jobsState_job_user_order = jobsState.job.user_order) === null || _jobsState_job_user_order === void 0 ? void 0 : _jobsState_job_user_order.property));
        }
    }, [
        hasLoadedBoundaries,
        jobsState.job
    ]);
    // To handle undefined address
    // same existing condition in mapView.js -> handlePlaceChange
    useEffect(function() {
        if (!geocodeReady) {
            return;
        }
        if (state.address) {
            _async_to_generator(function() {
                var concatenatedAddress, place;
                return _ts_generator(this, function(_state) {
                    switch(_state.label){
                        case 0:
                            concatenatedAddress = Object.values(state.address).filter(function(v) {
                                return !!v;
                            }).join(', ');
                            return [
                                4,
                                geocode(concatenatedAddress)
                            ];
                        case 1:
                            place = _state.sent();
                            if (!hasPlaceStreetNumber(place)) {
                                dispatch(setUndefinedAddress(true));
                            }
                            return [
                                2
                            ];
                    }
                });
            })();
        }
    }, [
        state.address,
        geocodeReady
    ]);
    return {
        lotAreaState: lotAreaState
    };
};
export default useMapRendering;
