import { useEffect } from 'react';
export function useClickOutside(ref, callback) {
    var excludedSelectors = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [], condition = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false;
    useEffect(function() {
        var handleClickOutside = function handleClickOutside(event) {
            if (!condition) {
                if ((ref === null || ref === void 0 ? void 0 : ref.current) && !ref.current.contains(event.target)) {
                    var excludedElementDetectedInDOM = excludedSelectors.some(function(selector) {
                        return document.querySelector(selector);
                    });
                    if (excludedSelectors.length && excludedElementDetectedInDOM) {
                        var excludededElementsContainsTarget = excludedSelectors.some(function(selector) {
                            var el = document.querySelectorAll(selector);
                            if (el.length) {
                                return Array.from(el).some(function(element) {
                                    return element === null || element === void 0 ? void 0 : element.contains(event.target);
                                });
                            }
                            return false;
                        });
                        !excludededElementsContainsTarget && callback(event);
                    } else {
                        callback(event);
                    }
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return function() {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [
        ref,
        condition
    ]);
}
