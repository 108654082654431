function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as turf from '@turf/turf';
import _ from 'lodash/fp';
import { usePrevious } from 'react-use';
import { PRODUCT_TYPE } from '../../constants/product';
import useWorkerCallback from '../../hooks/useWorkerCallback';
import { formatSqmArea } from '../../text';
import { textShadowStyle } from '../../utilities';
import { formatMonth } from '../../utilities/date';
import { isArrayEqual } from '../../utilities/lodash';
import { selectionToGeoJsonNoGM } from '../../workers/utilities';
import { PRODUCT_DATA } from './constants';
import { computeVisualCenter } from './geometry';
import { MapViewContext } from './mapViewContext';
var MapLabel = null;
var MAP_LABEL_OVERLAY_TYPE = {
    AREA: 'AREA',
    DATE: 'DATE'
};
/**
 * `useCoverageLabels` renders the coverage labels onto the map.
 */ var useCoverageLabels = function() {
    var state = useContext(MapViewContext).state;
    var _useState = _sliced_to_array(useState([]), 2), mapOverlays = _useState[0], setMapOverlays = _useState[1];
    var _useState1 = _sliced_to_array(useState([]), 2), newLabels = _useState1[0], setNewLabels = _useState1[1];
    var previousSelections = usePrevious(state.selections);
    var previousCoverage = usePrevious(state.coverage);
    useEffect(function() {
        _async_to_generator(function() {
            var tempMapLabel;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        if (!(state.googleMapsScriptStatus === 'ready')) return [
                            3,
                            2
                        ];
                        return [
                            4,
                            import('./mapLabel')
                        ];
                    case 1:
                        tempMapLabel = _state.sent();
                        MapLabel = tempMapLabel.MapLabel;
                        _state.label = 2;
                    case 2:
                        return [
                            2
                        ];
                }
            });
        })().catch(function(err) {
            console.error('Error loading MapLabel:', err);
        });
    }, [
        state.googleMapsScriptStatus
    ]);
    var getCoverageLabelsWorker = useMemo(function() {
        return new Worker(new URL('../../workers/getCoverageLabels.worker.js', import.meta.url));
    }, []);
    var updateCoverageLabels = useWorkerCallback(getCoverageLabelsWorker, function(labels) {
        setNewLabels(labels);
    });
    var cleanUpMapOverlays = useCallback(function() {
        var overlayType = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : MAP_LABEL_OVERLAY_TYPE.DATE;
        if (!overlayType) return;
        setMapOverlays(function(currentOverlays) {
            var hasChanges = false;
            var remainingOverlays = currentOverlays.filter(function(row) {
                if (overlayType === row.type) {
                    if (row.overlay && row.overlay.setMap) {
                        row.overlay.setMap(null);
                    }
                    hasChanges = true;
                    return null;
                }
                return row;
            });
            return hasChanges ? remainingOverlays : currentOverlays;
        });
    }, []);
    var createAreaLabelOnSelection = useCallback(function() {
        cleanUpMapOverlays(MAP_LABEL_OVERLAY_TYPE.AREA);
        if (state.activeSelection && state.activeSelection.region && state.coveragePerProduct) {
            var _state_activeSelection = state.activeSelection, id = _state_activeSelection.id, category_name = _state_activeSelection.category_name;
            var coverage = _.find([
                'selection_id',
                id
            ])(state.coveragePerProduct[category_name]);
            if (!coverage) {
                return;
            }
            var geom = selectionToGeoJsonNoGM(state.activeSelection).geometry;
            var visualCenter = computeVisualCenter(geom);
            if (category_name === PRODUCT_TYPE.STREETSCAPE) {
                var polygons = coverage.geometry.coordinates && coverage.geometry.coordinates.map(function(coords) {
                    return {
                        type: 'Polygon',
                        coordinates: coords
                    };
                });
                var areas = polygons.map(function(polygon) {
                    return {
                        polygon: polygon,
                        area: turf.area(turf.polygon(polygon.coordinates))
                    };
                });
                var _areas_reduce = areas.reduce(function(max, current) {
                    return current.area > max.area ? current : max;
                }, {
                    area: 0
                }), largestPolygon = _areas_reduce.polygon;
                // largestPolygon should not be undefined
                if (largestPolygon) {
                    visualCenter = computeVisualCenter(largestPolygon);
                }
            }
            var _visualCenter_coordinates = _sliced_to_array(visualCenter.coordinates, 2), lng = _visualCenter_coordinates[0], lat = _visualCenter_coordinates[1];
            var fontColor = PRODUCT_DATA.entities[category_name].display_color;
            // in case coverage doesn't return an area, we're not showing the area label
            if (coverage.area_in_sqm > 0) {
                setMapOverlays(function(overlays) {
                    return _to_consumable_array(overlays).concat([
                        {
                            type: MAP_LABEL_OVERLAY_TYPE.AREA,
                            overlay: new MapLabel(new google.maps.LatLng(lat, lng), formatSqmArea(coverage.area_in_sqm), 'transparent', state.map, fontColor, textShadowStyle('2px', "#FAFAFA"))
                        }
                    ]);
                });
            }
        }
    }, [
        cleanUpMapOverlays,
        state.activeSelection,
        state.coveragePerProduct,
        state.map
    ]);
    useEffect(function() {
        cleanUpMapOverlays(MAP_LABEL_OVERLAY_TYPE.DATE);
        var overlays = [];
        newLabels.forEach(function(label) {
            overlays.push({
                type: MAP_LABEL_OVERLAY_TYPE.DATE,
                overlay: new MapLabel(new google.maps.LatLng(label.position[1], label.position[0]), formatMonth(label.dateAcquired * 1000), label.displayColor, state.map)
            });
        });
        setMapOverlays(function(overlay) {
            return _to_consumable_array(overlay).concat(_to_consumable_array(overlays));
        });
        // On Select/Click shape show Area label
        createAreaLabelOnSelection();
    }, [
        cleanUpMapOverlays,
        createAreaLabelOnSelection,
        newLabels,
        state.map
    ]);
    var checkAndRepositionMapLabels = useCallback(function() {
        var avoidOverlayClash = function(overlays) {
            var padding = 0.00005; // rought distance only, improvements would be great
            overlays.forEach(function(overlayA, i) {
                var posA = overlayA.overlay.position;
                overlays.slice(i + 1).forEach(function(param) {
                    var overlayB = param.overlay;
                    var posB = overlayB.position;
                    if (arePositionsTooClose(posA, posB, padding)) {
                        var adjustedPos = {
                            lat: posB.lat() + padding,
                            lng: posB.lng() + padding
                        };
                        overlayB.position = new google.maps.LatLng(adjustedPos.lat, adjustedPos.lng);
                    }
                });
            });
        };
        var arePositionsTooClose = function(posA, posB, padding) {
            return Math.abs(posA.lat() - posB.lat()) < padding && Math.abs(posA.lng() - posB.lng()) < padding;
        };
        if (mapOverlays.length > 0) {
            avoidOverlayClash(mapOverlays, state.map) || [];
        }
    }, [
        mapOverlays,
        state.map
    ]);
    useEffect(function() {
        checkAndRepositionMapLabels();
    }, [
        checkAndRepositionMapLabels
    ]);
    /**
   * Draw map labels (date indicators) whenever coverage in selections change.
   */ useEffect(function() {
        var _window;
        if (!((_window = window) === null || _window === void 0 ? void 0 : _window.Worker)) {
            return;
        }
        if (!MapLabel) {
            return;
        }
        if (!state.selections || !state.coverage || isArrayEqual(state.selections, previousSelections, [
            'focused'
        ]) && isArrayEqual(state.coverage, previousCoverage)) {
            return;
        }
        updateCoverageLabels({
            coverage: state.coverage,
            selections: state.selections
        });
    }, [
        state.selections,
        state.coverage,
        previousSelections,
        previousCoverage,
        updateCoverageLabels
    ]);
};
export default useCoverageLabels;
