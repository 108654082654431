/**
 * It's unlikely you want to use this hook directly, most often it is used with the <Tooltip /> component.
 */ function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { arrow, autoUpdate, flip, offset, safePolygon, shift, useDismiss, useFloating, useFocus, useHover, useInteractions, useRole } from '@floating-ui/react';
import { useMemo, useRef, useState } from 'react';
var useTooltip = function(param) {
    var placement = param.placement, _param_keepOpenOnHover = param.keepOpenOnHover, keepOpenOnHover = _param_keepOpenOnHover === void 0 ? false : _param_keepOpenOnHover, controlledOpen = param.open, setControlledOpen = param.setOpen;
    var _useState = _sliced_to_array(useState(false), 2), uncontrolledOpen = _useState[0], setUncontrolledOpen = _useState[1];
    var open = controlledOpen !== null && controlledOpen !== void 0 ? controlledOpen : uncontrolledOpen;
    var setOpen = setControlledOpen !== null && setControlledOpen !== void 0 ? setControlledOpen : setUncontrolledOpen;
    var arrowRef = useRef(null);
    var floating = useFloating({
        placement: placement,
        open: open,
        onOpenChange: setOpen,
        whileElementsMounted: autoUpdate,
        middleware: [
            offset(8),
            shift({
                padding: 8
            }),
            flip(),
            arrow({
                element: arrowRef
            })
        ]
    });
    var hover = useHover(floating.context, _object_spread({
        move: false
    }, keepOpenOnHover && {
        handleClose: safePolygon()
    }));
    var focus = useFocus(floating.context, {
        move: false
    });
    var dismiss = useDismiss(floating.context);
    var role = useRole(floating.context, {
        role: 'tooltip'
    });
    var interactions = useInteractions([
        hover,
        focus,
        dismiss,
        role
    ]);
    return useMemo(function() {
        return _object_spread({
            open: open,
            setOpen: setOpen,
            arrowRef: arrowRef
        }, interactions, floating);
    }, [
        open,
        setOpen,
        interactions,
        floating
    ]);
};
export default useTooltip;
