export var profileConstants = {
    FETCH_USER_DETAILS_LOADING: 'FETCH_USER_DETAILS_LOADING',
    FETCH_USER_DETAILS_SUCCESS: 'FETCH_USER_DETAILS_SUCCESS',
    FETCH_USER_DETAILS_FAILURE: 'FETCH_USER_DETAILS_FAILURE',
    UPDATE_USER_DETAILS_LOADING: 'UPDATE_USER_DETAILS_LOADING',
    UPDATE_USER_DETAILS_SUCCESS: 'UPDATE_USER_DETAILS_SUCCESS',
    UPDATE_USER_DETAILS_ERROR: 'UPDATE_USER_DETAILS_ERROR',
    RESET_USER_FORM: 'RESET_USER_FORM',
    SET_COMPANY_DETAILS: 'SET_COMPANY_DETAILS',
    UPDATE_COMPANY_DETAILS_LOADING: 'UPDATE_COMPANY_DETAILS_LOADING',
    UPDATE_COMPANY_DETAILS_SUCCESS: 'UPDATE_COMPANY_DETAILS_SUCCESS',
    UPDATE_COMPANY_DETAILS_ERROR: 'UPDATE_COMPANY_DETAILS_ERROR',
    RESET_COMPANY_FORM: 'RESET_COMPANY_FORM',
    UPDATE_MAP_TYPE: 'UPDATE_MAP_TYPE',
    LIST_USER_CARDS: 'LIST_USER_CARDS',
    LIST_USER_CARDS_SUCCESS: 'LIST_USER_CARDS_SUCCESS',
    LIST_USER_CARDS_FAILED: 'LIST_USER_CARDS_FAILED',
    GET_MEMBERSHIP_PRICES: 'GET_MEMBERSHIP_PRICES',
    GET_MEMBERSHIP_PRICES_LOADING: 'GET_MEMBERSHIP_PRICES_LOADING',
    GET_MEMBERSHIP_PRICES_SUCCESS: 'GET_MEMBERSHIP_PRICES_SUCCESS',
    GET_MEMBERSHIP_RECEIPT: 'GET_MEMBERSHIP_RECEIPT',
    GET_MEMBERSHIP_RECEIPT_SUCCESS: 'GET_MEMBERSHIP_RECEIPT_SUCCESS',
    GET_MEMBERSHIP_RECEIPT_FAILED: 'GET_MEMBERSHIP_RECEIPT_FAILED',
    REFRESH_CUSTOMER_SUCCESS: 'REFRESH_CUSTOMER_SUCCESS',
    GET_MEMBERSHIP_DETAILS: 'GET_MEMBERSHIP_DETAILS',
    GET_MEMBERSHIP_DETAILS_SUCCESS: 'GET_MEMBERSHIP_DETAILS_SUCCESS',
    UPSERT_USER_MEMBERSHIP: 'UPSERT_USER_MEMBERSHIP',
    UPSERT_USER_MEMBERSHIP_SUCCESS: 'UPSERT_USER_MEMBERSHIP_SUCCESS',
    UPSERT_USER_MEMBERSHIP_DONE: 'UPSERT_USER_MEMBERSHIP_DONE',
    SEND_MEMBERSHIP_PAYMENT: 'SEND_MEMBERSHIP_PAYMENT',
    SEND_MEMBERSHIP_PAYMENT_SUCCESS: 'SEND_MEMBERSHIP_PAYMENT_SUCCESS',
    SEND_MEMBERSHIP_PAYMENT_FAILED: 'SEND_MEMBERSHIP_PAYMENT_FAILED',
    SET_HAS_USED_TRIAL: 'SET_HAS_USED_TRIAL',
    SET_PRETEND_TYPE: 'SET_PRETEND_TYPE'
};
