var registerServiceWorker = function() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', function() {
            console.log('Registering worker');
            navigator.serviceWorker.register('./event-worker.js', {
                scope: '/'
            }).then(function() {
                return console.log('Service worker register success');
            }, function(error) {
                console.error("Service worker registration failed: ".concat(error));
            });
        });
    }
};
export default registerServiceWorker;
