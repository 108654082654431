import flow from 'lodash/fp/flow';
import identity from 'lodash/fp/identity';
import isUndefined from 'lodash/fp/isUndefined';
import pull from 'lodash/fp/pull';
import set from 'lodash/fp/set';
import { DELETE_ACTIVITY, DISABLE_ACTIVITY_FILTER, ENABLE_ACTIVITY_FILTER, FILTER_ACTIVITIES, LIST_ACTIVITIES, LIST_ACTIVITIES_SUCCESS, TOGGLE_STAFF_ACTIVITIES } from '../constants/activity';
import { DELETE_ASSET } from '../constants/asset';
var defaultMapState = {
    projects: {},
    enabledFilters: [],
    isStaffActivityListVisible: false
};
var activityReducer = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : defaultMapState, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case LIST_ACTIVITIES:
            {
                return flow([
                    set([
                        'projects',
                        action.payload.projectId,
                        'ui',
                        'isLoading'
                    ], true)
                ])(state);
            }
        case TOGGLE_STAFF_ACTIVITIES:
            {
                return flow([
                    set('isStaffActivityListVisible', !state.isStaffActivityListVisible)
                ])(state);
            }
        case LIST_ACTIVITIES_SUCCESS:
            {
                var _action_payload = action.payload, projectId = _action_payload.projectId, activities = _action_payload.activities;
                return flow([
                    set([
                        'projects',
                        projectId,
                        'ui',
                        'isLoading'
                    ], false),
                    set([
                        'projects',
                        projectId,
                        'activities'
                    ], activities),
                    set([
                        'projects',
                        projectId,
                        'nonFilteredActivities'
                    ], activities)
                ])(state);
            }
        case FILTER_ACTIVITIES:
            {
                var _state_projects_projectId;
                var _action_payload1 = action.payload, projectId1 = _action_payload1.projectId, activities1 = _action_payload1.activities;
                return flow([
                    set([
                        'projects',
                        projectId1,
                        'activities'
                    ], activities1),
                    isUndefined((_state_projects_projectId = state.projects[projectId1]) === null || _state_projects_projectId === void 0 ? void 0 : _state_projects_projectId.nonFilteredActivities) ? set([
                        'projects',
                        projectId1,
                        'nonFilteredActivities'
                    ], activities1) : identity
                ])(state);
            }
        case ENABLE_ACTIVITY_FILTER:
            {
                var filterName = action.payload.filterName;
                return flow([
                    set([
                        'enabledFilters'
                    ], state.enabledFilters.concat(filterName))
                ])(state);
            }
        case DISABLE_ACTIVITY_FILTER:
            {
                var filterName1 = action.payload.filterName;
                return flow([
                    set([
                        'enabledFilters'
                    ], pull(filterName1, state.enabledFilters))
                ])(state);
            }
        case DELETE_ACTIVITY:
            {
                var _state_projects;
                var _action_payload2 = action.payload, projectId2 = _action_payload2.projectId, activityId = _action_payload2.activityId;
                var _ref = ((_state_projects = state.projects) === null || _state_projects === void 0 ? void 0 : _state_projects[projectId2]) || {}, _ref_activities = _ref.activities, activities2 = _ref_activities === void 0 ? [] : _ref_activities, _ref_nonFilteredActivities = _ref.nonFilteredActivities, nonFilteredActivities = _ref_nonFilteredActivities === void 0 ? [] : _ref_nonFilteredActivities;
                var filteredActivities = activities2.filter(function(activity) {
                    return activity.id !== activityId;
                });
                var filteredNonFilteredActivities = nonFilteredActivities.filter(function(activity) {
                    return activity.id !== activityId;
                });
                return flow([
                    set([
                        'projects',
                        projectId2,
                        'activities'
                    ], filteredActivities),
                    set([
                        'projects',
                        projectId2,
                        'nonFilteredActivities'
                    ], filteredNonFilteredActivities)
                ])(state);
            }
        case DELETE_ASSET:
            {
                var _action_payload3 = action.payload, projectId3 = _action_payload3.projectId, activityId1 = _action_payload3.activityId, assetId = _action_payload3.assetId;
                var index = state.projects[projectId3].activities.findIndex(function(activity) {
                    return activity.id === activityId1;
                });
                var assets = state.projects[projectId3].activities[index].created_assets.filter(function(asset) {
                    return asset.id !== assetId;
                });
                return flow([
                    set([
                        'projects',
                        projectId3,
                        'activities',
                        index,
                        'created_assets'
                    ], assets)
                ])(state);
            }
        default:
            return state;
    }
};
export default activityReducer;
