function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import * as turf from '@turf/turf';
import axios from 'axios';
import _ from 'lodash/fp';
var multiPolygonToPolygons = function(multiPolygon) {
    return _.map(function(polygonCoords) {
        return turf.polygon(polygonCoords).geometry;
    }, multiPolygon.coordinates);
};
export var getHighResCollections = function() {
    var _ref = _async_to_generator(function() {
        var data;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        axios.get("".concat(process.env.AEROMETREX_STAC_URL, "/collections"))
                    ];
                case 1:
                    data = _state.sent().data;
                    return [
                        2,
                        data.collections
                    ];
            }
        });
    });
    return function getHighResCollections() {
        return _ref.apply(this, arguments);
    };
}();
export var getHighResCollectionsContainsBBox = function(collections, bbox) {
    var bboxGeom = turf.bboxPolygon(bbox);
    return _.filter(function(collection) {
        var extentGeom = turf.bboxPolygon(collection.extent.spatial.bbox[0]);
        return turf.booleanContains(extentGeom, bboxGeom);
    }, collections);
};
export var EXCLUDED_LAYERNAMES = [
    'Melbourne_Centre_North_2021_2_GM'
];
// bbox = [minx, miny, maxx, maxy] in epsg:4326
export var getHighResLayers = function() {
    var _ref = _async_to_generator(function(collectionId, bbox) {
        var offset, limit, initialParams, features, nextLink, _features, __find, data, rawNextLink, _rawNextLink_split, base, search, rawParams, withDatetimeOmitted, bboxGeom, filtered;
        var _arguments = arguments;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    offset = _arguments.length > 2 && _arguments[2] !== void 0 ? _arguments[2] : 0, limit = _arguments.length > 3 && _arguments[3] !== void 0 ? _arguments[3] : 50;
                    initialParams = new URLSearchParams({
                        bbox: bbox.join(','),
                        offset: offset,
                        limit: limit
                    });
                    features = [];
                    nextLink = "".concat(process.env.AEROMETREX_STAC_URL, "/collections/").concat(collectionId, "/items?").concat(initialParams.toString());
                    // todo: do in parallel if the next link is predictable
                    console.log(collectionId, nextLink);
                    _state.label = 1;
                case 1:
                    if (!nextLink) return [
                        3,
                        3
                    ];
                    return [
                        4,
                        axios.get(nextLink)
                    ];
                case 2:
                    data = _state.sent().data;
                    (_features = features).push.apply(_features, _to_consumable_array(data.features));
                    // we need to omit 'datetime' from the query to get the avoid errors
                    rawNextLink = (__find = _.find({
                        rel: 'next'
                    }, data.links)) === null || __find === void 0 ? void 0 : __find.href;
                    if (rawNextLink) {
                        _rawNextLink_split = _sliced_to_array(rawNextLink.split('?'), 2), base = _rawNextLink_split[0], search = _rawNextLink_split[1];
                        rawParams = new URLSearchParams(search);
                        withDatetimeOmitted = new URLSearchParams(_.omit([
                            'datetime'
                        ], Object.fromEntries(rawParams)));
                        nextLink = "".concat(base, "?").concat(withDatetimeOmitted.toString());
                    } else {
                        nextLink = null;
                    }
                    return [
                        3,
                        1
                    ];
                case 3:
                    bboxGeom = turf.bboxPolygon(bbox);
                    filtered = _.filter(function(feature) {
                        var layername = feature.properties.layername;
                        if (layername.includes('CIR')) {
                            return false;
                        }
                        if (EXCLUDED_LAYERNAMES.includes(layername)) {
                            return false;
                        }
                        var geom = turf.feature(feature.geometry).geometry;
                        // turf.booleanContains does not work with MultiPolygon
                        // so split into Polygons and check that one of them contains the bbox
                        var geoms = geom.type === 'MultiPolygon' ? multiPolygonToPolygons(geom) : [
                            geom
                        ];
                        var contains = _.some(function(g) {
                            return turf.booleanContains(g, bboxGeom);
                        }, geoms);
                        return contains;
                    }, features);
                    console.log("[".concat(collectionId, "] ").concat(features.length, " -> ").concat(filtered.length, " features"));
                    return [
                        2,
                        filtered
                    ];
            }
        });
    });
    return function getHighResLayers(collectionId, bbox) {
        return _ref.apply(this, arguments);
    };
}();
export var groupHighResLayersByDate = function(features) {
    return _.groupBy(function(feature) {
        return feature.properties.datetime.split('T')[0];
    }, features);
};
