import { useEffect, useRef } from 'react';
var useEventListener = function(eventName, handler, element, options) {
    var savedHandler = useRef(handler);
    useEffect(function() {
        savedHandler.current = handler;
    }, [
        handler
    ]);
    useEffect(function() {
        var _element_current;
        var targetElement = (_element_current = element === null || element === void 0 ? void 0 : element.current) !== null && _element_current !== void 0 ? _element_current : window;
        if (!(targetElement && targetElement.addEventListener)) {
            return;
        }
        var listener = function(event) {
            savedHandler.current(event);
        };
        targetElement.addEventListener(eventName, listener, options);
        return function() {
            targetElement.removeEventListener(eventName, listener, options);
        };
    }, [
        element,
        options
    ]);
};
export default useEventListener;
