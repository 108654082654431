import _ from 'lodash/fp';
import { groupBy, map, maxBy } from 'lodash';
import { PRODUCT_DATA } from '../components/mapView/constants';
import { computeIntersection, computeUnion } from '../components/mapView/geometry';
import { filterProductCoverageLayersByIntersection } from '../utilities/coverage';
import { selectionToGeoJsonNoGM } from './utilities';
export var getCoverageLabels = function(param) {
    var coverage = param.coverage, selections = param.selections;
    var labels = [];
    var addLabel = function(layer) {
        layer.marker_locations.forEach(function(position) {
            labels.push({
                position: position,
                displayColor: PRODUCT_DATA.entities[layer.category_name].display_color,
                dateAcquired: layer.acquired_at
            });
        });
    };
    var selectionsByCategoryName = _.groupBy('category_name')(selections);
    _.forEach(function(productCoverage) {
        var productSelections = _.filter('visible', selectionsByCategoryName[productCoverage.category_name]);
        if (_.isEmpty(productSelections)) {
            return;
        }
        var drawRegion;
        _.forEach(function(selection) {
            var selectionRegion = selectionToGeoJsonNoGM(selection).geometry;
            if (!drawRegion) {
                drawRegion = selectionRegion;
            } else {
                drawRegion = computeUnion(drawRegion, selectionRegion);
            }
        }, productSelections);
        if (!drawRegion) {
            return;
        }
        productCoverage = filterProductCoverageLayersByIntersection(productCoverage, drawRegion);
        var layers = _.filter('acquired_at', productCoverage.layers);
        if (_.isEmpty(layers)) {
            return;
        }
        _.forEach(function(layer) {
            var layerGeometry = computeIntersection(layer.geometry, drawRegion);
            if (!layerGeometry) {
                return;
            }
            addLabel(layer);
        }, layers);
    }, coverage);
    // get only latest date for connflicted/duplicated positions
    return map(groupBy(labels, function(item) {
        return item.position.join(',');
    }), function(group) {
        return maxBy(group, 'dateAcquired');
    });
};
self.onmessage = function(e) {
    var _e_data = e.data, coverage = _e_data.coverage, selections = _e_data.selections;
    self.postMessage(getCoverageLabels({
        coverage: coverage,
        selections: selections
    }));
};
export { };
