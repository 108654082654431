function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { get } from 'lodash';
import debounce from 'lodash/fp/debounce';
import isEmpty from 'lodash/fp/isEmpty';
import keyBy from 'lodash/fp/keyBy';
import noop from 'lodash/noop';
import { JOB_FAILURE_MODAL } from '../../components/modal/JobFailureModal';
import { ORDER_FAILURE_MODAL } from '../../components/modal/OrderFailureModal';
import { RESET_FORM_DELAY } from '../../pages/constants';
import routes from '../../routes/constants';
import history from '../../routes/history';
import text from '../../text';
import { client, larkiApi } from '../../utilities/api';
import { captureError } from '../../utilities/error';
import { jobsConstants, mapConstants } from '../constants';
import { alertError, alertSuccess } from './index';
import { showModal } from './modal';
import { redirectTo } from '../../hooks/useRedirect';
export var processJobList = function(data) {
    var _data_projects;
    return keyBy('project_id', (_data_projects = data.projects) === null || _data_projects === void 0 ? void 0 : _data_projects.map(function(project) {
        return _object_spread_props(_object_spread({}, !isEmpty(project.jobs) ? project.jobs[0] : {
            projectId: project.id
        }), {
            user_project: project.user_project,
            project: project,
            jobs: project.jobs,
            point_clouds: project.point_clouds,
            created_at: project.created_at,
            creator: project.creator,
            updater: project.updater,
            updated_at: project.updated_at
        });
    }));
};
export var setJob = function(job) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                return [
                    2,
                    dispatch({
                        type: jobsConstants.SET_JOB,
                        payload: job
                    })
                ];
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var setLotArea = function(lotArea) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                return [
                    2,
                    dispatch({
                        type: mapConstants.SET_LOT_AREA,
                        payload: {
                            lotArea: lotArea
                        }
                    })
                ];
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getJob = function(id) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, lotArea, error, _error_response_data, _error_response, _error_response1, _error_response_data1, _error_response2;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            4,
                            ,
                            5
                        ]);
                        dispatch({
                            type: jobsConstants.GET_JOB
                        });
                        return [
                            4,
                            larkiApi.get("/job/".concat(id))
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: jobsConstants.GET_JOB_SUCCESS,
                            payload: data
                        });
                        lotArea = get(data, 'user_order.property.area_in_sqm');
                        if (!lotArea) return [
                            3,
                            3
                        ];
                        return [
                            4,
                            dispatch(setLotArea(lotArea))
                        ];
                    case 2:
                        _state.sent();
                        _state.label = 3;
                    case 3:
                        return [
                            2,
                            data
                        ];
                    case 4:
                        error = _state.sent();
                        dispatch({
                            type: jobsConstants.GET_JOB_FAILED,
                            payload: (_error_response = error.response) === null || _error_response === void 0 ? void 0 : (_error_response_data = _error_response.data) === null || _error_response_data === void 0 ? void 0 : _error_response_data.message
                        });
                        if (((_error_response1 = error.response) === null || _error_response1 === void 0 ? void 0 : _error_response1.status) === 403) {
                            history.push(routes.accessDenied);
                        } else {
                            ;
                            console.error(error);
                            dispatch(alertError((_error_response2 = error.response) === null || _error_response2 === void 0 ? void 0 : (_error_response_data1 = _error_response2.data) === null || _error_response_data1 === void 0 ? void 0 : _error_response_data1.message));
                        }
                        return [
                            3,
                            5
                        ];
                    case 5:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
// TODO: Refactor this to its own project state
export var getJobsList = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error, _error_response_data, _error_response, _error_response_data_message;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            3,
                            4
                        ]);
                        dispatch({
                            type: jobsConstants.GET_JOBS_LIST
                        });
                        return [
                            4,
                            client.listProjects({
                                isAdmin: false
                            })
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: jobsConstants.SET_JOBS_LIST,
                            jobsList: processJobList(data)
                        });
                        dispatch({
                            type: jobsConstants.SET_JOB_STATUS_COUNTS,
                            statusCounts: data.statusCounts
                        });
                        return [
                            3,
                            4
                        ];
                    case 2:
                        error = _state.sent();
                        captureError(error);
                        dispatch(alertError((_error_response_data_message = (_error_response = error.response) === null || _error_response === void 0 ? void 0 : (_error_response_data = _error_response.data) === null || _error_response_data === void 0 ? void 0 : _error_response_data.message) !== null && _error_response_data_message !== void 0 ? _error_response_data_message : 'Error fetching projects'));
                        return [
                            3,
                            4
                        ];
                    case 3:
                        dispatch({
                            type: jobsConstants.GET_JOBS_LIST_DONE
                        });
                        return [
                            7
                        ];
                    case 4:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
// TODO: Refactor this to its own project state
export var getAdminJobsList = function() {
    var _ref = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, _ref_status = _ref.status, status = _ref_status === void 0 ? [] : _ref_status;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error, _error_response_data, _error_response, _error_response_data_message;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        dispatch({
                            type: jobsConstants.GET_ADMIN_JOB_LIST
                        });
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            3,
                            4,
                            5
                        ]);
                        return [
                            4,
                            client.listProjects({
                                isAdmin: true,
                                status: status
                            })
                        ];
                    case 2:
                        data = _state.sent().data;
                        dispatch({
                            type: jobsConstants.SET_ADMIN_JOB_LIST,
                            jobsList: processJobList(data)
                        });
                        dispatch({
                            type: jobsConstants.SET_ADMIN_STATUS_COUNT,
                            statusCounts: data.statusCounts
                        });
                        return [
                            3,
                            5
                        ];
                    case 3:
                        error = _state.sent();
                        captureError(error);
                        dispatch(alertError((_error_response_data_message = (_error_response = error.response) === null || _error_response === void 0 ? void 0 : (_error_response_data = _error_response.data) === null || _error_response_data === void 0 ? void 0 : _error_response_data.message) !== null && _error_response_data_message !== void 0 ? _error_response_data_message : 'Error fetching admin projects'));
                        return [
                            3,
                            5
                        ];
                    case 4:
                        dispatch({
                            type: jobsConstants.GET_ADMIN_JOB_LIST_DONE
                        });
                        return [
                            7
                        ];
                    case 5:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
// export const getPointCloud = (jobId) => async (dispatch) => {
export var getPointCloud = function(projectId) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var response, error, _error_response_data, _error_response, _error_response_data_message;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            larkiApi.get("/project/".concat(projectId, "/point-cloud"))
                        ];
                    case 1:
                        response = _state.sent();
                        if (response.status === 200) {
                            dispatch({
                                type: jobsConstants.SET_POINT_CLOUD,
                                pointCloud: response.data
                            });
                        }
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        if (error.response.status === 403) {
                            redirectTo(routes.accessDenied);
                        } else {
                            ;
                            ;
                            dispatch(alertError((_error_response_data_message = (_error_response = error.response) === null || _error_response === void 0 ? void 0 : (_error_response_data = _error_response.data) === null || _error_response_data === void 0 ? void 0 : _error_response_data.message) !== null && _error_response_data_message !== void 0 ? _error_response_data_message : 'error fetching point cloud'));
                        }
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var createJob = function(_param) {
    var address = _param.address, rest = _object_without_properties(_param, [
        "address"
    ]);
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            larkiApi.post('/job', _object_spread({}, address, rest))
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: jobsConstants.SET_JOB,
                            payload: data
                        });
                        return [
                            2,
                            data
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch(showModal(JOB_FAILURE_MODAL));
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
// TODO: Refactor this to its own project state
export var resetUpdateJobProject = function(projectId) {
    var isAdmin = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    return {
        type: jobsConstants.RESET_UPDATE_JOB_PROJECT_NAME,
        payload: {
            id: projectId,
            isAdmin: isAdmin
        }
    };
};
export var updateJob = function(_param) {
    var _param_onConflict = _param.onConflict, onConflict = _param_onConflict === void 0 ? noop : _param_onConflict, jobId = _param.jobId, address = _param.address, rest = _object_without_properties(_param, [
        "onConflict",
        "jobId",
        "address"
    ]);
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            larkiApi.put("/job/".concat(jobId), _object_spread({}, address, rest))
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: jobsConstants.SET_JOB,
                            payload: data
                        });
                        dispatch({
                            type: jobsConstants.UPDATE_JOB_SUCCESS,
                            payload: data
                        });
                        return [
                            2,
                            data
                        ];
                    case 2:
                        error = _state.sent();
                        console.log("Update job error: ", error);
                        if (error.response.status === 409) {
                            onConflict();
                        } else {
                            dispatch(showModal(JOB_FAILURE_MODAL));
                        }
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
// TODO: Refactor this to its own project state
export var updateProjectName = function(projectId, name) {
    var isAdmin = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            3,
                            4
                        ]);
                        dispatch({
                            type: jobsConstants.UPDATE_JOB_PROJECT_NAME,
                            payload: {
                                id: projectId,
                                isAdmin: isAdmin
                            }
                        });
                        return [
                            4,
                            client.updateProjectName(projectId, name)
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: jobsConstants.UPDATE_JOB_PROJECT_NAME_SUCCESS,
                            payload: {
                                id: projectId,
                                project: data.project,
                                isAdmin: isAdmin
                            }
                        });
                        return [
                            3,
                            4
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch({
                            type: jobsConstants.UPDATE_JOB_PROJECT_NAME_FAILED,
                            payload: {
                                id: projectId,
                                isAdmin: isAdmin
                            }
                        });
                        return [
                            3,
                            4
                        ];
                    case 3:
                        debounce(RESET_FORM_DELAY, function() {
                            dispatch(resetUpdateJobProject(projectId, isAdmin));
                        })();
                        return [
                            7
                        ];
                    case 4:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var commitJob = function(jobId) {
    var membershipSubscribed = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : null;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            larkiApi.post("/job/".concat(jobId, "/commit"), {
                                membershipSubscribed: membershipSubscribed
                            })
                        ];
                    case 1:
                        _state.sent();
                        return [
                            2,
                            true
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch(showModal(ORDER_FAILURE_MODAL));
                        return [
                            2,
                            null
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var deleteProject = function(projectId) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: jobsConstants.DELETE_PROJECT,
                            payload: projectId
                        });
                        return [
                            4,
                            larkiApi.delete("/project/".concat(projectId))
                        ];
                    case 1:
                        _state.sent();
                        dispatch({
                            type: jobsConstants.DELETE_PROJECT_SUCCESS,
                            payload: projectId
                        });
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch(alertError(error.response.data.message));
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var mergeProject = function(project, targetProject) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            client.mergeProjects(project.id, targetProject.id)
                        ];
                    case 1:
                        _state.sent();
                        dispatch(alertSuccess(text('projectsHaveMerged', {
                            projectName: project.name
                        }), {
                            icon: 'circled-tick'
                        }));
                        return [
                            2,
                            true
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch(alertError(error.response.data.message));
                        return [
                            2,
                            false
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var updateProjectPointerraId = function(param) {
    var projectId = param.projectId, pointerraId = param.pointerraId;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            client.updatePointerraId(projectId, pointerraId)
                        ];
                    case 1:
                        _state.sent();
                        dispatch({
                            type: jobsConstants.POINTERRA_ID_UPDATED,
                            payload: {
                                id: projectId,
                                pointerraId: pointerraId
                            }
                        });
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var setListJobStatus = function(status) {
    return {
        type: jobsConstants.SET_LIST_JOB_STATUS,
        payload: status
    };
};
export var setListJobSearchText = function(status) {
    return {
        type: jobsConstants.SET_LIST_JOB_SEARCHTEXT,
        payload: status
    };
};
export var getJobReceipt = function(jobId, paymentIntentId) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: jobsConstants.GET_JOB_RECEIPT
                        });
                        return [
                            4,
                            client.getJobReceipt(jobId, paymentIntentId)
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: jobsConstants.GET_JOB_RECEIPT_SUCCESS,
                            payload: data
                        });
                        return [
                            2,
                            data
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch(showModal(jobsConstants.GET_JOB_RECEIPT_FAILED));
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var copyJob = function(jobId) {
    var isAdmin = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            client.copyJob(jobId)
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: jobsConstants.ADD_COPIED_JOB,
                            payload: {
                                job: data,
                                isAdmin: isAdmin
                            }
                        });
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch(alertError(error.response.data.message));
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var clearJob = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                return [
                    2,
                    dispatch({
                        type: jobsConstants.CLEAR_JOB
                    })
                ];
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var toggleJobBoundaryVisibility = function(isVisible) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                dispatch({
                    type: jobsConstants.TOGGLE_JOB_BOUNDARY_VISIBILITY,
                    payload: isVisible
                });
                return [
                    2
                ];
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var toggleJobBoundaryHidden = function(isHidden) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                dispatch({
                    type: jobsConstants.TOGGLE_JOB_BOUNDARY_HIDDEN,
                    payload: isHidden
                });
                return [
                    2
                ];
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var setUndefinedAddress = function(status) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                dispatch({
                    type: jobsConstants.SET_UNDEFINED_ADDRESS,
                    payload: status
                });
                return [
                    2
                ];
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var updateLotAreaState = function(jobId, lotAreaState) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var error, _error_response_data, _error_response;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            client.updateLotAreaState(jobId, lotAreaState)
                        ];
                    case 1:
                        _state.sent();
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        console.warn(((_error_response = error.response) === null || _error_response === void 0 ? void 0 : (_error_response_data = _error_response.data) === null || _error_response_data === void 0 ? void 0 : _error_response_data.message) ? error.response.data.message : text('putTourStatusFail'));
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var sendLead = function(jobId, triggerType) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var error, _error_response_data, _error_response;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        if (process.env.DISABLE_CRM) return [
                            2
                        ];
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            6,
                            ,
                            7
                        ]);
                        if (!jobId) return [
                            3,
                            3
                        ];
                        return [
                            4,
                            client.sendLead(jobId, triggerType)
                        ];
                    case 2:
                        _state.sent();
                        return [
                            3,
                            5
                        ];
                    case 3:
                        return [
                            4,
                            client.createLeadWithoutJob(triggerType)
                        ];
                    case 4:
                        _state.sent();
                        _state.label = 5;
                    case 5:
                        return [
                            3,
                            7
                        ];
                    case 6:
                        error = _state.sent();
                        console.warn(((_error_response = error.response) === null || _error_response === void 0 ? void 0 : (_error_response_data = _error_response.data) === null || _error_response_data === void 0 ? void 0 : _error_response_data.message) ? error.response.data.message : 'An error occurred on sending lead.');
                        return [
                            3,
                            7
                        ];
                    case 7:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
