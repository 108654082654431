function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { AxiosError } from 'axios';
import { DOWNLOAD_ALL_JOB_STATUS } from '../../constants/asset';
import text from '../../text';
import { client } from '../../utilities/api';
import { captureError } from '../../utilities/error';
import { DOWNLOAD_ALL_STATUS, DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID, DOWNLOAD_ALL_UPDATE_STATUS, DOWNLOAD_ALL_URL, DOWNLOAD_ALL_URL_FAILURE, DOWNLOAD_ALL_URL_SUCCESS, GET_CURRENT_PROJECT, GET_CURRENT_PROJECT_FAILURE, GET_CURRENT_PROJECT_SUCCESS, LIST_PROJECTS, LIST_PROJECTS_FAILURE, LIST_PROJECTS_SUCCESS, LIST_PROJECT_USERS, LIST_PROJECT_USERS_SUCCESS, REGISTER_PROJECT_TRIAL_MEMBERS, REGISTER_PROJECT_TRIAL_MEMBERS_SUCCESS, SET_CURRENT_PROJECT, UPDATE_USER_PROJECT_TRIAL, UPDATE_USER_PROJECT_TRIAL_SUCCESS } from '../constants/project';
import { alertError, alertWarning } from './alert';
import { processJobList } from './jobs';
import { jobsConstants } from '../constants';
export var generateDownloadAllUrl = function(projectId) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error, _error_response_data, _error_response;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: DOWNLOAD_ALL_URL,
                            payload: {
                                projectId: projectId
                            }
                        });
                        return [
                            4,
                            client.generateDownloadAllUrl(projectId)
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID,
                            payload: {
                                projectId: projectId,
                                queueJobId: data.jobId
                            }
                        });
                        switch(data.status){
                            case DOWNLOAD_ALL_JOB_STATUS.AVAILABLE:
                                dispatch({
                                    type: DOWNLOAD_ALL_URL_SUCCESS,
                                    payload: {
                                        projectId: projectId
                                    }
                                });
                                break;
                            case DOWNLOAD_ALL_JOB_STATUS.UNAVAILABLE:
                                dispatch({
                                    type: DOWNLOAD_ALL_URL_FAILURE,
                                    payload: {
                                        projectId: projectId
                                    }
                                });
                                dispatch(alertWarning(text('downloadAllUnavailable')));
                                break;
                            case DOWNLOAD_ALL_JOB_STATUS.FAILED:
                                dispatch({
                                    type: DOWNLOAD_ALL_URL_FAILURE,
                                    payload: {
                                        projectId: projectId
                                    }
                                });
                                dispatch(alertError(text('downloadAllFailed')));
                                break;
                            case DOWNLOAD_ALL_JOB_STATUS.PROCESSING:
                                break;
                            default:
                                console.error("Unknown download all job status: ".concat(data.status));
                                break;
                        }
                        return [
                            2,
                            data
                        ];
                    case 2:
                        error = _state.sent();
                        captureError(error);
                        dispatch({
                            type: DOWNLOAD_ALL_URL_FAILURE,
                            payload: {
                                projectId: projectId
                            }
                        });
                        dispatch(alertError(((_error_response = error.response) === null || _error_response === void 0 ? void 0 : (_error_response_data = _error_response.data) === null || _error_response_data === void 0 ? void 0 : _error_response_data.message) ? error.response.data.message : text('downloadAllFailed')));
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getDownloadAllUrl = function(projectId, jobId) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error, _error_response_data, _error_response;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: DOWNLOAD_ALL_URL,
                            payload: {
                                projectId: projectId
                            }
                        });
                        return [
                            4,
                            client.getDownloadAllUrl(projectId, jobId)
                        ];
                    case 1:
                        data = _state.sent().data;
                        switch(data.status){
                            case DOWNLOAD_ALL_JOB_STATUS.AVAILABLE:
                                dispatch({
                                    type: DOWNLOAD_ALL_URL_SUCCESS,
                                    payload: {
                                        projectId: projectId
                                    }
                                });
                                break;
                            case DOWNLOAD_ALL_JOB_STATUS.FAILED:
                                dispatch({
                                    type: DOWNLOAD_ALL_URL_FAILURE,
                                    payload: {
                                        projectId: projectId
                                    }
                                });
                                dispatch(alertError('Failed to generate download URL'));
                                break;
                            case DOWNLOAD_ALL_JOB_STATUS.PROCESSING:
                                dispatch({
                                    type: DOWNLOAD_ALL_UPDATE_STATUS,
                                    payload: {
                                        projectId: projectId,
                                        status: data.zipProgress === 1 ? DOWNLOAD_ALL_STATUS.DOWNLOADING : DOWNLOAD_ALL_STATUS.ZIPPING
                                    }
                                });
                                break;
                            case DOWNLOAD_ALL_JOB_STATUS.UNAVAILABLE:
                            default:
                                dispatch({
                                    type: DOWNLOAD_ALL_UPDATE_STATUS,
                                    payload: {
                                        projectId: projectId,
                                        status: DOWNLOAD_ALL_STATUS.IDLE
                                    }
                                });
                                console.error("Unknown download all job status: ".concat(data.status));
                                break;
                        }
                        return [
                            2,
                            data
                        ];
                    case 2:
                        error = _state.sent();
                        captureError(error);
                        dispatch({
                            type: DOWNLOAD_ALL_URL_FAILURE,
                            payload: {
                                projectId: projectId
                            }
                        });
                        dispatch(alertError(((_error_response = error.response) === null || _error_response === void 0 ? void 0 : (_error_response_data = _error_response.data) === null || _error_response_data === void 0 ? void 0 : _error_response_data.message) ? error.response.data.message : text('downloadAllFailed')));
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var resumeDownloadAll = function(projectId) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var _ref, queueJobId, err, _err_response_data, _err_response;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            client.toResumeDownload(projectId)
                        ];
                    case 1:
                        _ref = _state.sent(), queueJobId = _ref.data;
                        if (queueJobId) {
                            dispatch({
                                type: DOWNLOAD_ALL_URL,
                                payload: {
                                    projectId: projectId
                                }
                            });
                            dispatch({
                                type: DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID,
                                payload: {
                                    projectId: projectId,
                                    queueJobId: queueJobId
                                }
                            });
                        }
                        return [
                            3,
                            3
                        ];
                    case 2:
                        err = _state.sent();
                        dispatch({
                            type: DOWNLOAD_ALL_URL_FAILURE,
                            payload: {
                                projectId: projectId
                            }
                        });
                        if (!(_instanceof(err, AxiosError) && err.response.status === 403)) {
                            ;
                            captureError(err);
                            dispatch(alertError(((_err_response = err.response) === null || _err_response === void 0 ? void 0 : (_err_response_data = _err_response.data) === null || _err_response_data === void 0 ? void 0 : _err_response_data.message) ? err.response.data.message : text('downloadAllFailed')));
                        }
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2,
                            {}
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var setCurrentProject = function(project) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                return [
                    2,
                    dispatch({
                        type: SET_CURRENT_PROJECT,
                        payload: {
                            project: project
                        }
                    })
                ];
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getProjectById = function(projectId) {
    var _ref = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {}, includeJobs = _ref.includeJobs, includePointCloud = _ref.includePointCloud, _ref_includeQuote = _ref.includeQuote, includeQuote = _ref_includeQuote === void 0 ? false : _ref_includeQuote;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, err;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: GET_CURRENT_PROJECT
                        });
                        return [
                            4,
                            client.getProjectById(projectId, {
                                includeJobs: includeJobs,
                                includePointCloud: includePointCloud,
                                includeQuote: includeQuote
                            })
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: GET_CURRENT_PROJECT_SUCCESS,
                            payload: {
                                project: _object_spread_props(_object_spread({}, data), {
                                    projectId: data.id
                                })
                            }
                        });
                        return [
                            2,
                            {
                                project: data
                            }
                        ];
                    case 2:
                        err = _state.sent();
                        dispatch({
                            type: GET_CURRENT_PROJECT_FAILURE
                        });
                        if (!(_instanceof(err, AxiosError) && err.response.status === 403)) {
                            captureError(err);
                        }
                        return [
                            2,
                            {
                                project: null
                            }
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getLatestProject = function() {
    var _ref = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {}, isAdmin = _ref.isAdmin, includeDemo = _ref.includeDemo, includeJobs = _ref.includeJobs, includePointCloud = _ref.includePointCloud;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, err;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: GET_CURRENT_PROJECT
                        });
                        return [
                            4,
                            client.getLatestProject({
                                isAdmin: isAdmin,
                                inviteStatus: [
                                    'accepted'
                                ],
                                includeDemo: includeDemo,
                                includeJobs: includeJobs,
                                includePointCloud: includePointCloud
                            })
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: GET_CURRENT_PROJECT_SUCCESS,
                            payload: {
                                project: _object_spread_props(_object_spread({}, data), {
                                    projectId: data.id
                                })
                            }
                        });
                        return [
                            2,
                            data
                        ];
                    case 2:
                        err = _state.sent();
                        console.error(err);
                        dispatch({
                            type: GET_CURRENT_PROJECT_FAILURE
                        });
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var registerProjectTrialMembers = function(projectId, users) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: REGISTER_PROJECT_TRIAL_MEMBERS,
                            payload: {
                                projectId: projectId
                            }
                        });
                        return [
                            4,
                            client.registerProjectTrialMembers(projectId, users)
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: REGISTER_PROJECT_TRIAL_MEMBERS_SUCCESS,
                            payload: {
                                members: data
                            }
                        });
                        return [
                            2,
                            data
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch(alertError(error.response.data.message));
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var listProjects = function() {
    var isAdmin = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, dispatchType, error, _error_response_data, _error_response, message;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: LIST_PROJECTS
                        });
                        return [
                            4,
                            client.listProjects({
                                isAdmin: isAdmin,
                                includeDemo: false,
                                inviteStatus: [
                                    'accepted'
                                ]
                            })
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatchType = {
                            list: isAdmin ? jobsConstants.SET_ADMIN_JOB_LIST : jobsConstants.SET_JOBS_LIST,
                            count: isAdmin ? jobsConstants.SET_ADMIN_STATUS_COUNT : jobsConstants.SET_JOB_STATUS_COUNTS
                        };
                        dispatch({
                            type: dispatchType.list,
                            jobsList: processJobList(data)
                        });
                        dispatch({
                            type: dispatchType.count,
                            statusCounts: data.statusCounts
                        });
                        dispatch({
                            type: LIST_PROJECTS_SUCCESS,
                            payload: {
                                data: data
                            }
                        });
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        message = ((_error_response = error.response) === null || _error_response === void 0 ? void 0 : (_error_response_data = _error_response.data) === null || _error_response_data === void 0 ? void 0 : _error_response_data.message) || text('listProjectsFailed');
                        captureError(error);
                        dispatch(alertError(message));
                        dispatch({
                            type: LIST_PROJECTS_FAILURE,
                            payload: {
                                error: message
                            }
                        });
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var listProjectUsers = function(projectId) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: LIST_PROJECT_USERS,
                            payload: {
                                projectId: projectId
                            }
                        });
                        return [
                            4,
                            client.listProjectUsers(projectId)
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: LIST_PROJECT_USERS_SUCCESS,
                            payload: {
                                projectId: projectId,
                                users: data
                            }
                        });
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch(alertError(error.response.data.message));
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var updateUserProjectTrial = function(projectId, userId, projectRole) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: UPDATE_USER_PROJECT_TRIAL,
                            payload: {
                                projectId: projectId,
                                userId: userId
                            }
                        });
                        return [
                            4,
                            client.updateUserProjectTrial(projectId, userId, projectRole)
                        ];
                    case 1:
                        _state.sent();
                        dispatch({
                            type: UPDATE_USER_PROJECT_TRIAL_SUCCESS,
                            payload: {
                                projectId: projectId,
                                userId: userId
                            }
                        });
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch(alertError(error.response.data.message));
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
