function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import text from '../text';
import { USER_TYPE } from './user';
export var PRODUCT_TYPE = {
    AERIAL: 'aerial',
    STREETSCAPE: 'streetscape',
    DRONE: 'drone',
    EXTERIOR: 'exterior',
    INTERIOR: 'interior',
    UNKNOWN: 'unknown',
    BIM_INTERIOR: 'bim_interior',
    TWOD_PLAN_INTERIOR: 'twod_plan_interior',
    BIM_EXTERIOR: 'bim_exterior',
    TWOD_PLAN_EXTERIOR: 'twod_plan_exterior',
    ADDON_SCAN: 'addOnScan',
    ADDON_DATABASE: 'addOnDatabase'
};
export var ASSET_ICON_MAP = {
    Aerial: 'aerial',
    Drone: 'drone',
    Exterior: 'exterior',
    Interior: 'interior',
    Streetscape: 'streetscape',
    unknown: 'unknown',
    BIM_Interior: 'bim_interior',
    BIM_Exterior: 'bim_exterior',
    Twod_Plan_Interior: 'twod_plan_interior',
    Twod_Plan_Exterior: 'twod_plan_exterior'
};
export var STATUS_COLOR_MAP = {
    Completed: 'completed',
    'In progress': 'in-progress',
    Commenced: 'in-progress',
    Pending: 'pending',
    Quoted: 'pending',
    Paid: 'paid',
    Cancelled: 'cancelled',
    Draft: 'draft'
};
export var STATUS_TEXT_MAP = {
    Completed: 'Completed',
    'In progress': 'Commenced',
    Commenced: 'Commenced',
    Pending: 'Quoted',
    Quoted: 'Quoted',
    Paid: 'Paid',
    Cancelled: 'Cancelled',
    Draft: 'Draft'
};
export var PRODUCT_CAPTURED_MAP = {
    aerial: 'plane',
    streetscape: 'car'
};
export var ADD_ONS_PROP_NAME = 'addOns';
export var PRODUCT_DELIVERY_METHOD = _define_property({
    database: 'Database',
    capture: 'Capture',
    draft: 'Draft'
}, ADD_ONS_PROP_NAME, 'Add-ons');
var _obj;
export var PRODUCT_PROS_CONS = (_obj = {}, _define_property(_obj, PRODUCT_TYPE.AERIAL, {
    pros: [
        text('lowCost'),
        text('quickTurnaroundTime'),
        text('greatLargeAreas')
    ],
    cons: [
        text('lowerDetail'),
        text('lowerAccuracy'),
        text('older')
    ]
}), _define_property(_obj, PRODUCT_TYPE.STREETSCAPE, {
    pros: [
        text('lowCost'),
        text('quickTurnaroundTime'),
        text('greatFrontFacadesStreetscapes')
    ],
    cons: [
        text('mediumDetail'),
        text('mediumAccuracy'),
        text('older')
    ]
}), _define_property(_obj, PRODUCT_TYPE.DRONE, {
    pros: [
        text('highDetail'),
        text('highAccuracy'),
        text('greatRoofsLargeGrounds')
    ],
    cons: [
        text('higherCost'),
        text('slowerTurnaroundTime')
    ]
}), _define_property(_obj, PRODUCT_TYPE.EXTERIOR, {
    pros: [
        text('highestDetail'),
        text('highestAccuracy'),
        text('forPreciseExteriors')
    ],
    cons: [
        text('highestCost'),
        text('slowestTurnaroundTime')
    ]
}), _define_property(_obj, PRODUCT_TYPE.INTERIOR, {
    pros: [
        text('highestDetail'),
        text('highestAccuracy'),
        text('forPreciseInteriors')
    ],
    cons: [
        text('highestCost'),
        text('slowestTurnaroundTime')
    ]
}), // TODO: should be another const DELIVERY_METHOD_PROS_CONS
_define_property(_obj, PRODUCT_TYPE.ADDON_SCAN, {
    pros: [
        text('highestDetail'),
        text('highestAccuracy'),
        text('forPrecision')
    ],
    cons: [
        text('higherCostThanDatabase'),
        text('slowerTurnaroundTime')
    ]
}), _define_property(_obj, PRODUCT_TYPE.ADDON_DATABASE, {
    pros: [
        text('lowCost'),
        text('quickTurnaroundTime'),
        text('forConceptsAndContext')
    ],
    cons: [
        text('mediumDetail'),
        text('mediumAccuracy'),
        text('older')
    ]
}), _define_property(_obj, PRODUCT_TYPE.BIM_EXTERIOR, {
    pros: [
        text('architectural3DModel'),
        text('bimFile'),
        text('forAEC')
    ],
    cons: [
        text('basicLOD2'),
        text('excFrames'),
        text('takesTime')
    ]
}), _define_property(_obj, PRODUCT_TYPE.BIM_INTERIOR, {
    pros: [
        text('architectural3DModel'),
        text('bimFile'),
        text('forAEC')
    ],
    cons: [
        text('basicLOD2'),
        text('excFrames'),
        text('takesTime')
    ]
}), _define_property(_obj, PRODUCT_TYPE.TWOD_PLAN_EXTERIOR, {
    pros: [
        text('lines2dText'),
        text('pdfAndDwg'),
        text('forApprovals')
    ],
    cons: [
        text('basicBestFit'),
        text('singleCut'),
        text('takesTime')
    ]
}), _define_property(_obj, PRODUCT_TYPE.TWOD_PLAN_INTERIOR, {
    pros: [
        text('lines2dText'),
        text('pdfAndDwg'),
        text('forApprovals')
    ],
    cons: [
        text('basicBestFit'),
        text('singleCut'),
        text('takesTime')
    ]
}), _obj);
var _obj1;
export var PRODUCT_EXPLANATION = (_obj1 = {}, _define_property(_obj1, PRODUCT_TYPE.AERIAL, text('aerialPointCloudExplanation')), _define_property(_obj1, PRODUCT_TYPE.STREETSCAPE, text('streetscapePointCloudExplanation')), _define_property(_obj1, PRODUCT_TYPE.INTERIOR, text('interiorPointCloudExplanation')), _define_property(_obj1, PRODUCT_TYPE.EXTERIOR, text('exteriorPointCloudExplanation')), _define_property(_obj1, PRODUCT_TYPE.DRONE, text('dronePointCloudExplanation')), _define_property(_obj1, PRODUCT_TYPE.UNKNOWN, text('unknownPointCloudExplanation')), _define_property(_obj1, PRODUCT_TYPE.ADDON_SCAN, text('addOnScanExplanation')), _define_property(_obj1, PRODUCT_TYPE.ADDON_DATABASE, text('addOnDatabaseExplanation')), _define_property(_obj1, PRODUCT_TYPE.BIM_EXTERIOR, text('exteriorBimTooltip')), _define_property(_obj1, PRODUCT_TYPE.BIM_INTERIOR, text('interiorBimTooltip')), _define_property(_obj1, PRODUCT_TYPE.TWOD_PLAN_EXTERIOR, text('exterior2dPlanTooltip')), _define_property(_obj1, PRODUCT_TYPE.TWOD_PLAN_INTERIOR, text('interior2dPlanTooltip')), _obj1);
var _obj2, _obj3, _obj4, _obj5, _obj6, _obj7, _obj8, _obj9, _obj10, _obj11;
export var SALES_HELP_DISCOUNT = (_obj11 = {}, _define_property(_obj11, PRODUCT_TYPE.AERIAL, (_obj2 = {}, _define_property(_obj2, USER_TYPE.FREE, 10), _define_property(_obj2, USER_TYPE.ESSENTIALS, 10), _obj2)), _define_property(_obj11, PRODUCT_TYPE.STREETSCAPE, (_obj3 = {}, _define_property(_obj3, USER_TYPE.FREE, 10), _define_property(_obj3, USER_TYPE.ESSENTIALS, 10), _obj3)), _define_property(_obj11, PRODUCT_TYPE.INTERIOR, (_obj4 = {}, _define_property(_obj4, USER_TYPE.FREE, 0), _define_property(_obj4, USER_TYPE.ESSENTIALS, 0), _obj4)), _define_property(_obj11, PRODUCT_TYPE.EXTERIOR, (_obj5 = {}, _define_property(_obj5, USER_TYPE.FREE, 0), _define_property(_obj5, USER_TYPE.ESSENTIALS, 0), _obj5)), _define_property(_obj11, PRODUCT_TYPE.DRONE, (_obj6 = {}, _define_property(_obj6, USER_TYPE.FREE, 0), _define_property(_obj6, USER_TYPE.ESSENTIALS, 0), _obj6)), _define_property(_obj11, PRODUCT_TYPE.BIM_INTERIOR, (_obj7 = {}, _define_property(_obj7, USER_TYPE.FREE, 0), _define_property(_obj7, USER_TYPE.ESSENTIALS, 0), _obj7)), _define_property(_obj11, PRODUCT_TYPE.BIM_EXTERIOR, (_obj8 = {}, _define_property(_obj8, USER_TYPE.FREE, 0), _define_property(_obj8, USER_TYPE.ESSENTIALS, 0), _obj8)), _define_property(_obj11, PRODUCT_TYPE.TWOD_PLAN_INTERIOR, (_obj9 = {}, _define_property(_obj9, USER_TYPE.FREE, 0), _define_property(_obj9, USER_TYPE.ESSENTIALS, 0), _obj9)), _define_property(_obj11, PRODUCT_TYPE.TWOD_PLAN_EXTERIOR, (_obj10 = {}, _define_property(_obj10, USER_TYPE.FREE, 0), _define_property(_obj10, USER_TYPE.ESSENTIALS, 0), _obj10)), _obj11);
export var AREA_TYPE = {
    SHAPE_AREA: 'SHAPE_AREA',
    PRODUCT_AREA: 'PRODUCT_AREA'
};
var _obj12;
export var AREA_TYPE_CONFIG = (_obj12 = {}, _define_property(_obj12, AREA_TYPE.PRODUCT_AREA, {
    key: 'area_in_sqm',
    label: text('productArea', {})
}), _define_property(_obj12, AREA_TYPE.SHAPE_AREA, {
    key: 'selection_area_in_sqm',
    label: text('shapeArea')
}), _obj12);
var _obj13;
export var ADD_ONS = (_obj13 = {}, _define_property(_obj13, PRODUCT_TYPE.BIM_INTERIOR, {
    PRODUCT_NAME: PRODUCT_TYPE.BIM_INTERIOR,
    DATA_TYPE: PRODUCT_TYPE.INTERIOR
}), _define_property(_obj13, PRODUCT_TYPE.TWOD_PLAN_INTERIOR, {
    PRODUCT_NAME: PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
    DATA_TYPE: PRODUCT_TYPE.INTERIOR
}), _define_property(_obj13, PRODUCT_TYPE.BIM_EXTERIOR, {
    PRODUCT_NAME: PRODUCT_TYPE.BIM_EXTERIOR,
    DATA_TYPE: PRODUCT_TYPE.EXTERIOR
}), _define_property(_obj13, PRODUCT_TYPE.TWOD_PLAN_EXTERIOR, {
    PRODUCT_NAME: PRODUCT_TYPE.TWOD_PLAN_EXTERIOR,
    DATA_TYPE: PRODUCT_TYPE.EXTERIOR
}), _obj13);
export var NON_TRIPOD_DATA_TYPE = [
    PRODUCT_TYPE.UNKNOWN,
    PRODUCT_TYPE.DRONE,
    PRODUCT_TYPE.AERIAL,
    PRODUCT_TYPE.STREETSCAPE
];
export var SMART_SELECTION_DELIVERY_METHOD = {
    AUTOMATIC: 'automatic',
    MANUAL: 'manual'
};
export var BUY_NOW_DATA_TYPES = [
    PRODUCT_TYPE.AERIAL,
    PRODUCT_TYPE.STREETSCAPE
];
export var CAPTURE_SCAN_SERVICES_DATA_TYPE = [
    PRODUCT_TYPE.DRONE,
    PRODUCT_TYPE.INTERIOR,
    PRODUCT_TYPE.EXTERIOR
];
export var ADD_ONS_DATA_TYPE = [
    PRODUCT_TYPE.BIM_INTERIOR,
    PRODUCT_TYPE.BIM_EXTERIOR,
    PRODUCT_TYPE.TWOD_PLAN_INTERIOR,
    PRODUCT_TYPE.TWOD_PLAN_EXTERIOR
];
export var RFQ_DATA_TYPES = _to_consumable_array(CAPTURE_SCAN_SERVICES_DATA_TYPE).concat(_to_consumable_array(ADD_ONS_DATA_TYPE));
export var UNMERGED_OUTLINES_DATA_TYPES = [
    PRODUCT_TYPE.INTERIOR
];
