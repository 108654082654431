function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import { debounce } from 'lodash';
import TagManager from 'react-gtm-module';
import { RESET_FORM_DELAY } from '../../pages/constants';
import { client, larkiApi } from '../../utilities/api';
import { isStaff } from '../../utilities/user';
import { alertConstants, profileConstants } from '../constants';
var RESET_COMPANY_FORM = profileConstants.RESET_COMPANY_FORM, RESET_USER_FORM = profileConstants.RESET_USER_FORM;
export var getUserProfile = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, tagManagerArgs, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: profileConstants.FETCH_USER_DETAILS_LOADING
                        });
                        return [
                            4,
                            larkiApi.get('/user/profile')
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: profileConstants.FETCH_USER_DETAILS_SUCCESS,
                            userProfile: data
                        });
                        if (process.env.GOOGLE_GTM_ID && !isStaff(data.role) && !window.dataLayer) {
                            tagManagerArgs = {
                                gtmId: "".concat(process.env.GOOGLE_GTM_ID),
                                dataLayer: {
                                    event: 'login',
                                    userId: data.id
                                }
                            };
                            TagManager.initialize(tagManagerArgs);
                        }
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch({
                            type: alertConstants.ALERT_ERROR,
                            payload: {
                                message: error.response.data.message
                            }
                        });
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var resetUserForm = function() {
    return function(dispatch) {
        return dispatch({
            type: RESET_USER_FORM
        });
    };
};
export var debouncedResetUserForm = function(dispatch) {
    return debounce(function() {
        return dispatch({
            type: RESET_USER_FORM
        });
    }, RESET_FORM_DELAY);
};
export var updateUserProfile = function(userProfile) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var response, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: profileConstants.UPDATE_USER_DETAILS_LOADING
                        });
                        return [
                            4,
                            larkiApi.put('/user/profile', {
                                full_name: userProfile.full_name,
                                email: userProfile.email,
                                phone: userProfile.phone,
                                profession: userProfile.profession
                            })
                        ];
                    case 1:
                        response = _state.sent();
                        if (response.status === 200) {
                            dispatch({
                                type: profileConstants.UPDATE_USER_DETAILS_SUCCESS,
                                userProfile: userProfile
                            });
                            debouncedResetUserForm(dispatch)();
                        }
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch({
                            type: profileConstants.UPDATE_USER_DETAILS_ERROR
                        });
                        dispatch({
                            type: alertConstants.ALERT_ERROR,
                            payload: {
                                message: error.response.data.message
                            }
                        });
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getCompanyProfile = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            larkiApi.get('/organisation/profile')
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: profileConstants.SET_COMPANY_DETAILS,
                            companyProfile: data
                        });
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch({
                            type: alertConstants.ALERT_ERROR,
                            payload: {
                                message: error.response.data.message
                            }
                        });
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var resetCompanyForm = function() {
    return function(dispatch) {
        return dispatch({
            type: RESET_COMPANY_FORM
        });
    };
};
export var debouncedResetCompanyForm = function(dispatch) {
    return debounce(function() {
        return dispatch({
            type: RESET_COMPANY_FORM
        });
    }, RESET_FORM_DELAY);
};
export var updateCompanyProfile = function(companyProfile) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var response, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: profileConstants.UPDATE_COMPANY_DETAILS_LOADING
                        });
                        return [
                            4,
                            larkiApi.put('/organisation/profile', companyProfile)
                        ];
                    case 1:
                        response = _state.sent();
                        if (response.status === 200) {
                            dispatch({
                                type: profileConstants.UPDATE_COMPANY_DETAILS_SUCCESS,
                                companyProfile: companyProfile
                            });
                            debouncedResetCompanyForm(dispatch)();
                        }
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch({
                            type: profileConstants.UPDATE_COMPANY_DETAILS_ERROR
                        });
                        dispatch({
                            type: alertConstants.ALERT_ERROR,
                            payload: {
                                message: error.response.data.message
                            }
                        });
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var listUserCards = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: profileConstants.LIST_USER_CARDS
                        });
                        return [
                            4,
                            client.listUserCards()
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: profileConstants.LIST_USER_CARDS_SUCCESS,
                            payload: data
                        });
                        return [
                            2,
                            data
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch({
                            type: profileConstants.LIST_USER_CARDS_FAILED
                        });
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var setPretendType = function(payload) {
    return function(dispatch) {
        return dispatch({
            type: profileConstants.SET_PRETEND_TYPE,
            payload: payload
        });
    };
};
