export var FILE_FORMAT = {
    pointCloud3D: [
        'rcs',
        'e57',
        'las',
        'laz',
        'ply',
        'rcp',
        'pts'
    ],
    drawing2D: [
        'dwg'
    ],
    bim3D: [
        'rvt',
        'pln',
        'skp',
        'vwx',
        '3dm',
        'ifc'
    ],
    image: [
        'jpg',
        'png'
    ],
    document: [
        'pdf'
    ],
    compressed: [
        'zip'
    ]
};
export var BIM_FILE_EXT = [
    'rvt',
    'pln',
    'ifc'
];
export var FEATURE_PLAN_EXT = [
    'pdf',
    'dwg'
];
export var DOWNLOAD_ALL_JOB_STATUS = {
    PROCESSING: 'processing',
    FAILED: 'failed',
    AVAILABLE: 'available',
    UNAVAILABLE: 'unavailable'
};
