var incrementLetterSequence = function(str) {
    var incrementChar = function(char) {
        if (char === 'z') return 'aa';
        if (char === 'Z') return 'a';
        var code = char.charCodeAt(0);
        return String.fromCharCode(code + 1);
    };
    // Convert string to an array of characters for easy manipulation
    var chars = str.split('');
    // Start from the end of the string
    for(var i = chars.length - 1; i >= 0; i--){
        if (chars[i] === 'z') {
            chars[i] = 'a';
            if (i === 0) {
                chars.unshift('a');
            }
        } else if (chars[i] === 'Z') {
            chars[i] = 'A';
            if (i === 0) {
                chars.unshift('A');
            }
        } else {
            chars[i] = incrementChar(chars[i]);
            break;
        }
    }
    return chars.join('');
};
export var getNextSequenceItem = function(array) {
    var DEFAULT = 'Shape 1';
    if (array.length === 0) return DEFAULT;
    var lastItem = array[array.length - 1];
    if (!lastItem) {
        return DEFAULT;
    }
    var output = DEFAULT;
    var match = lastItem.trim().match(/^(\S+)\s?(\w*)$/);
    if (match) {
        var prefix = match[1];
        var sequencePart = match[2];
        if (sequencePart === '') {
            sequencePart = '0';
        }
        if (!isNaN(sequencePart)) {
            // If there is a numeric part, increment it
            var number = parseInt(sequencePart, 10);
            var nextNumber = number + 1;
            output = "".concat(prefix, " ").concat(nextNumber);
        } else {
            output = "".concat(prefix, " ").concat(incrementLetterSequence(sequencePart));
        }
    } else {
        output = DEFAULT;
    }
    if (array.includes(output)) {
        array.pop();
        return getNextSequenceItem(array);
    }
    return output;
};
