function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { createSlice } from '@reduxjs/toolkit';
export var initialState = {
    files: {
        entities: {},
        ids: []
    },
    uploading: false,
    error: null
};
var slice = createSlice({
    name: 'uploadAsset',
    initialState: initialState,
    reducers: {
        addFile: function addFile(state, action) {
            state.files.ids.push(action.payload.id);
            state.files.entities[action.payload.id] = _object_spread_props(_object_spread({}, action.payload), {
                progress: 0
            });
        },
        removeFile: function removeFile(state, action) {
            delete state.files.entities[action.payload];
            state.files.ids = state.files.ids.filter(function(id) {
                return id !== action.payload;
            });
        },
        updateProgress: function updateProgress(state, action) {
            state.files.entities[action.payload.id].progress = action.payload.progress;
        },
        setUploading: function setUploading(state, action) {
            state.uploading = action.payload;
        },
        setError: function setError(state, action) {
            state.error = action.payload;
        }
    }
});
export var uploadAssetReducer = slice.reducer, uploadAssetActions = slice.actions;
