function clamp(num, min, max) {
    return Math.min(Math.max(num, min), max);
}
var roundToNearest = function(num) {
    var amount = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 100;
    return Math.round(num / amount) * amount;
};
var stripOffDiscount = function(amount, percentage) {
    var subtotal = amount / (1 + percentage / 100);
    var gst = amount - subtotal;
    return {
        gst: gst,
        subtotal: subtotal
    };
};
export { clamp, roundToNearest, stripOffDiscount };
