function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { PRODUCT_TYPE } from '../../constants/product';
export var defaultSelectionColor = '#000000';
export var aerialIconEnabled = '/public/img/aerial-point-cloud-enabled.svg';
export var droneIconEnabled = '/public/img/drone-point-cloud-enabled.svg';
export var exteriorIconEnabled = '/public/img/exterior-point-cloud-enabled.svg';
export var interiorIconEnabled = '/public/img/interior-point-cloud-enabled.svg';
export var streetscapeIconEnabled = '/public/img/streetscape-point-cloud-enabled.svg';
export var unknownIconEnabled = '/public/img/unknown-point-cloud-enabled.svg';
export var aerialIconDisabled = '/public/img/aerial-point-cloud-disabled.svg';
export var droneIconDisabled = '/public/img/drone-point-cloud-disabled.svg';
export var exteriorIconDisabled = '/public/img/exterior-point-cloud-disabled.svg';
export var interiorIconDisabled = '/public/img/interior-point-cloud-disabled.svg';
export var streetscapeIconDisabled = '/public/img/streetscape-point-cloud-disabled.svg';
export var unknownIconDisabled = '/public/img/unknown-point-cloud-disabled.svg';
export var aerialIconWhite = '/public/img/aerial-point-cloud-white.svg';
export var droneIconWhite = '/public/img/drone-point-cloud-white.svg';
export var exteriorIconWhite = '/public/img/exterior-point-cloud-white.svg';
export var interiorIconWhite = '/public/img/interior-point-cloud-white.svg';
export var streetscapeIconWhite = '/public/img/streetscape-point-cloud-white.svg';
export var unknownIconWhite = '/public/img/unknown-point-cloud-white.svg';
export var PRODUCT_DATA = {
    entities: {
        unknown: {
            order: 0,
            display_name: 'Unspecified',
            display_color: '#9b9b9b'
        },
        aerial: {
            order: 1,
            display_name: 'Aerial',
            display_color: '#157DAA'
        },
        streetscape: {
            order: 2,
            display_name: 'Streetscape',
            display_color: '#3048C9'
        },
        drone: {
            order: 3,
            display_name: 'Drone',
            display_color: '#B087F3'
        },
        exterior: {
            order: 4,
            display_name: 'Exterior',
            display_color: '#E55384'
        },
        interior: {
            order: 5,
            display_name: 'Interior',
            display_color: '#F47601'
        },
        bim_exterior: {
            order: 6,
            display_name: 'BIM Exterior',
            display_color: '#E55384'
        },
        bim_interior: {
            order: 7,
            display_name: 'BIM Interior',
            display_color: '#F47601'
        },
        twod_plan_exterior: {
            order: 8,
            display_name: '2D Plan Exterior',
            display_color: '#E55384'
        },
        twod_plan_interior: {
            order: 9,
            display_name: '2D Plan Interior',
            display_color: '#F47601'
        }
    },
    result: [
        'unknown',
        'aerial',
        'streetscape',
        'drone',
        'exterior',
        'interior',
        'bim_exterior',
        'bim_interior',
        'twod_plan_exterior',
        'twod_plan_interior'
    ]
};
export var GEOSPATIAL_FILES = {
    SHAPEFILE: {
        fileExtension: 'shp',
        displayName: '.shp (WGS84, m)'
    },
    GEOJSON: {
        fileExtension: 'geojson',
        displayName: '.geojson (WGS84, m)'
    },
    KEYHOLE: {
        fileExtension: 'kml',
        displayName: '.kml (WGS84, m)'
    },
    DXF: {
        fileExtension: 'dxf',
        displayName: '.dxf (MGA2020, m)'
    },
    DXF_TRUNCATED: {
        fileExtension: 'dxf-truncated',
        displayName: '.dxf (MGA2020, mm, <1km truncated)'
    }
};
export var MAP_LOAD_DELAY = 500;
export var MAP_TYPE = {
    HIGH_RES: 'highres',
    HIGH_RES_NZ: 'highresnz',
    SATELLITE: 'satellite',
    ROAD_MAP: 'roadmap'
};
export var softwares = {
    entities: {
        autodesk_revit: {
            id: 'autodesk_revit',
            name: 'Revit',
            format: 'rcs'
        },
        autodesk_autocad: {
            id: 'autodesk_autocad',
            name: 'AutoCAD',
            format: 'rcs'
        },
        graphisoft_archicad: {
            id: 'graphisoft_archicad',
            name: 'ArchiCAD',
            format: 'e57'
        },
        bentley: {
            id: 'bentley',
            name: 'Bentley',
            format: 'e57'
        },
        vectorworks: {
            id: 'vectorworks',
            name: 'Vectorworks',
            format: 'e57'
        },
        rhinoceros_rhino: {
            id: 'rhinoceros_rhino',
            name: 'Rhinoceros',
            format: 'e57'
        },
        sketchup_scan_plugin: {
            id: 'sketchup_scan_plugin',
            name: 'SketchUp + scan plugin',
            format: 'e57'
        }
    },
    result: [
        'autodesk_revit',
        'autodesk_autocad',
        'graphisoft_archicad',
        'bentley',
        'vectorworks',
        'rhinoceros_rhino',
        'sketchup_scan_plugin'
    ]
};
export var MAP_ZOOM_LEVEL_TO_HIDE_ELEMENTS = 16;
export var MAP_ZOOM_LEVEL_TO_HIDE_LABELS = 17;
export var MAP_BOUNDS_PADDING = 20; // m
export var PREFERRED_MAP_ZOOM = 18;
export var NZ_STATES = [
    'Auckland',
    'New Plymouth',
    'Wellington',
    'Nelson',
    'Canterbury',
    'Otago'
];
export var INITIAL_MAP_LOCATION = '-37.81229312184036,144.96279632621173'; // Melbourne CBD
export var INITIAL_MAP_ZOOM = 18;
export var MIN_MAP_ZOOM = 12;
export var MAX_MAP_ZOOM = 22;
export var MAX_MAP_ZOOM_SATELLITE = 20;
export var MAX_MAP_ZOOM_HIGH_RES = 22;
export var INITIAL_RECTANGLE_SIZE = {
    dx: 0.025 /* km */ ,
    dy: 0.025 /* km */ 
};
export var AUTO_MAP_ADDRESS = {
    address: '90 Bridge Road',
    city: 'Richmond',
    state: 'Victoria',
    postcode: '3121'
};
var _obj;
/**
 * Whether to merge layers of the same type.
 * Display only.
 */ export var DRAW_LAYERS_MERGED = (_obj = {
    unknown: true,
    aerial: true,
    streetscape: false,
    drone: true,
    exterior: true,
    interior: true
}, _define_property(_obj, PRODUCT_TYPE.BIM_EXTERIOR, true), _define_property(_obj, PRODUCT_TYPE.BIM_INTERIOR, true), _define_property(_obj, PRODUCT_TYPE.TWOD_PLAN_EXTERIOR, true), _define_property(_obj, PRODUCT_TYPE.TWOD_PLAN_INTERIOR, true), _obj);
export var Z_INDEX = {
    SELECTION: 1,
    COVERAGE: 2,
    CLICKABLE: 3,
    BOOLEAN: 4,
    MARKER: 5,
    REGION: 6
};
export var LNG_MAP = [
    1,
    0,
    3,
    2
];
export var LAT_MAP = [
    3,
    2,
    1,
    0
];
/**
 * Actions to take upon revisiting the map after logging in from previously being an authenticated user.
 */ export var ON_REVISIT = {
    _2D_HIGH_RES: '2d-high-res'
};
