// Taken from stripe
export var cardsBrand = {
    amex: 'American Express',
    cartes_bancaires: 'Cartes Bancaires',
    diners_club: 'Diners Club',
    discover: 'Discover',
    jcb: 'Japan Credit Bureau',
    mastercard: 'Mastercard',
    visa: 'Visa',
    unionpay: 'UnionPay'
};
