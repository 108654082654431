function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useBoolean } from 'react-use';
import { COUPON_MODAL } from '../components/modal/CouponModal';
import { hideModal, showModal } from '../redux/actions';
var useCoupon = function() {
    var modalProps = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        title: null,
        onAcceptLabel: null,
        onAccept: null
    }, predicate = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : function() {
        var args = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true;
        return args // return true by default
        ;
    };
    var _useBoolean = _sliced_to_array(useBoolean(false), 2), hasDiscountDataModalAppeared = _useBoolean[0], setHasDiscountDataModalAppeared = _useBoolean[1];
    var dispatch = useDispatch();
    var dispatchModal = useCallback(function() {
        if (predicate(hasDiscountDataModalAppeared)) {
            dispatch(showModal(COUPON_MODAL, {
                title: modalProps.title,
                onAcceptLabel: modalProps.onAcceptLabel,
                onAccept: function() {
                    modalProps.onAccept && modalProps.onAccept();
                    dispatch(hideModal(COUPON_MODAL));
                }
            }));
            setHasDiscountDataModalAppeared(true);
        }
    }, [
        hasDiscountDataModalAppeared,
        setHasDiscountDataModalAppeared,
        dispatch,
        modalProps,
        predicate
    ]);
    useEffect(function() {
        dispatchModal();
    }, [
        dispatchModal
    ]);
};
export default useCoupon;
