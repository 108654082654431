export var PROJECT_TRIAL_ROLE = 'project-trial';
export var FREE_ROLE = 'free';
export var PROJECT_ROLE = {
    PROJECT_TRIAL: PROJECT_TRIAL_ROLE,
    FREE: FREE_ROLE
};
export var PROJECT_TEAM_MEMBER_LIMIT = 3;
export var FETCH_STATUS = {
    IDLE: 'idle',
    LOADING: 'loading',
    DONE: 'done',
    ERROR: 'error'
};
