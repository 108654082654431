function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import { DETAILED_STATUS, STATUS } from '../constants/job';
import { convertSelectionToRegionSelection } from './map';
export var isJobViewable = function(job) {
    return job.status === 'completed' && get('user_project.invite_status', job) === 'accepted' && !isEmpty(job.point_clouds) || job.status === 'demo';
};
export var isDetailedStatusCompleted = function(status) {
    return status && status === DETAILED_STATUS.COMPLETED;
};
export var isDetailedStatusInProgress = function(status) {
    return status && status === DETAILED_STATUS.IN_PROGRESS;
};
export var jobHasPointCloud = get('point_clouds.0.pointerra_id');
export var anySelectionVisible = function(selections) {
    return selections && selections.some(function(selection) {
        return selection.visible;
    });
};
export var getSelectionProductId = get('product_id');
export var allSelectionsVisible = function(selections) {
    return selections.every(function(selection) {
        return selection.visible;
    });
};
export var allProductSelectionsVisible = function(selections, productId) {
    return selections && productId && allSelectionsVisible(selections.filter(function(selection) {
        return getSelectionProductId(selection) === productId;
    }));
};
/**
 * @param {*} job
 * @returns true/false
 *
 * Remove delivery_method since computation in Backend is only
 * for BUY NOW data. Scan services is moved as RFQ
 *
 * => job?.quote?.delivery_method === 'automatic'
 */ export var isJobPayable = function(job) {
    return (job === null || job === void 0 ? void 0 : job.status) && [
        STATUS.DRAFT
    ].includes(job === null || job === void 0 ? void 0 : job.status);
};
export var isDemoJob = function(job) {
    return job && job.status && job.status === 'demo';
};
export var transformUserOrderToSelections = function(job) {
    var _job_user_order;
    var selectionsToResume = [];
    if ((_job_user_order = job.user_order) === null || _job_user_order === void 0 ? void 0 : _job_user_order.selections) {
        job.user_order.selections.forEach(function(selection) {
            var _job_coverage_products_selection_product_id, _job_coverage_products, _job_coverage, _job_coverage_products_selection_product_id1, _job_coverage_products1, _job_coverage1, _job_coverage_products_selection_product_id2, _job_coverage_products2, _job_coverage2, _job_coverage3, _job_coverage4;
            var category_name = (job === null || job === void 0 ? void 0 : (_job_coverage = job.coverage) === null || _job_coverage === void 0 ? void 0 : (_job_coverage_products = _job_coverage.products) === null || _job_coverage_products === void 0 ? void 0 : (_job_coverage_products_selection_product_id = _job_coverage_products[selection === null || selection === void 0 ? void 0 : selection.product_id]) === null || _job_coverage_products_selection_product_id === void 0 ? void 0 : _job_coverage_products_selection_product_id.category_name) || 'unknown';
            var display_name = (job === null || job === void 0 ? void 0 : (_job_coverage1 = job.coverage) === null || _job_coverage1 === void 0 ? void 0 : (_job_coverage_products1 = _job_coverage1.products) === null || _job_coverage_products1 === void 0 ? void 0 : (_job_coverage_products_selection_product_id1 = _job_coverage_products1[selection === null || selection === void 0 ? void 0 : selection.product_id]) === null || _job_coverage_products_selection_product_id1 === void 0 ? void 0 : _job_coverage_products_selection_product_id1.display_name) || 'Unknown';
            var delivery_method = job === null || job === void 0 ? void 0 : (_job_coverage2 = job.coverage) === null || _job_coverage2 === void 0 ? void 0 : (_job_coverage_products2 = _job_coverage2.products) === null || _job_coverage_products2 === void 0 ? void 0 : (_job_coverage_products_selection_product_id2 = _job_coverage_products2[selection === null || selection === void 0 ? void 0 : selection.product_id]) === null || _job_coverage_products_selection_product_id2 === void 0 ? void 0 : _job_coverage_products_selection_product_id2.delivery_method;
            selectionsToResume.push({
                created_at: selection.created_at,
                product_id: selection.product_id,
                available: true,
                category_name: category_name,
                display_name: display_name,
                delivery_method: delivery_method,
                region: convertSelectionToRegionSelection(selection),
                id: selection.id,
                name: selection.ui_state.name,
                layers: _object_spread({}, job === null || job === void 0 ? void 0 : (_job_coverage3 = job.coverage) === null || _job_coverage3 === void 0 ? void 0 : _job_coverage3.layers),
                products: _object_spread({}, job === null || job === void 0 ? void 0 : (_job_coverage4 = job.coverage) === null || _job_coverage4 === void 0 ? void 0 : _job_coverage4.products),
                visible: true,
                parent_selection_id: selection.parent_selection_id || ''
            });
        });
    }
    return selectionsToResume;
};
