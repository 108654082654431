function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import flowFP from 'lodash/fp/flow';
import setFP from 'lodash/fp/set';
import { CLEAR_PENDING_REQUESTS, REMOVE_FROM_PENDING_REQUESTS, SET_PENDING_REQUESTS } from '../constants/api';
var defaultState = {
    pendingApiRequests: []
};
var apiReducer = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : defaultState, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case SET_PENDING_REQUESTS:
            {
                return flowFP([
                    setFP([
                        'pendingApiRequests'
                    ], _to_consumable_array(state.pendingApiRequests).concat([
                        action.payload
                    ]))
                ])(state);
            }
        case REMOVE_FROM_PENDING_REQUESTS:
            {
                return flowFP([
                    setFP([
                        'pendingApiRequests'
                    ], state.pendingApiRequests.filter(function(item) {
                        return item.id !== action.payload;
                    }))
                ])(state);
            }
        case CLEAR_PENDING_REQUESTS:
            {
                return flowFP([
                    setFP([
                        'pendingApiRequests'
                    ], [])
                ])(state);
            }
        default:
            return state;
    }
};
export default apiReducer;
