import * as yup from 'yup';
import text from '../text';
export * from './company';
export * from './user';
export var isValidName = function(name) {
    return name ? name.trim().length > 0 : false;
};
export var PROJ_NAME_MIN_LENGTH = 1;
export var PROJ_NAME_MAX_LENGTH = 50;
export var MIN_LENGTH = 1;
export var projectValidationSchema = yup.object().shape({
    project: yup.object().shape({
        name: yup.string().required(text('required')).min(PROJ_NAME_MIN_LENGTH, text('projectNameLength', {
            min: PROJ_NAME_MIN_LENGTH,
            max: PROJ_NAME_MAX_LENGTH
        })).max(PROJ_NAME_MAX_LENGTH, text('projectNameLength', {
            min: PROJ_NAME_MIN_LENGTH,
            max: PROJ_NAME_MAX_LENGTH
        })).nullable()
    })
});
export var validateEmail = yup.string().required(text('required')).email(text('enterValidEmail'));
export var validatePassword = yup.string().min(8, text('passwordValidation')).matches(/^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/, text('passwordValidation')).required(text('required'));
export var validateConfirmPassword = validatePassword.test('passwords-match', text('passwordsMustMatch'), function(value) {
    return this.parent.password === value;
});
export var assignedPaymentSchema = yup.object().shape({
    payerName: yup.string().required(text('required')),
    payerEmail: validateEmail,
    message: yup.string().notRequired()
});
