import { useEffect, useCallback } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';
import { AUTOSUBMIT_DELAY } from '../../constants';
var AutoSave = function(param) {
    var isValidForm = param.isValidForm;
    var _useFormikContext = useFormikContext(), dirty = _useFormikContext.dirty, submitForm = _useFormikContext.submitForm, values = _useFormikContext.values;
    var debouncedSubmit = useCallback(debounce(submitForm, AUTOSUBMIT_DELAY), [
        submitForm
    ]);
    useEffect(function() {
        dirty && isValidForm && debouncedSubmit();
    }, [
        debouncedSubmit,
        dirty,
        isValidForm,
        values
    ]);
    return null;
};
export default AutoSave;
