function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import text from '../text';
export var FREE = 'free';
export var TRIAL = 'trial';
export var ESSENTIALS = 'essentials';
export var LARKI_SUPPORT = 'larkiSupport';
export var GOVERNMENT = 'government';
export var USER_TYPE = {
    FREE: FREE,
    TRIAL: TRIAL,
    ESSENTIALS: ESSENTIALS,
    LARKI_SUPPORT: LARKI_SUPPORT,
    GOVERNMENT: GOVERNMENT
};
var _obj;
export var USER_TYPE_TO_DISPLAY_NAME = (_obj = {}, _define_property(_obj, USER_TYPE.FREE, text('freeUser')), _define_property(_obj, USER_TYPE.TRIAL, text('trialUser')), _define_property(_obj, USER_TYPE.ESSENTIALS, text('essentials')), _define_property(_obj, USER_TYPE.LARKI_SUPPORT, text('larkiSupport')), _define_property(_obj, USER_TYPE.GOVERNMENT, text('governmentUser')), _obj);
