import * as Sentry from '@sentry/react';
import { createBrowserHistory } from 'history';
var history = createBrowserHistory();
export var initializeSentry = function() {
    Sentry.init({
        dsn: process.env.SENTRY_FRONTEND_DSN,
        integrations: [
            Sentry.reactRouterV5BrowserTracingIntegration({
                history: history
            }),
            Sentry.thirdPartyErrorFilterIntegration({
                filterKeys: [
                    'larki-frontend'
                ],
                behaviour: 'apply-tag-if-contains-third-party-frames'
            })
        ],
        tracePropagationTargets: [
            '*'
        ],
        enabled: !process.env.DISABLE_SENTRY,
        tracesSampleRate: 1.0,
        environment: process.env.SENTRY_ENVIRONMENT
    });
};
