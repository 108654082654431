function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import routerHistory from '../routes/history';
export function isValidHttpUrl(string) {
    // https://stackoverflow.com/a/43467144
    var url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
}
export var getRedirectUrl = function(to) {
    var preserveQueryParams = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false, queryParams = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    var searchParams = new URLSearchParams(preserveQueryParams ? window.location.search : undefined);
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = Object.entries(queryParams)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var _step_value = _sliced_to_array(_step.value, 2), key = _step_value[0], value = _step_value[1];
            searchParams.append(key, value);
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    if (isValidHttpUrl(to)) {
        var url = new URL(to);
        var _iteratorNormalCompletion1 = true, _didIteratorError1 = false, _iteratorError1 = undefined;
        try {
            for(var _iterator1 = searchParams[Symbol.iterator](), _step1; !(_iteratorNormalCompletion1 = (_step1 = _iterator1.next()).done); _iteratorNormalCompletion1 = true){
                var _step_value1 = _sliced_to_array(_step1.value, 2), key1 = _step_value1[0], value1 = _step_value1[1];
                url.searchParams.append(key1, value1);
            }
        } catch (err) {
            _didIteratorError1 = true;
            _iteratorError1 = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion1 && _iterator1.return != null) {
                    _iterator1.return();
                }
            } finally{
                if (_didIteratorError1) {
                    throw _iteratorError1;
                }
            }
        }
        return url;
    } else {
        var url1 = new URL("".concat(process.env.LARKI_APP_URL).concat(to));
        var _iteratorNormalCompletion2 = true, _didIteratorError2 = false, _iteratorError2 = undefined;
        try {
            for(var _iterator2 = searchParams[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true){
                var _step_value2 = _sliced_to_array(_step2.value, 2), key2 = _step_value2[0], value2 = _step_value2[1];
                url1.searchParams.append(key2, value2);
            }
        } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion2 && _iterator2.return != null) {
                    _iterator2.return();
                }
            } finally{
                if (_didIteratorError2) {
                    throw _iteratorError2;
                }
            }
        }
        return url1;
    }
};
export var isUrlExternal = function(url) {
    if (url.hostname === 'localhost') {
        return url.host !== window.location.host; // on localhost also check the port
    }
    return url.hostname !== window.location.hostname;
};
export var redirectTo = function(to) {
    var _history = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : undefined, _preserveQueryParams = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false, _queryParams = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : {};
    var history = _history !== null && _history !== void 0 ? _history : routerHistory;
    var url = getRedirectUrl(to, _preserveQueryParams, _queryParams);
    var external = isUrlExternal(url);
    if (external) {
        window.location.replace(url);
    } else {
        history.push({
            pathname: url.pathname,
            search: url.search
        });
    }
};
export var useRedirect = function() {
    var history = useHistory();
    var push = useCallback(function(to) {
        var _ref = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {
            preserveQueryParams: false,
            queryParams: {}
        }, preserveQueryParams = _ref.preserveQueryParams, queryParams = _ref.queryParams;
        redirectTo(to, history, preserveQueryParams, queryParams);
    }, [
        history
    ]);
    return useMemo(function() {
        return {
            push: push
        };
    }, [
        push
    ]);
};
