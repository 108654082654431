import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../redux/reducers';
var store;
if (process.env.ENABLE_REDUX_LOG) {
    var logger = require('redux-logger').logger;
    store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware, logger)));
} else {
    store = createStore(rootReducer, applyMiddleware(thunkMiddleware));
}
export { store };
