function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { START_TRIAL_MODAL } from '../components/modal/StartTrialModal/StartTrialModal';
import { showModal } from '../redux/actions';
import { profileSelectors } from '../redux/selectors/profile';
import routes from '../routes/constants';
var useMembershipButton = function() {
    var history = useHistory();
    var dispatch = useDispatch();
    var canUserStartTrial = useSelector(profileSelectors.getCanUserStartTrial);
    var canUserUpgrade = useSelector(profileSelectors.getCanUserUpgrade);
    var handleMembershipClick = useCallback(function(state) {
        return canUserStartTrial ? dispatch(showModal(START_TRIAL_MODAL)) : canUserUpgrade ? history.push(_object_spread({
            pathname: "".concat(routes.user.membershipManagement)
        }, state && {
            state: _object_spread({}, state)
        })) : function() {};
    }, [
        canUserStartTrial,
        canUserUpgrade,
        dispatch,
        history
    ]);
    return {
        handleMembershipClick: handleMembershipClick
    };
};
export default useMembershipButton;
