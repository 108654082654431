function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import * as turf from '@turf/turf';
import { computeDestinationPoint, getDistance } from 'geolib';
import _ from 'lodash';
import geohash from 'ngeohash';
import polylabel from 'polylabel';
// we've had issues with turf.js in computing intersection: https://github.com/mfogel/polygon-clipping/issues/91 https://github.com/Turfjs/turf/issues/2277
// so using polyclip-ts for intersection, union, difference
import * as polyclip from 'polyclip-ts';
import proj4 from 'proj4';
import assert from '../../utilities/assert';
function computeSurroundingPolygonFromPolyline(polyline) {
    var LINE_WIDTH_IN_METERS = 20;
    var path = polyline.getPath();
    var surroundingPolygonComponents = [];
    for(var i = 0; i < path.length - 1; i++){
        var p1 = turf.point([
            path.getAt(i).lng(),
            path.getAt(i).lat()
        ]);
        var p2 = turf.point([
            path.getAt(i + 1).lng(),
            path.getAt(i + 1).lat()
        ]);
        var bearing = turf.bearing(p1, p2);
        var p0a = turf.destination(p1, LINE_WIDTH_IN_METERS, bearing + 90, {
            units: 'meters'
        });
        var p0b = turf.destination(p1, LINE_WIDTH_IN_METERS, bearing - 90, {
            units: 'meters'
        });
        var p1a = turf.destination(p2, LINE_WIDTH_IN_METERS, bearing + 90, {
            units: 'meters'
        });
        var p1b = turf.destination(p2, LINE_WIDTH_IN_METERS, bearing - 90, {
            units: 'meters'
        });
        var surroundingRectangle = turf.polygon([
            [
                turf.getCoord(p0a),
                turf.getCoord(p0b),
                turf.getCoord(p1b),
                turf.getCoord(p1a),
                turf.getCoord(p0a)
            ]
        ]);
        surroundingPolygonComponents.push(surroundingRectangle);
        if (i > 0 && i < path.length - 1) {
            var p0 = turf.point([
                path.getAt(i - 1).lng(),
                path.getAt(i - 1).lat()
            ]);
            var priorBearing = turf.bearing(p0, p1);
            var a0 = priorBearing - bearing < 0 ? priorBearing - 90 : bearing + 90;
            var a1 = priorBearing - bearing < 0 ? bearing - 90 : priorBearing + 90;
            var controlPointSector = turf.sector(p1, LINE_WIDTH_IN_METERS, a0, a1, {
                units: 'meters'
            });
            surroundingPolygonComponents.push(controlPointSector);
        }
    }
    var surroundingPolygon = turf.polygon([]);
    for(var i1 = 0; i1 < surroundingPolygonComponents.length; i1++){
        surroundingPolygon = turf.union(surroundingPolygon, surroundingPolygonComponents[i1]);
    }
    // apply a fudge factor to make sure segments overlap and merge with rectangles
    surroundingPolygon = turf.buffer(surroundingPolygon, 0.001, {
        units: 'meters'
    });
    return surroundingPolygon.geometry.coordinates.map(function(ring) {
        return ring.map(function(point) {
            return new google.maps.LatLng(point[1], point[0]);
        });
    });
}
// Hand-rolled - uses geodesic("+a=6378137")
function computeRectangleAroundLocation(location, radius) {
    var EARTH_RADIUS_IN_KILOMETERS = 6378.137;
    var DEGREES_TO_RADIANS = Math.PI / 180.0;
    var RADIANS_TO_DEGREES = 180.0 / Math.PI;
    return {
        north: location.lat + radius.dy / EARTH_RADIUS_IN_KILOMETERS * RADIANS_TO_DEGREES,
        south: location.lat + -radius.dy / EARTH_RADIUS_IN_KILOMETERS * RADIANS_TO_DEGREES,
        east: location.lng + radius.dx / EARTH_RADIUS_IN_KILOMETERS * RADIANS_TO_DEGREES / Math.cos(location.lat * DEGREES_TO_RADIANS),
        west: location.lng + -radius.dx / EARTH_RADIUS_IN_KILOMETERS * RADIANS_TO_DEGREES / Math.cos(location.lat * DEGREES_TO_RADIANS)
    };
}
function computeSquarePolygonAroundLocation(location) {
    var size = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 50;
    // In the scenario where there is no region to add the selection to
    // create a 50x50 polygon aligned with the center of the user's view.
    var moveIn = function(oldPoint, bearing) {
        var newPoint = computeDestinationPoint(oldPoint, size / 2, bearing);
        return newPoint;
    };
    location = {
        latitude: location.lat(),
        longitude: location.lng()
    };
    var south = moveIn(location, 180);
    var southWest = moveIn(south, 270);
    var southEast = moveIn(south, 90);
    var north = moveIn(location, 0);
    var northWest = moveIn(north, 270);
    var northEast = moveIn(north, 90);
    var newRegion = {
        type: 'Polygon',
        include: [
            southWest,
            northWest,
            northEast,
            southEast
        ].map(function(c) {
            return [
                c.latitude,
                c.longitude
            ];
        }),
        exclude: []
    };
    return newRegion;
}
/**
 * Computes the **outer** perimeter of a shape defined by it's `include` and `exclude` coordinates.
 * Expects `include` to be a list of [lat, lng] coordinates.
 * Expects `exclude` to be a list of lists of [lat, lng] coordinates.
 */ function computePerimeter(include, exclude) {
    if (include.length < 1) {
        return 0;
    }
    var includeShifted = _to_consumable_array(_.drop(include, 1)).concat([
        _.first(include)
    ]);
    var zipped = _.zip(include, includeShifted);
    var perimeter = _.reduce(zipped, function(sum, param) {
        var _param = _sliced_to_array(param, 2), _param_ = _sliced_to_array(_param[0], 2), lat1 = _param_[0], lng1 = _param_[1], _param_1 = _sliced_to_array(_param[1], 2), lat2 = _param_1[0], lng2 = _param_1[1];
        return sum + getDistance({
            lat: lat1,
            lon: lng1
        }, {
            lat: lat2,
            lon: lng2
        });
    }, 0);
    return perimeter;
}
var geoJsonGeometryToTurfPolygonOrMultiPolygon = function(geometry) {
    if (geometry.type === 'Polygon') {
        return turf.polygon(geometry.coordinates);
    }
    if (geometry.type === 'MultiPolygon') {
        return turf.multiPolygon(geometry.coordinates);
    }
    throw new Error('Invalid feature type', geometry);
};
/**
 * Computes the union between two (multi-)polygons.
 * @param a GeoJSON geometry of a polygon or multi-polygon
 * @param b GeoJSON geometry of a polygon or multi-polygon
 * @returns GeoJSON geometry of the union of `a` and `b`
 */ function computeUnion(a, b) {
    var polyA = geoJsonGeometryToTurfPolygonOrMultiPolygon(a);
    var polyB = geoJsonGeometryToTurfPolygonOrMultiPolygon(b);
    var unionCoords = polyclip.union(polyA.geometry.coordinates, polyB.geometry.coordinates);
    if (unionCoords.length === 0) return null;
    return {
        type: 'MultiPolygon',
        coordinates: unionCoords
    };
}
/**
 * Computes the union between N (multi)-polygons.
 * @param geoms array of GeoJSON geometry of polygons of multi-polygon
 * @returns GeoJSON geometry of union of all `geoms`.
 */ function computeUnionMulti(geoms) {
    console.assert(geoms.length >= 1);
    return geoms.reduce(function(acc, geom) {
        if (!acc) {
            return geom;
        }
        return computeUnion(acc, geom);
    }, null);
}
/**
 * Computes the difference between two (multi-)polygons.
 * @param a GeoJSON geometry of a polygon or multi-polygon
 * @param b GeoJSON geometry of a polygon or multi-polygon
 * @returns GeoJSON geometry of the difference of `a` and `b`
 */ function computeDifference(a, b) {
    var polyA = geoJsonGeometryToTurfPolygonOrMultiPolygon(a);
    var polyB = geoJsonGeometryToTurfPolygonOrMultiPolygon(b);
    var differenceCoords = polyclip.difference(polyA.geometry.coordinates, polyB.geometry.coordinates);
    if (differenceCoords.length === 0) return null;
    return {
        type: 'MultiPolygon',
        coordinates: differenceCoords
    };
}
/**
 * Computes the intersection between two (multi-)polygons.
 * @param a GeoJSON geometry of a polygon or multi-polygon
 * @param b GeoJSON geometry of a polygon or multi-polygon
 * @returns GeoJSON geometry of the intersection of `a` and `b`. Can be null
 */ function computeIntersection(a, b) {
    var polyA = geoJsonGeometryToTurfPolygonOrMultiPolygon(a);
    var polyB = geoJsonGeometryToTurfPolygonOrMultiPolygon(b);
    var intersectionCoords = polyclip.intersection(polyA.geometry.coordinates, polyB.geometry.coordinates);
    if (intersectionCoords.length === 0) return null;
    return {
        type: 'MultiPolygon',
        coordinates: intersectionCoords
    };
}
function computeArea(feature) {
    var polygon = geoJsonGeometryToTurfPolygonOrMultiPolygon(feature);
    return turf.area(polygon);
}
/**
 * Computes the centroid of a polygon.
 * @param geometry GeoJSON geometry of a polygon.
 * @returns GeoJSON geometry
 */ function computeCentroid(geometry) {
    console.assert(geometry.type === 'Polygon' || geometry.type === 'MultiPolygon');
    var poly = geoJsonGeometryToTurfPolygonOrMultiPolygon(geometry);
    var centroid = turf.center(poly);
    if (!centroid) return null;
    return centroid.geometry;
}
var crsConverter = proj4(proj4.WGS84, 'EPSG:3857');
/**
 * Computes the visual center of a polygon.
 * @param geometry GeoJSON geometry of a polygon.
 * @returns GeoJSON Point.
 */ function computeVisualCenter(geometry) {
    assert(geometry.type === 'Polygon');
    var points = geometry.coordinates.map(function(coords) {
        return coords.map(crsConverter.forward);
    });
    var p = polylabel(points);
    var _ref = [
        p[0],
        p[1]
    ], cx = _ref[0], cy = _ref[1];
    var _crsConverter_inverse = _sliced_to_array(crsConverter.inverse([
        cx,
        cy
    ]), 2), cxt = _crsConverter_inverse[0], cyt = _crsConverter_inverse[1];
    return turf.point([
        cxt,
        cyt
    ]).geometry;
}
/**
 * Computes a rectangle that encompasses all vertices.
 * @param geometry GeoJSON geometry of a polygon or multi-polygon.
 * @returns GeoJSON geometry
 */ function computeMinEnvelope(geometry) {
    var poly = geoJsonGeometryToTurfPolygonOrMultiPolygon(geometry);
    var envelope = turf.envelope(poly);
    return envelope.geometry;
}
/**
 * Computes the width of a rectangle.
 * @param geometry GeoJSON geometry of a polygon.
 * @returns [width, height] in meters
 */ function computeWidthHeight(geometry) {
    console.assert(geometry.type === 'Polygon');
    var c = geometry.coordinates[0];
    var l0 = turf.lineString([
        c[0],
        c[1]
    ]);
    var l1 = turf.lineString([
        c[1],
        c[2]
    ]);
    var width = turf.length(l0, {
        units: 'meters'
    });
    var height = turf.length(l1, {
        units: 'meters'
    });
    return [
        width,
        height
    ];
}
/**
 * Runs a filter predicate on a polygon or each polygon of a multi-polygon.
 * @param geometry GeoJSON geometry of a polygon or multi-polygon.
 * @param filterFunc A function that takes a polygon and returns true if it should be kept.
 * @returns A multi-polygon containing only the geometries that passed the filter
 */ function filterPolygons(geometry, filterFunc) {
    var geoms = geometry.type === 'Polygon' ? [
        geometry.coordinates
    ] : geometry.coordinates;
    var keepPolys = [];
    var rejectPolys = [];
    _.forEach(function(geom) {
        if (filterFunc(geom)) {
            keepPolys.push(geom);
        } else {
            rejectPolys.push(geom);
        }
    }, geoms);
    console.log(keepPolys, rejectPolys);
    return turf.multiPolygon(keepPolys).geometry;
}
function computeBuffered(geometry, buffer) {
    return turf.buffer(geometry, buffer, {
        units: 'meters'
    }).geometry;
}
function computeTranslate(geometry, distance) {
    var direction = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0;
    return turf.transformTranslate(geometry, distance, direction, {
        units: 'meters'
    });
}
function toPoint(param) {
    var lng = param.lng, lat = param.lat;
    return turf.point([
        lng,
        lat
    ]).geometry;
}
/**
 * Hash to geometry.
 */ function geoDecode(hash) {
    var _geohash_decode_bbox = _sliced_to_array(geohash.decode_bbox(hash), 4), minLat = _geohash_decode_bbox[0], minLon = _geohash_decode_bbox[1], maxLat = _geohash_decode_bbox[2], maxLon = _geohash_decode_bbox[3];
    return turf.bboxPolygon([
        minLon,
        minLat,
        maxLon,
        maxLat
    ]).geometry;
}
function hasOverlap(a, b) {
    var polyA = geoJsonGeometryToTurfPolygonOrMultiPolygon(a);
    var polyB = geoJsonGeometryToTurfPolygonOrMultiPolygon(b);
    return turf.booleanIntersects(polyA, polyB);
}
export { computeArea, computeBuffered, computeCentroid, computeDifference, computeIntersection, computeMinEnvelope, computePerimeter, computeRectangleAroundLocation, computeSquarePolygonAroundLocation, computeSurroundingPolygonFromPolyline, computeTranslate, computeUnion, computeUnionMulti, computeVisualCenter, computeWidthHeight, filterPolygons, geoDecode, hasOverlap, toPoint };
