import { forEach, isEmpty } from 'lodash';
import _ from 'lodash/fp';
/**
 * @returns {Object} contains the compared object fields with value 'true' if the same keys have different values
 */ export var checkFieldsAreDiff = function(baseObj, compareObj) {
    var changedFields = {};
    if (!isEmpty(baseObj) && !isEmpty(compareObj)) {
        forEach(baseObj, function(baseValue, baseKey) {
            if (compareObj[baseKey] !== baseValue) {
                changedFields[baseKey] = true;
            }
        });
        return changedFields;
    }
    return changedFields;
};
var flatten = function(obj) {
    var prefix = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '';
    return Object.keys(obj).reduce(function(acc, k) {
        var pre = prefix.length ? prefix + '.' : '';
        if (typeof obj[k] === 'object' && obj[k] !== null) {
            Object.assign(acc, flatten(obj[k], pre + k));
        } else {
            acc[pre + k] = obj[k];
        }
        return acc;
    }, {});
};
var unflatten = function(flattedObject) {
    var result = {};
    _.keys(flattedObject).forEach(function(key) {
        result = _.set(key, flattedObject[key], result);
    });
    return result;
};
var objectUtilities = {
    flatten: flatten,
    unflatten: unflatten
};
export default objectUtilities;
