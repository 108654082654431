function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import text from '../../../../../text';
var TOURS = {
    TOUR_2D_MAP: 'TOUR_2D_MAP',
    TOUR_FILE_MANAGER: 'TOUR_FILE_MANAGER',
    TOUR_3D_VIEWER: 'TOUR_3D_VIEWER'
};
var MODAL_TOUR_NAMES = {
    TOUR_2D_MAP: '2d_map_tour',
    TOUR_FILE_MANAGER: 'file_manager_tour',
    TOUR_3D_VIEWER: '3d_viewer_tour'
};
var _obj;
var TOUR_MODAL_START_CONFIG = (_obj = {}, _define_property(_obj, TOURS.TOUR_2D_MAP, {
    gif: '/public/img/2D-map.gif',
    title: text('tourModal2dTitle'),
    description: text('tourModal2dDescription'),
    done: text('tourModal2dDone')
}), _define_property(_obj, TOURS.TOUR_FILE_MANAGER, {
    gif: '/public/img/filemanager.gif',
    title: text('tourModalFilemanagerTitle'),
    description: text('tourModalFilemanagerDescription'),
    done: text('tourModalFilemanagerDone')
}), _define_property(_obj, TOURS.TOUR_3D_VIEWER, {
    gif: '/public/img/3D_Viewer.gif',
    title: text('tourModal3dViewerTitle'),
    description: text('tourModal3dViewerDescription'),
    done: text('tourModal3dViewerDone')
}), _obj);
var hightlightedAreaStyling = function(base, param) {
    var x = param.x, y = param.y, width = param.width, height = param.height;
    return _object_spread_props(_object_spread({}, base), {
        display: 'block',
        stroke: '#f00',
        strokeWidth: 3,
        width: width + 6,
        height: height + 6,
        rx: 3,
        x: x - 3,
        y: y - 3,
        pointerEvents: 'none'
    });
};
var popoverCustomDefaultStyling = function(props) {
    return _object_spread_props(_object_spread({}, props), {
        borderRadius: 6,
        padding: 0
    });
};
export { TOURS, TOUR_MODAL_START_CONFIG, hightlightedAreaStyling, popoverCustomDefaultStyling, MODAL_TOUR_NAMES };
