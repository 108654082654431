function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { useEffect, useRef, useState } from 'react';
import { usePageVisibility } from './usePageVisibility';
export var usePoll = function(param) {
    var callback = param.callback, _param_startEnabled = param.startEnabled, startEnabled = _param_startEnabled === void 0 ? false : _param_startEnabled, _param_delay = param.delay, delay = _param_delay === void 0 ? 5000 : _param_delay, _param_dependencies = param.dependencies, dependencies = _param_dependencies === void 0 ? [] : _param_dependencies;
    // `callback` is called every `delay` ms
    // only when the page is visible
    // and when `isPollingEnabled` is true.
    // Return a function that can be called to enable or disable polling.
    var isPageVisible = usePageVisibility();
    var timerIdRef = useRef(null);
    var _useState = _sliced_to_array(useState(startEnabled), 2), isPollingEnabled = _useState[0], setIsPollingEnabled = _useState[1];
    useEffect(function() {
        var pollingCallback = function() {
            callback(function() {
                return setIsPollingEnabled(false);
            });
        };
        var startPolling = function() {
            pollingCallback();
            timerIdRef.current = setInterval(pollingCallback, delay);
        };
        var stopPolling = function() {
            clearInterval(timerIdRef.current);
        };
        if (isPageVisible && isPollingEnabled) {
            startPolling();
        } else {
            stopPolling();
        }
        return function() {
            stopPolling();
        };
    }, [
        isPageVisible,
        isPollingEnabled
    ].concat(_to_consumable_array(dependencies)));
    return {
        poll: setIsPollingEnabled,
        pollOnce: callback
    };
};
