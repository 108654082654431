import { format } from 'date-fns';
var DATE = 'dd MMM yyyy';
var TIME = 'h:mm aaaa';
var MONTH = 'MMM yyyy';
export var FORMAT = {
    DATE: DATE,
    TIME: TIME
};
export var formatDate = function(dateString) {
    return dateString && format(new Date(dateString), DATE);
};
export var formatTime = function(dateString) {
    var customFormat = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : TIME;
    return dateString && format(new Date(dateString), customFormat);
};
export var formatMonth = function(dateString) {
    return dateString && format(new Date(dateString), MONTH);
};
export var getNowDate = function() {
    return new Date().toISOString();
};
