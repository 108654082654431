function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { useState } from 'react';
import { MAX_MAP_ZOOM, MIN_MAP_ZOOM } from '../components/mapView/constants';
import { getDistanceInPixels } from '../components/mapView/drawingManager/utilities';
import { computeTranslate, toPoint } from '../components/mapView/geometry';
import { getGMBoundsZoomLevel } from '../utilities/map';
import * as numberUtils from '../utilities/number';
var useZoom = function(mapRef, initialZoom) {
    var minZoom = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : MIN_MAP_ZOOM, maxZoom = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : MAX_MAP_ZOOM;
    // todo: this zoom should be in MapViewContext since there should only be one zoom for the entire map and all instances of useZoom should share it
    var _useState = _sliced_to_array(useState(initialZoom), 2), _zoom = _useState[0], _setZoom = _useState[1];
    var setZoom = function(map, value) {
        var updateMap = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : true;
        var clamped = numberUtils.clamp(value, minZoom, maxZoom);
        _setZoom(clamped);
        if (updateMap) {
            map.setZoom(clamped);
        }
    };
    var increaseZoom = function(map, increaseBy) {
        var newZoom = _zoom + increaseBy;
        setZoom(map, newZoom);
    };
    var decreaseZoom = function(map, decreaseBy) {
        return increaseZoom(map, -decreaseBy);
    };
    var fitBounds = function(map, bounds, zoom) {
        var padding = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : 0;
        var mapDim = {
            width: mapRef.current.offsetWidth,
            height: mapRef.current.offsetHeight
        };
        // hacky way of determining the pixels per meter on the map
        // todo: this isn't exact, should be fixed
        var gmCenter = map.getCenter();
        var centerOffset = computeTranslate(toPoint({
            lng: gmCenter.lng(),
            lat: gmCenter.lat()
        }), padding);
        var gmCenterOffset = new google.maps.LatLng(centerOffset.coordinates[1], centerOffset.coordinates[0]);
        var paddingInPixels = getDistanceInPixels(gmCenter, gmCenterOffset, map, zoom);
        map.fitBounds(bounds, paddingInPixels);
        var newZoom = getGMBoundsZoomLevel(bounds, mapDim, padding, MAX_MAP_ZOOM);
        setZoom(map, newZoom, false);
        // timeout here so that it isn't overridden by fitBounds
        // todo: find another way
        setTimeout(function() {
            map.setZoom(newZoom);
        }, 50);
    };
    return {
        state: {
            zoom: _zoom
        },
        actions: {
            setZoom: setZoom,
            increaseZoom: increaseZoom,
            decreaseZoom: decreaseZoom,
            fitBounds: fitBounds
        }
    };
};
export default useZoom;
