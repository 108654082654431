import routes from '../routes/constants';
import { isRouteIncluded, isRouteMatching } from './routes';
var SHARE_LINK_ROUTES = [
    routes.fileManager.viewProject(),
    routes.order.job(),
    routes.view3D.project()
];
var SHARE_LINK_ROUTES_WITH_ORDER_ID = [
    routes.order.job()
];
var SHARE_LINK_ROUTES_WITH_HOME_ICON = [
    routes.view3D.project()
];
var isShareLinkVisible = function(pathname) {
    return isRouteIncluded(SHARE_LINK_ROUTES)(pathname);
};
var extractRouteParam = function(pathname) {
    var _param;
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = SHARE_LINK_ROUTES[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var route = _step.value;
            var match = isRouteMatching(pathname)(route);
            if (match) {
                if (isRouteIncluded(SHARE_LINK_ROUTES_WITH_ORDER_ID)(route)) {
                    _param = match.params.jobId;
                } else {
                    _param = match.params.projectId;
                }
            }
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return _param ? parseInt(_param, 10) : null;
};
var doesPathHaveOrderId = function(pathname) {
    return SHARE_LINK_ROUTES_WITH_ORDER_ID.some(function(route) {
        return isRouteMatching(pathname)(route);
    });
};
var shouldShowHomeIcon = function(pathname) {
    return isRouteIncluded(SHARE_LINK_ROUTES_WITH_HOME_ICON)(pathname);
};
var isRoute2dMap = function(pathname) {
    return isRouteIncluded([
        routes.order.job()
    ])(pathname);
};
var isRoute3dViewer = function(pathname) {
    return isRouteIncluded([
        routes.view3D.project()
    ])(pathname);
};
var shareLinkUtilities = {
    isShareLinkVisible: isShareLinkVisible,
    extractRouteParam: extractRouteParam,
    doesPathHaveOrderId: doesPathHaveOrderId,
    shouldShowHomeIcon: shouldShowHomeIcon,
    isRoute2dMap: isRoute2dMap,
    isRoute3dViewer: isRoute3dViewer
};
export default shareLinkUtilities;
