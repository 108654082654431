function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { useGesture } from '@use-gesture/react';
import { __ } from 'lodash/fp';
import { useContext, useEffect, useState } from 'react';
import useDebounceCollect from '../../hooks/useDebounceCollect';
import * as numberUtils from '../../utilities/number';
import { MapViewContext } from './mapViewContext';
var SCROLL_WHEEL_SENS = 8e-4;
var TRACKPAD_SENS = 1e-4;
function useSmoothMapZoom() {
    var _useContext = useContext(MapViewContext), state = _useContext.state, actions = _useContext.actions;
    // custom scrollwheel/pinch events for controlling zoom
    // from experience: the map appears to debounce setZoom calls;
    //  so we can't send setZoom events at too high a frequency or they will all be debounced
    //  until the user stops scrolling (this is most noticeable on a trackpad)
    // to fix this, we useDebounceCollect
    var _useDebounceCollect = useDebounceCollect(function(wheelState) {
        return wheelState;
    }, 50), updateWheelState = _useDebounceCollect.callFunc, collectedWheelState = _useDebounceCollect.debounced;
    var _useState = _sliced_to_array(useState(false), 2), isTrackpad = _useState[0], setIsTrackpad = _useState[1];
    var mapBind = useGesture({
        onWheel: function(wheelState) {
            var _wheelState_event;
            if (!wheelState.active) {
                return;
            }
            var wheelDelta = (_wheelState_event = wheelState.event) === null || _wheelState_event === void 0 ? void 0 : _wheelState_event.nativeEvent.wheelDelta;
            var _isTrackpad = wheelDelta && Math.abs(wheelDelta) <= 50;
            if (_isTrackpad) {
                setIsTrackpad(true);
            } else {
                setIsTrackpad(false);
                var zoomChange = -SCROLL_WHEEL_SENS * wheelState.movement[1];
                actions.zoom.increaseZoom(state.map, zoomChange);
            }
            updateWheelState(wheelState);
        },
        onPinch: function(pinchState) {
            console.log('pinch', pinchState);
        }
    });
    useEffect(function() {
        if (!isTrackpad) {
            return;
        }
        if (!state.map) {
            return;
        }
        var zoomChange = -TRACKPAD_SENS * __.sum(collectedWheelState.map(function(wheelState) {
            return wheelState.movement[1];
        }));
        zoomChange = numberUtils.clamp(zoomChange, -1, 1);
        actions.zoom.increaseZoom(state.map, zoomChange);
    }, [
        collectedWheelState
    ]);
    return mapBind;
}
export default useSmoothMapZoom;
