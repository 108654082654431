function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import _ from 'lodash/fp';
import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminJobsList, getJobsList } from '../redux/actions';
var useProjectsList = function(param) {
    var isAdmin = param.isAdmin, _param_filterPredicate = param.filterPredicate, filterPredicate = _param_filterPredicate === void 0 ? _.stubTrue : _param_filterPredicate, _param_deps = param.deps, deps = _param_deps === void 0 ? [] : _param_deps;
    var dispatch = useDispatch();
    var _useSelector = useSelector(function(state) {
        return {
            jobs: state.jobsReducer.jobsList,
            isJobsListLoading: state.jobsReducer.isJobsListLoading,
            adminJobs: state.jobsReducer.adminJobList,
            isAdminJobListLoading: state.jobsReducer.isAdminJobListLoading
        };
    }), jobs = _useSelector.jobs, adminJobs = _useSelector.adminJobs, isJobsListLoading = _useSelector.isJobsListLoading, isAdminJobListLoading = _useSelector.isAdminJobListLoading;
    var projects = useMemo(function() {
        return _.flow(_.map(function(job) {
            return job.project;
        }), _.orderBy('updated_at', [
            'desc'
        ]), _.filter(filterPredicate))(isAdmin ? adminJobs : jobs);
    }, [
        isAdmin,
        adminJobs,
        jobs
    ]);
    var isLoading = useMemo(function() {
        return isAdmin ? isAdminJobListLoading : isJobsListLoading;
    }, [
        isAdmin,
        isAdminJobListLoading,
        isJobsListLoading
    ]);
    var hasFetchedJobsList = useRef(false);
    var hasFetchedAdminJobList = useRef(false);
    useEffect(function() {
        if (!deps.every(Boolean)) {
            return;
        }
        if (isAdmin && !hasFetchedAdminJobList.current) {
            hasFetchedAdminJobList.current = true;
            dispatch(getAdminJobsList());
        } else if (!isAdmin && !hasFetchedJobsList.current) {
            hasFetchedJobsList.current = true;
            dispatch(getJobsList());
        }
    }, [
        isAdmin
    ].concat(_to_consumable_array(deps)));
    return {
        projects: projects,
        isLoading: isLoading
    };
};
export default useProjectsList;
