import Cookies from 'universal-cookie';
var cookies = new Cookies(null, {
    sameSite: 'lax',
    domain: process.env.COOKIE_DOMAIN,
    secure: !!process.env.COOKIE_SECURE
});
export function getUser() {
    try {
        var user = sessionStorage.getItem('user') || localStorage.getItem('user');
        return JSON.parse(user);
    } catch (e) {
        return null;
    }
}
export function getUserCookie() {
    var user = cookies.get('user');
    if (!user) return null;
    return user;
}
export function setUser(user) {
    var session = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    cookies.set('user', user);
    return session ? sessionStorage.setItem('user', JSON.stringify(user)) : localStorage.setItem('user', JSON.stringify(user));
}
export function clearUser() {
    cookies.remove('user');
    localStorage.clear();
    sessionStorage.clear();
}
export function isImpersonating() {
    try {
        var user = sessionStorage.getItem('user');
        if (!user) {
            return false;
        }
        var parsedUser = JSON.parse(user);
        if (parsedUser && parsedUser.is_anonymous) {
            return false;
        }
        return user !== null;
    } catch (e) {
        return false;
    }
}
