import { matchPath } from 'react-router-dom';
export var isRouteIncluded = function(routesToMatch) {
    return function(route) {
        var matches = routesToMatch.map(function(path) {
            return matchPath(route, {
                path: path
            });
        });
        return matches.some(Boolean);
    };
};
export var isRouteMatching = function(currentPath) {
    return function(path) {
        var match = matchPath(currentPath, {
            path: path
        });
        return match;
    };
};
