var appareance = {
    theme: 'none',
    variables: {
        fontFamily: '"Roboto", sans-serif'
    },
    rules: {
        '.Input': {
            borderStyle: 'solid',
            borderWidth: '1px',
            borderColor: '#4a4a4a',
            outline: 'none',
            margin: 'auto 0'
        },
        '.Input:focus-visible': {
            borderColor: '#04c28e',
            boxShadow: '0px 0px 8px #c7feef, 0px 10px 20px rgba(18, 42, 66, 0.02), 0 0 0 1px #c7feef'
        },
        '.Input--invalid': {
            borderColor: '#ef364d'
        },
        '.Input::placeholder': {
            color: '#9b9b9b'
        },
        '.Input--invalid:focus-visible': {
            outline: 'none',
            borderColor: '#ef364d',
            boxShadow: 'none'
        },
        '.Error': {
            fontSize: '11px'
        },
        '.Label': {
            fontSize: '14px',
            fontWeight: '500',
            color: '#4a4a4a',
            '-webkit-font-smoothing': 'auto'
        }
    }
};
export default appareance;
