import * as turf from '@turf/turf';
/**
 * path: [[lng, lat], [lng, lat], ...]
 */ export var isPathClockwiseNoGM = function(path) {
    var area = 0;
    for(var i = 0; i < path.length; i++){
        var j = (i + 1) % path.length;
        area += path[i][1] * path[j][0];
        area -= path[j][1] * path[i][0];
    }
    return area > 0;
};
/**
 * path: [[lat, lng], [lat, lng], ...]
 */ export function computeSurroundingPolygonFromPolylineNoGM(path) {
    var LINE_WIDTH_IN_METERS = 20;
    var surroundingPolygonComponents = [];
    for(var i = 0; i < path.length - 1; i++){
        var p1 = turf.point([
            path[i][1],
            path[i][0]
        ]);
        var p2 = turf.point([
            path[i + 1][1],
            path[i + 1][0]
        ]);
        var bearing = turf.bearing(p1, p2);
        var p0a = turf.destination(p1, LINE_WIDTH_IN_METERS, bearing + 90, {
            units: 'meters'
        });
        var p0b = turf.destination(p1, LINE_WIDTH_IN_METERS, bearing - 90, {
            units: 'meters'
        });
        var p1a = turf.destination(p2, LINE_WIDTH_IN_METERS, bearing + 90, {
            units: 'meters'
        });
        var p1b = turf.destination(p2, LINE_WIDTH_IN_METERS, bearing - 90, {
            units: 'meters'
        });
        var surroundingRectangle = turf.polygon([
            [
                turf.getCoord(p0a),
                turf.getCoord(p0b),
                turf.getCoord(p1b),
                turf.getCoord(p1a),
                turf.getCoord(p0a)
            ]
        ]);
        surroundingPolygonComponents.push(surroundingRectangle);
        if (i > 0 && i < path.length - 1) {
            var p0 = turf.point([
                path[i - 1][1],
                path[i - 1][0]
            ]);
            var priorBearing = turf.bearing(p0, p1);
            var a0 = priorBearing - bearing < 0 ? priorBearing - 90 : bearing + 90;
            var a1 = priorBearing - bearing < 0 ? bearing - 90 : priorBearing + 90;
            var controlPointSector = turf.sector(p1, LINE_WIDTH_IN_METERS, a0, a1, {
                units: 'meters'
            });
            surroundingPolygonComponents.push(controlPointSector);
        }
    }
    var surroundingPolygon = turf.polygon([]);
    for(var i1 = 0; i1 < surroundingPolygonComponents.length; i1++){
        surroundingPolygon = turf.union(surroundingPolygon, surroundingPolygonComponents[i1]);
    }
    // apply a fudge factor to make sure segments overlap and merge with rectangles
    surroundingPolygon = turf.buffer(surroundingPolygon, 0.001, {
        units: 'meters'
    });
    return surroundingPolygon.geometry.coordinates;
}
export var selectionToGeoJsonNoGM = function(geometry) {
    var coordinates = [];
    if (geometry.region.type === 'Polygon') {
        var _geometry_region_exclude;
        // the latitude & longitude pairs need to be reversed to be valid GeoJSON
        coordinates.push(geometry.region.include.map(function(point) {
            return [
                point[1],
                point[0]
            ];
        }));
        (_geometry_region_exclude = geometry.region.exclude) === null || _geometry_region_exclude === void 0 ? void 0 : _geometry_region_exclude.forEach(function(excludedRegion) {
            coordinates.push(excludedRegion.map(function(point) {
                return [
                    point[1],
                    point[0]
                ];
            }));
        });
    } else if (geometry.region.type === 'Rectangle') {
        var _geometry_region_exclude1;
        if (geometry.region.include.length === 2) {
            var path = [];
            path.push([
                geometry.region.include[0][1],
                geometry.region.include[0][0]
            ]);
            path.push([
                geometry.region.include[1][1],
                geometry.region.include[0][0]
            ]);
            path.push([
                geometry.region.include[1][1],
                geometry.region.include[1][0]
            ]);
            path.push([
                geometry.region.include[0][1],
                geometry.region.include[1][0]
            ]);
            path.push([
                geometry.region.include[0][1],
                geometry.region.include[0][0]
            ]);
            coordinates.push(path);
        } else {
            coordinates.push(geometry.region.include.map(function(point) {
                return [
                    point[1],
                    point[0]
                ];
            }));
        }
        (_geometry_region_exclude1 = geometry.region.exclude) === null || _geometry_region_exclude1 === void 0 ? void 0 : _geometry_region_exclude1.forEach(function(excludedRegion) {
            coordinates.push(excludedRegion.map(function(point) {
                return [
                    point[1],
                    point[0]
                ];
            }));
        });
    } else if (geometry.region.type === 'Polyline') {
        var path1 = geometry.region.include;
        var polygon = computeSurroundingPolygonFromPolylineNoGM(path1, geometry.region.width);
        coordinates = polygon;
    }
    // make sure the last pair equals the first pair to close the polygon
    coordinates.forEach(function(coordinatePoints, index, currentCoordinates) {
        if (coordinatePoints.length > 1 && (coordinatePoints[0][0] !== coordinatePoints[coordinatePoints.length - 1][0] || coordinatePoints[0][1] !== coordinatePoints[coordinatePoints.length - 1][1])) {
            currentCoordinates[index].push(coordinatePoints[0]);
        }
    });
    // check winding order of path
    // first polygon should be clockwise
    // subsequent ones should be anticlockwise
    coordinates.forEach(function(polygonPath, index, currentCoordinates) {
        if (index === 0 && isPathClockwiseNoGM(polygonPath) || index > 0 && !isPathClockwiseNoGM(polygonPath)) {
            currentCoordinates[index] = polygonPath.reverse();
        }
    });
    return {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'Polygon',
            coordinates: coordinates
        }
    };
};
