function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
export var ANONYMOUS_DASHBOARD = 'anonymousDashboard';
export var ANONYMOUS_ORDER = 'anonymousOrder';
export var ANONYMOUS_HIGH_RES = 'anonymousHighRes';
var _obj;
export var ANONYMOUS_ALERT = (_obj = {}, _define_property(_obj, ANONYMOUS_DASHBOARD, {
    type: 'ALERT_INFO',
    message: 'goToDashboardPleaseSignUp'
}), _define_property(_obj, ANONYMOUS_ORDER, {
    type: 'ALERT_INFO',
    message: 'goToOrderPleaseSignUp'
}), _define_property(_obj, ANONYMOUS_HIGH_RES, {
    type: 'ALERT_INFO',
    message: 'toContinueWithHighResMap'
}), _obj);
