import text, { formatCurrency } from '../text';
export var BILLING_CYCLE = {
    day: 'daily',
    week: 'weekly',
    month: 'monthly',
    year: 'yearly'
};
export var ENTITLEMENTS = {
    HIGH_RES_MAP: 'high-res-map',
    _3D_VIEWER: '3d-viewer'
};
export var MEMBERSHIP = {
    MONTHLY: {
        PRICE_ID: process.env.STRIPE_SUBSCRIPTION_MONTHLY_PRICE_ID
    },
    YEARLY: {
        PRICE_ID: process.env.STRIPE_SUBSCRIPTION_YEARLY_PRICE_ID
    },
    NO_MEMBERSHIP: 'noMembership'
};
export var STATUS = {
    TRIALING: 'trialing'
};
export var TRIAL_MEMBERSHIP_BENEFITS = [
    text('trial2DMapBenefit'),
    text('trial3DViewerBenefit'),
    text('trialFileManagerBenefit'),
    text('trialSupportBenefit'),
    text('trialFreeTierBenefit'),
    text('andMoreBenefit')
];
export var ESSENTIALS_MEMBERSHIP_BENEFITS = [
    text('free2DMapBenefit'),
    text('freeLotAreaBenefit'),
    text('essentialsFileManagerBenefit1'),
    text('essentialsFileManagerBenefit2'),
    text('essentialsDiscountBenefit'),
    text('essentialsScanServicesDiscountBenefit'),
    text('essentials3DViewerDiscountBenefit1'),
    text('essentials3DViewerDiscountBenefit2'),
    text('andMoreBenefit')
];
export var FREE_BENEFITS = [
    text('free2DMapBenefit'),
    text('freeLotAreaBenefit'),
    text('freeDownloadFileBenefit'),
    text('freeFileBenefit'),
    text('freeDiscountBenefit'),
    text('andMoreBenefit')
];
export var SUBSCRIPTION_PRICE = {
    monthly: formatCurrency(99, 'aud', 1, {}, true),
    yearly: formatCurrency(990, 'aud', 1, {}, true)
};
