function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { nanoid } from 'nanoid';
import geohash from 'ngeohash';
import _ from 'lodash/fp';
import { MAP_TYPE, MAX_MAP_ZOOM, NZ_STATES } from '../components/mapView/constants';
import { computeSurroundingPolygonFromPolyline } from '../components/mapView/geometry';
import { NON_TRIPOD_DATA_TYPE } from '../constants/product';
import polylabel from 'polylabel';
var isPathClockwise = function(path) {
    var area = 0;
    for(var i = 0; i < path.length; i++){
        var j = (i + 1) % path.length;
        area += path[i][0] * path[j][1];
        area -= path[j][0] * path[i][1];
    }
    return area < 0;
};
export var convertToGeoJson = function(regionSelection) {
    // for each geometry object generate a GeoJSON feature
    var features = [];
    regionSelection.forEach(function(geometry) {
        // add include and exclude geometries to GeoJSON coordinates
        var coordinates = [];
        if (geometry.region.type === 'Polygon') {
            var _geometry_region_exclude;
            // the latitude & longitude pairs need to be reversed to be valid GeoJSON
            coordinates.push(geometry.region.include.map(function(point) {
                return [
                    point[1],
                    point[0]
                ];
            }));
            (_geometry_region_exclude = geometry.region.exclude) === null || _geometry_region_exclude === void 0 ? void 0 : _geometry_region_exclude.forEach(function(excludedRegion) {
                coordinates.push(excludedRegion.map(function(point) {
                    return [
                        point[1],
                        point[0]
                    ];
                }));
            });
        } else if (geometry.region.type === 'Rectangle') {
            var _geometry_region_exclude1;
            if (geometry.region.include.length === 2) {
                var path = [];
                path.push([
                    geometry.region.include[0][1],
                    geometry.region.include[0][0]
                ]);
                path.push([
                    geometry.region.include[1][1],
                    geometry.region.include[0][0]
                ]);
                path.push([
                    geometry.region.include[1][1],
                    geometry.region.include[1][0]
                ]);
                path.push([
                    geometry.region.include[0][1],
                    geometry.region.include[1][0]
                ]);
                path.push([
                    geometry.region.include[0][1],
                    geometry.region.include[0][0]
                ]);
                coordinates.push(path);
            } else {
                coordinates.push(geometry.region.include.map(function(point) {
                    return [
                        point[1],
                        point[0]
                    ];
                }));
            }
            (_geometry_region_exclude1 = geometry.region.exclude) === null || _geometry_region_exclude1 === void 0 ? void 0 : _geometry_region_exclude1.forEach(function(excludedRegion) {
                coordinates.push(excludedRegion.map(function(point) {
                    return [
                        point[1],
                        point[0]
                    ];
                }));
            });
        } else if (geometry.region.type === 'Polyline') {
            var path1 = _.map(function(param) {
                var _param = _sliced_to_array(param, 2), lat = _param[0], lng = _param[1];
                return new google.maps.LatLng(lat, lng);
            }, geometry.region.include);
            var polyline = new google.maps.Polyline({
                path: path1,
                type: 'polyline'
            });
            var polygon = computeSurroundingPolygonFromPolyline(polyline, geometry.region.width);
            coordinates = polygon.map(function(ring) {
                return ring.map(function(point) {
                    return [
                        point.lng(),
                        point.lat()
                    ];
                });
            });
        }
        // make sure the last pair equals the first pair to close the polygon
        coordinates.forEach(function(coordinatePoints, index, currentCoordinates) {
            if (coordinatePoints.length > 1 && (coordinatePoints[0][0] !== coordinatePoints[coordinatePoints.length - 1][0] || coordinatePoints[0][1] !== coordinatePoints[coordinatePoints.length - 1][1])) {
                currentCoordinates[index].push(coordinatePoints[0]);
            }
        });
        // check winding order of path
        // first polygon should be clockwise
        // subsequent ones should be anticlockwise
        coordinates.forEach(function(polygonPath, index, currentCoordinates) {
            if (index === 0 && isPathClockwise(polygonPath) || index > 0 && !isPathClockwise(polygonPath)) {
                currentCoordinates[index] = polygonPath.reverse();
            }
        });
        // add the new feature to the feature list
        features.push({
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Polygon',
                coordinates: coordinates
            }
        });
    });
    // return a feature collection object
    return {
        type: 'FeatureCollection',
        features: features
    };
};
export var convertToGoogleGeometry = function(region) {
    var paths = [];
    var bounds = new google.maps.LatLngBounds();
    region.forEach(function(geometry) {
        var latLng = new google.maps.LatLng({
            lat: geometry[1],
            lng: geometry[0]
        });
        paths.push(latLng);
        bounds.extend(latLng);
    });
    return {
        paths: paths,
        bounds: bounds
    };
};
export var convertGeoJsonToRegionSelection = function(geoJson) {
    var geometry = [];
    geoJson.features.forEach(function(feature) {
        var exclude = [];
        feature.geometry.coordinates.slice(1).forEach(function(geometry) {
            exclude.push(geometry.map(function(points) {
                return [
                    points[1],
                    points[0]
                ];
            }).slice(0, -1));
        });
        geometry.push({
            data: {},
            region: {
                type: 'Polygon',
                include: feature.geometry.coordinates[0].map(function(points) {
                    return [
                        points[1],
                        points[0]
                    ];
                }).slice(0, -1),
                exclude: exclude
            }
        });
    });
    return geometry;
};
export var convertSelectionToRegionSelection = function(selection) {
    var _selection_ui_state;
    var type = ((_selection_ui_state = selection.ui_state) === null || _selection_ui_state === void 0 ? void 0 : _selection_ui_state.type) || selection.region.type;
    var include = type === 'Polyline' ? selection.ui_state.line_path.map(function(points) {
        return [
            points[1],
            points[0]
        ];
    }) : selection.region.coordinates[0].map(function(points) {
        return [
            points[1],
            points[0]
        ];
    }).slice(0, -1);
    var exclude = [];
    selection.region.coordinates.slice(1).forEach(function(coordinates, index, currentCoordinates) {
        if (index === 0 && isPathClockwise(coordinates) || index > 0 && !isPathClockwise(coordinates)) {
            currentCoordinates[index] = coordinates.reverse();
        }
        exclude.push(coordinates.map(function(points) {
            return [
                points[1],
                points[0]
            ];
        }).slice(0, -1));
    });
    return {
        type: type,
        include: include,
        exclude: exclude
    };
};
export var createId = function() {
    return nanoid();
};
export var getRegionBounds = function(region) {
    var bounds = new google.maps.LatLngBounds();
    region.include.forEach(function(param) {
        var _param = _sliced_to_array(param, 2), lat = _param[0], lng = _param[1];
        bounds.extend({
            lat: lat,
            lng: lng
        });
    });
    return bounds;
};
export var isShapeInsideBounds = function(bounds, geometryShape) {
    var allCoordinatesContain = geometryShape.coordinates.map(function(param) {
        var _param = _sliced_to_array(param, 2), lat = _param[0], lng = _param[1];
        return bounds.contains({
            lat: lat,
            lng: lng
        });
    });
    return allCoordinatesContain.every(Boolean);
};
export var isRegionInsideBounds = function(bounds, region) {
    return isShapeInsideBounds(bounds, {
        type: region.type,
        coordinates: region.include
    });
};
export var getSimplePlaceFromGoogle = function(placeResult) {
    if (!(placeResult && placeResult.address_components && placeResult.geometry)) {
        return null;
    }
    var address = '';
    var city = '';
    var state = '';
    var postcode = '';
    placeResult.address_components.forEach(function(addressComponent) {
        if (addressComponent.types.includes('street_number')) {
            if (!address) {
                address = addressComponent.long_name;
            } else {
                address = "".concat(addressComponent.long_name, " ").concat(address);
            }
        } else if (addressComponent.types.includes('route')) {
            if (!address) {
                address = addressComponent.long_name;
            } else {
                address = "".concat(address, " ").concat(addressComponent.long_name);
            }
        } else if (addressComponent.types.includes('locality')) {
            city = addressComponent.long_name;
        } else if (addressComponent.types.includes('administrative_area_level_1')) {
            state = addressComponent.long_name;
        } else if (addressComponent.types.includes('postal_code')) {
            postcode = addressComponent.long_name;
        }
    });
    return {
        location: {
            lat: placeResult.geometry.location.lat(),
            lng: placeResult.geometry.location.lng()
        },
        address: address,
        city: city,
        state: state,
        postcode: postcode
    };
};
export var hasPlaceStreetNumber = function(place) {
    return place && place.address_components && place.address_components.some(function(component) {
        return component.types && component.types.includes('street_number');
    });
};
export var getHighResMapType = function(place) {
    return (place === null || place === void 0 ? void 0 : place.state) && NZ_STATES.includes(place.state) ? MAP_TYPE.HIGH_RES_NZ : MAP_TYPE.HIGH_RES;
};
export var stringCoords2LatLng = function(coordsStr) {
    // Converts <lat>,<lng> string to { lat: <lat>, Lng: <lng> } object.
    var coords = coordsStr.split(',').map(parseFloat);
    return {
        lat: coords[0],
        lng: coords[1]
    };
};
var GEOCODE_PRECISION = 7; // 152.9m x 152.4m
/**
 * Encodes a Google Maps LatLng object to a geohash string.
 * @param {google.maps.LatLng} latLng
 * @returns Geohash
 */ export var geoEncodeLatLng = function(latLng) {
    return geohash.encode(latLng.lat(), latLng.lng(), GEOCODE_PRECISION);
};
/**
 * Encodes a Google Maps LatLngBounds object to a list of geohashes.
 * @param {google.maps.LatLngBounds} bounds
 * @returns Geohashes
 */ export var geoEncodeBounds = function(bounds) {
    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();
    var _ref = [
        sw.lat(),
        ne.lat()
    ], minLat = _ref[0], maxLat = _ref[1];
    var _ref1 = [
        sw.lng(),
        ne.lng()
    ], minLng = _ref1[0], maxLng = _ref1[1];
    return geohash.bboxes(minLat, minLng, maxLat, maxLng, GEOCODE_PRECISION);
};
/**
 * Decodes a geohash string to a Google Maps LatLngBounds object.
 * @param {string} hash
 * @returns Google Maps LatLngBounds object
 */ export var geoDecode = function(hash) {
    var _geohash_decode_bbox = _sliced_to_array(geohash.decode_bbox(hash), 4), minLat = _geohash_decode_bbox[0], minLon = _geohash_decode_bbox[1], maxLat = _geohash_decode_bbox[2], maxLon = _geohash_decode_bbox[3];
    return new google.maps.LatLngBounds(new google.maps.LatLng(minLat, minLon), new google.maps.LatLng(maxLat, maxLon));
};
/**
 * Converts a Google Maps bounds object to a GeoJSON feature.
 * @param {google.maps.LatLngBounds} bounds
 * @returns GeoJSON polygon feature
 */ export var convertGMBoundsToGeoJson = function(bounds) {
    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();
    var start = [
        sw.lng(),
        sw.lat()
    ];
    var coordinates = [
        [
            start,
            [
                ne.lng(),
                sw.lat()
            ],
            [
                ne.lng(),
                ne.lat()
            ],
            [
                sw.lng(),
                ne.lat()
            ],
            start
        ]
    ];
    return {
        type: 'Polygon',
        coordinates: coordinates
    };
};
/**
 * Converts a Google Maps polygon object to a GeoJSON feature.
 * @param {google.maps.Polygon} polygon
 * @returns GeoJSON polygon feature
 */ export var convertGMPolygonToGeoJson = function(polygon) {
    console.assert(polygon.type === 'polygon');
    console.assert(polygon.getPath().getLength() >= 3);
    var coordinates = polygon.getPath().getArray().map(function(latLng) {
        return [
            latLng.lng(),
            latLng.lat()
        ];
    });
    coordinates.push(coordinates[0]);
    return {
        type: 'Polygon',
        coordinates: [
            coordinates
        ]
    };
};
/**
 * Converts a Google Maps rectangle object to a GeoJSON feature.
 * @param {google.maps.Rectangle} rectangle
 * @returns GeoJSON polygon feature
 */ export var convertGMRectangleToGeoJson = function(rectangle) {
    console.assert(rectangle.type === 'rectangle');
    return convertGMBoundsToGeoJson(rectangle.bounds);
};
/**
 * Converts a Google Maps rectangle object to a GeoJSON feature.
 * @param {google.maps.Polyline} polyline
 * @returns GeoJSON polygon feature
 */ export var convertGMPolylineToGeoJson = function(polyline) {
    console.assert(polyline.type === 'polyline');
    var path = computeSurroundingPolygonFromPolyline(polyline)[0];
    var coords = path.map(function(latLng) {
        return [
            latLng.lng(),
            latLng.lat()
        ];
    });
    return {
        type: 'Polygon',
        coordinates: [
            coords
        ]
    };
};
export var convertGMGeometryToGeoJson = function(geometry) {
    if (geometry.type === 'rectangle') {
        return convertGMRectangleToGeoJson(geometry);
    }
    if (geometry.type === 'polygon') {
        return convertGMPolygonToGeoJson(geometry);
    }
    if (geometry.type === 'polyline') {
        return convertGMPolylineToGeoJson(geometry);
    }
    throw new Error("unknown geometry type ".concat(geometry.type));
};
export var getGMGeometryBounds = function(geometry) {
    if (geometry.type === 'rectangle') {
        return geometry.getBounds();
    }
    if (geometry.type === 'polygon' || geometry.type === 'polyline') {
        var bounds = new google.maps.LatLngBounds();
        var coords = geometry.getPath();
        coords.forEach(function(coord) {
            bounds.extend(coord);
        });
        return bounds;
    }
    throw new Error("unknown geometry type ".concat(geometry.type));
};
export var convertGMBoundsToBBox = function(bounds) {
    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();
    var _ref = [
        sw.lat(),
        ne.lat()
    ], minLat = _ref[0], maxLat = _ref[1];
    var _ref1 = [
        sw.lng(),
        ne.lng()
    ], minLng = _ref1[0], maxLng = _ref1[1];
    return [
        minLng,
        minLat,
        maxLng,
        maxLat
    ];
};
// export const addBufferGMBounds = (bounds, buffer) => {
//   const poly = convertGMBoundsToGeoJson(bounds);
//   const polyBuffered = computeBuffered(poly, buffer);
//   const latLngCoordinates = polyBuffered.coordinates[0].map(([lng, lat]) => [
//     lat,
//     lng,
//   ]);
//   return getRegionBounds({
//     include: latLngCoordinates,
//   });
// };
/**
 * Gets the zoom after setting the map bounds.
 * Credit: https://stackoverflow.com/a/13274361
 * @param bounds google.maps.LatLngBounds object
 * @param mapDim - { width, height } object of the DOM element that displays the map
 * @param buffer [m]
 * @param zoomMax max zoom possible
 * @returns The zoom
 */ export var getGMBoundsZoomLevel = function(bounds, mapDim) {
    var buffer = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0, zoomMax = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : MAX_MAP_ZOOM;
    var zoom = function zoom(mapPx, worldPx, fraction) {
        return Math.log(mapPx / worldPx / fraction) / Math.LN2;
    };
    var WORLD_DIM = {
        width: 256,
        height: 256
    };
    var latRad = function(lat) {
        var sin = Math.sin(lat * Math.PI / 180);
        var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
        return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
    };
    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();
    // add buffer using hand-rolled translation geodesic("+a=6378137")
    var EARTH_RADIUS_IN_KILOMETERS = 6378.137;
    var DEGREES_TO_RADIANS = Math.PI / 180.0;
    var RADIANS_TO_DEGREES = 180.0 / Math.PI;
    // todo: custom method
    ne = new google.maps.LatLng({
        lat: ne.lat() + buffer / 1000 / EARTH_RADIUS_IN_KILOMETERS * RADIANS_TO_DEGREES,
        lng: ne.lng() + buffer / 1000 / EARTH_RADIUS_IN_KILOMETERS * RADIANS_TO_DEGREES / Math.cos(ne.lat() * DEGREES_TO_RADIANS)
    });
    sw = new google.maps.LatLng({
        lat: sw.lat() + -buffer / 1000 / EARTH_RADIUS_IN_KILOMETERS * RADIANS_TO_DEGREES,
        lng: sw.lng() + -buffer / 1000 / EARTH_RADIUS_IN_KILOMETERS * RADIANS_TO_DEGREES / Math.cos(sw.lat() * DEGREES_TO_RADIANS)
    });
    var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
    var lngDiff = ne.lng() - sw.lng();
    var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;
    var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
    var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
    return Math.min(latZoom, lngZoom, zoomMax);
};
export var selectionChildren = function(selections, selectionId) {
    return selections.filter(function(selection) {
        return selection.parent_selection_id === selectionId;
    });
};
export var selectionIsNonTripod = function(productType) {
    return NON_TRIPOD_DATA_TYPE.includes(productType);
};
export var getShortenedAddressFromGeocoder = function(placeResult) {
    if (!(placeResult && placeResult.address_components && placeResult.geometry)) {
        return null;
    }
    var address = '', city = '', state = '', postcode = '', country = '';
    placeResult.address_components.forEach(function(addressComponent) {
        if (addressComponent.types.includes('street_number')) {
            if (!address) {
                address = addressComponent.short_name;
            } else {
                address = "".concat(addressComponent.short_name, " ").concat(address);
            }
        } else if (addressComponent.types.includes('route')) {
            if (!address) {
                address = addressComponent.short_name;
            } else {
                address = "".concat(address, " ").concat(addressComponent.short_name);
            }
        } else if (addressComponent.types.includes('locality')) {
            city = addressComponent.short_name;
        } else if (addressComponent.types.includes('administrative_area_level_1')) {
            state = addressComponent.short_name;
        } else if (addressComponent.types.includes('country')) {
            country = addressComponent.short_name;
        }
    });
    return [
        address,
        city,
        state,
        postcode,
        country
    ].filter(function(str) {
        return !!str;
    }).join(', ');
};
