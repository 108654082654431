import get from 'lodash/get';
import { differenceInCalendarDays, differenceInHours } from 'date-fns';
import { MEMBERSHIP, STATUS } from '../constants/membership';
export var calculateRemainderHours = function(membership) {
    return differenceInHours(new Date(membership.trial_end), new Date());
};
export var calculateRemainderDays = function(membership) {
    return differenceInCalendarDays(new Date(membership.trial_end), new Date());
};
export var calculateTrialLengthDays = function(membership) {
    return differenceInCalendarDays(new Date(membership.trial_end), new Date(membership.trial_start));
};
export var isMembershipPriceId = function(membershipPriceId) {
    return MEMBERSHIP.NO_MEMBERSHIP !== membershipPriceId;
};
export var isYearlyMembershipPriceId = function(membershipPriceId) {
    return MEMBERSHIP.YEARLY.PRICE_ID === membershipPriceId;
};
export var isTrialMembership = function(membership) {
    return (membership === null || membership === void 0 ? void 0 : membership.status) && [
        STATUS.TRIALING
    ].includes(membership.status);
};
export var getPriceId = function(memberships) {
    return get(memberships, [
        0,
        'price_id'
    ]);
};
export var getTrialResidualDays = function(membership) {
    return membership && membership.trial_end && differenceInCalendarDays(new Date(membership.trial_end), new Date());
};
