import { useContext, useEffect } from 'react';
import { MapViewContext } from './mapViewContext';
var markers = [];
var clearMarkers = function() {
    return markers.forEach(function(m) {
        return m.setMap(null);
    });
};
var createLarkiMarker = function(place, map) {
    var icon = {
        url: '/public/img/map-marker.svg',
        anchor: new google.maps.Point(17, 34),
        scaledSize: new google.maps.Size(50, 50)
    };
    var marker = new google.maps.Marker({
        position: place === null || place === void 0 ? void 0 : place.geometry.location,
        map: map,
        title: '',
        icon: icon
    });
    return marker;
};
var useLarkiMarker = function() {
    var state = useContext(MapViewContext).state;
    // Place LARKI marker
    useEffect(function() {
        if (state.mapPlace && state.map && state.googleMapsScriptStatus === 'ready') {
            clearMarkers();
            var marker = createLarkiMarker(state.mapPlace, state.map);
            markers.push(marker);
        }
    }, [
        state.googleMapsScriptStatus,
        state.mapPlace,
        state.map
    ]);
};
export default useLarkiMarker;
