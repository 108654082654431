function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { values } from 'lodash';
import _ from 'lodash/fp';
import { ADD_ONS } from '../../../constants/product';
import assert from '../../../utilities/assert';
import { Z_INDEX } from '../constants';
/** Returns path in counter-clockwise order. */ export var getPathFromBounds = function(bounds) {
    var point1Lat = bounds.getNorthEast().lat();
    var point1Lng = bounds.getNorthEast().lng();
    var point2Lat = bounds.getSouthWest().lat();
    var point2Lng = bounds.getSouthWest().lng();
    var locations = [];
    locations.push(new google.maps.LatLng({
        lat: point1Lat,
        lng: point1Lng
    }));
    locations.push(new google.maps.LatLng({
        lat: point1Lat,
        lng: point2Lng
    }));
    locations.push(new google.maps.LatLng({
        lat: point2Lat,
        lng: point2Lng
    }));
    locations.push(new google.maps.LatLng({
        lat: point2Lat,
        lng: point1Lng
    }));
    return locations;
};
var capitaliseString = function(type) {
    return type.charAt(0).toUpperCase() + type.slice(1);
};
export var convertGeometries = function(geometries, toGeoJson) {
    var geometryRegions = {};
    Object.keys(geometries).forEach(function(key) {
        var type = capitaliseString(geometries[key].type);
        var path = [];
        if (type === 'Rectangle') {
            if (toGeoJson) {
                type = 'Polygon';
            }
            path = getPathFromBounds(geometries[key].getBounds());
        } else {
            path = geometries[key].getPath();
        }
        var latLngPath = [];
        path.forEach(function(point) {
            latLngPath.push([
                point.lat(),
                point.lng()
            ]);
        });
        if (type !== 'Boolean') {
            geometryRegions[geometries[key].id] = {
                region: {
                    type: type,
                    include: latLngPath,
                    exclude: []
                }
            };
        } else {
            var _geometryRegions_geometries_key_parentId;
            if ((_geometryRegions_geometries_key_parentId = geometryRegions[geometries[key].parentId]) === null || _geometryRegions_geometries_key_parentId === void 0 ? void 0 : _geometryRegions_geometries_key_parentId.region) {
                geometryRegions[geometries[key].parentId] = {
                    region: _object_spread_props(_object_spread({}, geometryRegions[geometries[key].parentId].region), {
                        exclude: _to_consumable_array(geometryRegions[geometries[key].parentId].region.exclude || []).concat([
                            latLngPath
                        ])
                    })
                };
            } else {
                geometryRegions[geometries[key].parentId] = {
                    region: {
                        exclude: [
                            latLngPath
                        ]
                    }
                };
            }
        }
    });
    return geometryRegions;
};
export var createMarker = function(location, map) {
    var markerIcon = {
        url: '/public/img/marker-point.svg',
        size: new google.maps.Size(15, 15),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(7, 7)
    };
    var markerShape = {
        coords: [
            0,
            0,
            0,
            15,
            15,
            15,
            15,
            0
        ],
        type: 'poly'
    };
    var marker = new google.maps.Marker({
        position: location,
        map: map,
        draggable: true,
        icon: markerIcon,
        shape: markerShape,
        zIndex: Z_INDEX.MARKER
    });
    return marker;
};
export var createMidpointMarker = function(location, map) {
    var markerIcon = {
        url: '/public/img/marker-midpoint.svg',
        size: new google.maps.Size(7, 7),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(3, 3)
    };
    var markerShape = {
        coords: [
            0,
            0,
            0,
            10,
            10,
            10,
            10,
            0
        ],
        type: 'poly'
    };
    var marker = new google.maps.Marker({
        position: location,
        map: map,
        draggable: true,
        icon: markerIcon,
        shape: markerShape,
        zIndex: 2
    });
    return marker;
};
export var isPathClockwise = function(path) {
    var area = 0;
    for(var i = 0; i < path.length; i++){
        var j = (i + 1) % path.length;
        area += path[i].lat() * path[j].lng();
        area -= path[j].lat() * path[i].lng();
    }
    return area > 0;
};
export var getDistanceInPixels = function(position1, position2, map, zoom) {
    if (map && map.getProjection()) {
        var point1 = map.getProjection().fromLatLngToPoint(position1);
        var point2 = map.getProjection().fromLatLngToPoint(position2);
        var pixelSize = Math.pow(2, -zoom);
        var distance = Math.sqrt((point1.x - point2.x) * (point1.x - point2.x) + (point1.y - point2.y) * (point1.y - point2.y)) / pixelSize;
        return distance;
    }
    console.warn('map or map projection missing');
    return 0;
};
export var getSegmentLengths = function(geometry, map, zoom) {
    var lengths = [];
    var isClockwise = false;
    var totalLength = 0;
    if (geometry) {
        var geometryPath = [];
        if (geometry.type === 'rectangle') {
            geometryPath = getPathFromBounds(geometry.getBounds());
        } else {
            geometry.getPath().forEach(function(point) {
                geometryPath.push(point);
            });
        }
        isClockwise = isPathClockwise(geometryPath);
        if (geometry.type === 'polygon' || geometry.type === 'boolean' || geometry.type === 'rectangle') {
            geometryPath.push(geometryPath[0]);
        }
        for(var i = 0; i < geometryPath.length - 1; i++){
            // Google spherical uses geodesic("+a=6378137") by default
            var length = google.maps.geometry.spherical.computeDistanceBetween(geometryPath[i], geometryPath[i + 1]);
            var heading = google.maps.geometry.spherical.computeHeading(geometryPath[i], geometryPath[i + 1]);
            var midpoint = google.maps.geometry.spherical.computeOffset(geometryPath[i], length / 2, heading);
            var endpoint = google.maps.geometry.spherical.computeOffset(geometryPath[i], length, heading);
            var distanceInPixels = getDistanceInPixels(geometryPath[i], geometryPath[i + 1], map, zoom);
            lengths.push({
                length: length,
                heading: heading,
                midpoint: midpoint,
                endpoint: endpoint,
                distanceInPixels: distanceInPixels
            });
            totalLength += length;
        }
    }
    return {
        lengths: lengths,
        isClockwise: isClockwise,
        totalLength: totalLength
    };
};
export var calculateMidpoints = function(geometry) {
    var midpoints = [];
    if (geometry) {
        var geometryPath = [];
        geometry.getPath().forEach(function(point) {
            geometryPath.push(point);
        });
        for(var i = 0; i < geometryPath.length - 1; i++){
            var length = google.maps.geometry.spherical.computeDistanceBetween(geometryPath[i], geometryPath[i + 1]);
            var heading = google.maps.geometry.spherical.computeHeading(geometryPath[i], geometryPath[i + 1]);
            var midpoint = google.maps.geometry.spherical.computeOffset(geometryPath[i], length / 2, heading);
            midpoints.push(midpoint);
        }
        if (geometry.type === 'polygon' || geometry.type === 'boolean') {
            var length1 = google.maps.geometry.spherical.computeDistanceBetween(geometryPath[geometryPath.length - 1], geometryPath[0]);
            var heading1 = google.maps.geometry.spherical.computeHeading(geometryPath[geometryPath.length - 1], geometryPath[0]);
            var midpoint1 = google.maps.geometry.spherical.computeOffset(geometryPath[geometryPath.length - 1], length1 / 2, heading1);
            midpoints.push(midpoint1);
        }
    }
    return midpoints;
};
export var getScreenPositionFromLatLng = function(latLng, map, zoom) {
    if (map && map.getProjection()) {
        var scale = Math.pow(2, zoom); // = numTiles
        var projection = map.getProjection();
        var positionWorldCoordinate = projection.fromLatLngToPoint(latLng);
        var pixelCoordinate = new google.maps.Point(positionWorldCoordinate.x * scale, positionWorldCoordinate.y * scale);
        var topLeft = new google.maps.LatLng(map.getBounds().getNorthEast().lat(), map.getBounds().getSouthWest().lng());
        var topLeftWorldCoordinate = projection.fromLatLngToPoint(topLeft);
        var topLeftPixelCoordinate = new google.maps.Point(topLeftWorldCoordinate.x * scale, topLeftWorldCoordinate.y * scale);
        return {
            x: Math.floor(pixelCoordinate.x - topLeftPixelCoordinate.x),
            y: Math.floor(pixelCoordinate.y - topLeftPixelCoordinate.y)
        };
    }
    return {
        x: 0,
        y: 0
    };
};
export var createRectangle = function(bounds, id, map, clickable) {
    return new google.maps.Rectangle({
        bounds: bounds,
        map: map,
        clickable: clickable,
        draggable: false,
        editable: false,
        geodesic: true,
        visible: true,
        fillColor: '#000000',
        fillOpacity: 0,
        strokeColor: '#000000',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        zIndex: Z_INDEX.REGION,
        type: 'rectangle',
        id: id
    });
};
export var createPolygon = function(path, id, map, isBoolean, clickable) {
    return new google.maps.Polygon({
        path: path,
        map: map,
        clickable: clickable,
        draggable: false,
        editable: false,
        geodesic: true,
        visible: true,
        fillColor: '#000000',
        fillOpacity: 0,
        strokeColor: '#000000',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        zIndex: Z_INDEX.REGION,
        type: isBoolean ? 'boolean' : 'polygon',
        id: id
    });
};
export var createPolygonWithHoles = function(paths, id, map, isBoolean, clickable) {
    return new google.maps.Polygon({
        paths: paths,
        map: map,
        clickable: clickable,
        draggable: false,
        editable: false,
        geodesic: true,
        visible: true,
        fillColor: '#000000',
        fillOpacity: 0,
        strokeColor: '#000000',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        zIndex: Z_INDEX.REGION,
        type: isBoolean ? 'boolean' : 'polygon',
        id: id
    });
};
export var createPolyline = function(path, id, map) {
    return new google.maps.Polyline({
        path: path,
        map: map,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: true,
        visible: true,
        icons: null,
        strokeColor: '#000000',
        strokeOpacity: 1.0,
        strokeWeight: 3,
        zIndex: Z_INDEX.REGION,
        type: 'polyline',
        id: id
    });
};
export var transformGeometryToState = function(geometries) {
    var newState = {};
    Object.keys(geometries).forEach(function(geometryId) {
        var array = [];
        newState[geometryId] = {
            type: geometries[geometryId].type,
            locations: function() {
                switch(geometries[geometryId].type){
                    case 'rectangle':
                        return getPathFromBounds(geometries[geometryId].getBounds());
                    default:
                        geometries[geometryId].getPath().forEach(function(point) {
                            return array.push(point);
                        });
                        return array;
                }
            }(),
            parentId: geometries[geometryId].parentId || null
        };
    });
    return newState;
};
export var isPopupMarkerType = function(popup, type) {
    return (popup === null || popup === void 0 ? void 0 : popup.markerType) && type && popup.markerType === type;
};
export var isPopupStandard = function(popup) {
    return isPopupMarkerType(popup, 'standard');
};
export var isPopupMidpoint = function(popup) {
    return isPopupMarkerType(popup, 'midpoint');
};
export var getAddOnConfig = function(product) {
    return values(ADD_ONS).find(function(param) {
        var PRODUCT_NAME = param.PRODUCT_NAME;
        return PRODUCT_NAME === product;
    });
};
export var getIncludePath = function(region) {
    var _region_include_map;
    return (_region_include_map = region === null || region === void 0 ? void 0 : region.include.map(function(point) {
        return new google.maps.LatLng({
            lat: point[0],
            lng: point[1]
        });
    })) !== null && _region_include_map !== void 0 ? _region_include_map : [];
};
export var getExclusionPaths = function(region) {
    var _region_exclude_map;
    return (_region_exclude_map = region === null || region === void 0 ? void 0 : region.exclude.map(function(path) {
        return path.map(function(point) {
            return new google.maps.LatLng({
                lat: point[0],
                lng: point[1]
            });
        });
    })) !== null && _region_exclude_map !== void 0 ? _region_exclude_map : [];
};
export var getBoundsFromPath = function(path) {
    var bounds = new google.maps.LatLngBounds();
    path.forEach(function(location) {
        bounds.extend(location);
    });
    return bounds;
};
export var isMarkerValid = function(marker) {
    return marker.id && !_.isNaN(marker.index);
};
export var isPathValid = function(path) {
    return (path === null || path === void 0 ? void 0 : path.length) > 0;
};
export var isPathsValid = function(paths) {
    return paths.every(isPathValid);
};
export var isRegionValid = function(region) {
    return region && [
        'Rectangle',
        'Polygon',
        'Boolean',
        'Polyline'
    ].includes(region.type) && region.include.length > 0;
};
export var isGeometryValidPolygon = function(geometry) {
    return typeof (geometry === null || geometry === void 0 ? void 0 : geometry.setPath) === 'function';
};
export var assertRegionIsValid = function(region) {
    return assert(isRegionValid(region), 'Region not valid', region);
};
export var assertMarkerIsValid = function(marker) {
    return assert(isMarkerValid(marker), 'Marker not valid', marker);
};
export var assertPathIsValid = function(path) {
    return assert(isPathValid(path), 'Path not valid', path);
};
// assumes path has length > 0
export var assertPathIsClockwise = function(path) {
    return assert(isPathClockwise(path), 'Path is not clockwise', path);
};
// assumes path has length > 0
export var assertPathIsCounterClockwise = function(path) {
    return assert(!isPathClockwise(path), 'Path is not counter clockwise', path);
};
export var assertGeometryIsValidPolygon = function(geometry) {
    return assert(isGeometryValidPolygon(geometry), 'Geometry is not valid polygon', geometry);
};
