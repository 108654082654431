import { useContext, useMemo } from 'react';
import { MapViewContext } from '../mapViewContext';
import { sumBy } from 'lodash';
import { ADD_ONS_DATA_TYPE, CAPTURE_SCAN_SERVICES_DATA_TYPE } from '../../../constants/product';
import { isUserGovernment, isUserUnderTrial, userHasValidMembership } from '../../../utilities/user';
import { useSelector } from 'react-redux';
import { profileSelectors } from '../../../redux/selectors/profile';
import { GST } from '../../../constants/price';
var useData = function() {
    var _state_job, _orderTotals_price;
    var currentUser = useSelector(profileSelectors.getUserProfile);
    var state = useContext(MapViewContext).state;
    var quote = (_state_job = state.job) === null || _state_job === void 0 ? void 0 : _state_job.quote;
    var orderTotals;
    if (quote === null || quote === void 0 ? void 0 : quote.cart_totals) {
        // for new orders Sprint 67
        orderTotals = quote.cart_totals;
    } else if (quote === null || quote === void 0 ? void 0 : quote.order) {
        // released Sprint 66
        orderTotals = quote.order;
    } else {
        // old implementation
        // used quoted and completed orders
        orderTotals = quote;
    }
    // Trialing users are not included as essential
    var isEssentialUser = useMemo(function() {
        if (isUserGovernment(currentUser)) return true;
        return userHasValidMembership(currentUser) && !isUserUnderTrial(currentUser);
    }, [
        currentUser
    ]);
    var isUserOnTrial = useMemo(function() {
        return isUserUnderTrial(currentUser);
    }, [
        currentUser
    ]);
    var gstPercentage = useMemo(function() {
        return 1 + (quote.gst || GST) / 100;
    }, [
        quote.gst
    ]);
    // for FREE & TRIAL user for Advertisement or Promotion of Membership
    var discounts = useMemo(function() {
        var captureServicesDiscount = sumBy(quote.charges, function(charge) {
            var isScanCaptureService = CAPTURE_SCAN_SERVICES_DATA_TYPE.includes(charge.details.category_name);
            if (isScanCaptureService) {
                return sumBy(isEssentialUser ? charge.discounts : charge.member_discounts_preview, function(preview) {
                    return preview.price.discount;
                });
            }
            return 0;
        });
        var addOnsDiscount = sumBy(quote.charges, function(charge) {
            var isScanCaptureService = ADD_ONS_DATA_TYPE.includes(charge.details.category_name);
            if (isScanCaptureService) {
                return sumBy(isEssentialUser ? charge.discounts : charge.member_discounts_preview, function(preview) {
                    return preview.price.discount;
                });
            }
            return 0;
        });
        var selfServiceDiscount = sumBy(quote.charges, function(charge) {
            return sumBy(charge.discounts, function(discount) {
                return discount.price.discount;
            });
        });
        return {
            members: {
                captureServicesDiscount: captureServicesDiscount,
                addOnsDiscount: addOnsDiscount
            },
            selfServiceDiscount: selfServiceDiscount,
            selfServiceDiscountIncGst: selfServiceDiscount * gstPercentage
        };
    }, [
        gstPercentage,
        isEssentialUser,
        quote.charges
    ]);
    var fullPriceWithSalesHelpIncGst = useMemo(function() {
        return (orderTotals.price.base + orderTotals.price.fees + discounts.selfServiceDiscount) * gstPercentage;
    }, [
        orderTotals.price.base,
        orderTotals.price.fees,
        discounts.selfServiceDiscount,
        gstPercentage
    ]);
    var memberTax = useMemo(function() {
        return sumBy(orderTotals === null || orderTotals === void 0 ? void 0 : orderTotals.member_taxes, function(tax) {
            return tax.price.extra;
        });
    }, [
        orderTotals === null || orderTotals === void 0 ? void 0 : orderTotals.member_taxes
    ]);
    var nonMemberTax = useMemo(function() {
        return sumBy(orderTotals === null || orderTotals === void 0 ? void 0 : orderTotals.taxes, function(tax) {
            return tax.price.extra;
        });
    }, [
        orderTotals === null || orderTotals === void 0 ? void 0 : orderTotals.taxes
    ]);
    var memberPrice = useMemo(function() {
        var _orderTotals_price;
        return (orderTotals === null || orderTotals === void 0 ? void 0 : (_orderTotals_price = orderTotals.price) === null || _orderTotals_price === void 0 ? void 0 : _orderTotals_price.member_subtotal) + memberTax;
    }, [
        memberTax,
        orderTotals === null || orderTotals === void 0 ? void 0 : (_orderTotals_price = orderTotals.price) === null || _orderTotals_price === void 0 ? void 0 : _orderTotals_price.member_subtotal
    ]);
    /** AFAIK: orderTotals.discounts only includes New Customer Discount(165) and
   *  Membership Trial Discount(69.50) passed from Backend, associated w/ this comment
   *  in template check section --OTHER DISCOUNTS-- */ var otherDiscountsTotal = useMemo(function() {
        var subtotal = sumBy(orderTotals === null || orderTotals === void 0 ? void 0 : orderTotals.discounts, function(discount) {
            return discount.price.subtotal;
        });
        return {
            subtotal: subtotal,
            gst: sumBy(orderTotals === null || orderTotals === void 0 ? void 0 : orderTotals.discounts, function(discount) {
                return discount.price.gst;
            }),
            subtotalIncGst: subtotal * gstPercentage
        };
    }, [
        gstPercentage,
        orderTotals === null || orderTotals === void 0 ? void 0 : orderTotals.discounts
    ]);
    return {
        quote: quote,
        currentUser: currentUser,
        isEssentialUser: isEssentialUser,
        isUserOnTrial: isUserOnTrial,
        orderTotals: orderTotals,
        discounts: discounts,
        memberTax: memberTax,
        nonMemberTax: nonMemberTax,
        memberPrice: memberPrice,
        otherDiscountsTotal: otherDiscountsTotal,
        fullPriceWithSalesHelpIncGst: fullPriceWithSalesHelpIncGst,
        currency: {
            name: quote.currency.name,
            scale_factor: quote.currency.scale_factor
        },
        gstPercentage: gstPercentage
    };
};
export default useData;
