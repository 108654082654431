function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import _ from 'lodash/fp';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { MapViewContext } from '../components/mapView/mapViewContext';
import { alertError } from '../redux/actions';
import { profileSelectors } from '../redux/selectors/profile';
import projectSelectors from '../redux/selectors/project';
import { client } from '../utilities/api';
import { captureError } from '../utilities/error';
import { isDemoProject } from '../utilities/project';
import shareLinkUtilities from '../utilities/shareLink';
import { isUserAnonymous } from '../utilities/user';
var useShareLinkParams = function() {
    var dispatch = useDispatch();
    var location = useLocation();
    var currentProject = useSelector(projectSelectors.getCurrentProject);
    var user = useSelector(profileSelectors.getUserProfile);
    var isAnon = isUserAnonymous(user);
    var _useMemo = _sliced_to_array(useMemo(function() {
        return [
            shareLinkUtilities.isShareLinkVisible(location.pathname),
            shareLinkUtilities.extractRouteParam(location.pathname),
            shareLinkUtilities.doesPathHaveOrderId(location.pathname),
            shareLinkUtilities.shouldShowHomeIcon(location.pathname)
        ];
    }, [
        location.pathname
    ]), 4), isVisible = _useMemo[0], id = _useMemo[1], isOrderId = _useMemo[2], showHomeIcon = _useMemo[3];
    var _useState = _sliced_to_array(useState(), 2), projectIdFallback = _useState[0], setProjectIdFallback = _useState[1];
    var projectId = !isOrderId ? id : projectIdFallback;
    var redirectUrl = window.location.href;
    var isDemo = currentProject ? isDemoProject(currentProject) : false;
    var isAvailable = isVisible && !_.isNil(projectId) && redirectUrl && !!user && !isAnon && !isDemo;
    var _useAsyncFn = _sliced_to_array(useAsyncFn(function() {
        var _ref = _async_to_generator(function(id) {
            var resp;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        return [
                            4,
                            client.getJobById(id)
                        ];
                    case 1:
                        resp = _state.sent();
                        return [
                            2,
                            resp.data
                        ];
                }
            });
        });
        return function(id) {
            return _ref.apply(this, arguments);
        };
    }()), 2), getJobState = _useAsyncFn[0], getJob = _useAsyncFn[1];
    useEffect(function() {
        if (id && isOrderId) {
            _async_to_generator(function() {
                var job;
                return _ts_generator(this, function(_state) {
                    switch(_state.label){
                        case 0:
                            return [
                                4,
                                getJob(id)
                            ];
                        case 1:
                            job = _state.sent();
                            setProjectIdFallback(job.project_id);
                            return [
                                2
                            ];
                    }
                });
            })();
        }
    }, [
        getJob,
        id,
        isOrderId
    ]);
    useEffect(function() {
        if (getJobState.error) {
            captureError(getJobState.error);
            dispatch(alertError(getJobState.error.message));
        }
    }, [
        dispatch,
        getJobState.error
    ]);
    return {
        isAvailable: isAvailable,
        projectId: projectId,
        redirectUrl: redirectUrl,
        showHomeIcon: showHomeIcon
    };
};
export var parse2dMapMetadata = function(_param) {
    var c = _param.center, z = _param.zoom, metadata = _object_without_properties(_param, [
        "center",
        "zoom"
    ]);
    return _object_spread({
        center: c ? {
            lat: parseFloat(c.lat),
            lng: parseFloat(c.lng)
        } : null,
        zoom: z ? parseFloat(z) : null
    }, metadata);
};
export var useShareLink2dMapMetadata = function() {
    var state = useContext(MapViewContext).state;
    var mapState = useSelector(function(reduxState) {
        return reduxState.mapReducer;
    });
    var metadata = useMemo(function() {
        var center = state.debouncedViewportCenter;
        return {
            center: center ? {
                lat: center.lat(),
                lng: center.lng()
            } : null,
            zoom: state.zoom.zoom,
            currentMapTypeId: state.currentMapTypeId,
            highResDate: mapState.highRes.currentDate,
            highResLayerName: state.currentHighResLayerName
        };
    }, [
        state.debouncedViewportCenter,
        state.zoom.zoom,
        state.currentMapTypeId,
        mapState.highRes.currentDate,
        state.currentHighResLayerName
    ]);
    return metadata;
};
export var useShareLinkMetadata = function() {
    var location = useLocation();
    var metadata2dMap = useShareLink2dMapMetadata();
    return shareLinkUtilities.isRoute2dMap(location.pathname) ? metadata2dMap : undefined;
};
export var useNew3dBookmark = function() {
    var location = useLocation();
    return shareLinkUtilities.isRoute3dViewer(location.pathname) ? function() {
        var root = document.getElementById('cloud-viewer');
        var headers = _to_consumable_array(root.getElementsByClassName('p__header__title'));
        var header = headers.find(function(header) {
            return header.innerHTML.includes('Bookmarks');
        });
        if (header) {
            var _header_parentElement, _parent_children__getElementsByClassName__getElementsByTagName_, _parent_children__getElementsByClassName_, _parent_children_;
            var parent = (_header_parentElement = header.parentElement) === null || _header_parentElement === void 0 ? void 0 : _header_parentElement.parentElement;
            var isCollapsed = parent.classList.contains('p__card--is-collapsed');
            if (isCollapsed) header.click();
            parent === null || parent === void 0 ? void 0 : (_parent_children_ = parent.children[1]) === null || _parent_children_ === void 0 ? void 0 : (_parent_children__getElementsByClassName_ = _parent_children_.getElementsByClassName('add_button')[0]) === null || _parent_children__getElementsByClassName_ === void 0 ? void 0 : (_parent_children__getElementsByClassName__getElementsByTagName_ = _parent_children__getElementsByClassName_.getElementsByTagName('button')[0]) === null || _parent_children__getElementsByClassName__getElementsByTagName_ === void 0 ? void 0 : _parent_children__getElementsByClassName__getElementsByTagName_.click();
        }
    } : undefined;
};
export default useShareLinkParams;
