export default {
    user: {
        profile: '/user-profile',
        membershipManagement: '/user/membership/management',
        membershipChangeSuccess: '/user/membership/change/success'
    },
    login: '/login',
    logout: '/logout',
    signUp: '/signup',
    home: '/',
    dashboard: '/dashboard',
    order: {
        root: '/order',
        job: function(jobId) {
            return jobId ? "/order/".concat(jobId) : '/order/:jobId';
        }
    },
    payment: {
        status: function(jobId) {
            return jobId ? "/job/".concat(jobId, "/payment/status") : '/job/:jobId/payment/status';
        },
        job: function(jobId) {
            return jobId ? "/job/".concat(jobId, "/payment") : '/job/:jobId/payment';
        },
        assignedStatus: function() {
            return '/assigned-payment/status';
        }
    },
    view3D: {
        root: '/viewer',
        project: function(projectId) {
            return "/viewer/".concat(projectId !== null && projectId !== void 0 ? projectId : ':projectId');
        }
    },
    view3DExternal: {
        root: "".concat(process.env.LARKI_VIEWER_URL, "/viewer"),
        project: function(projectId) {
            return "".concat(process.env.LARKI_VIEWER_URL, "/viewer/").concat(projectId !== null && projectId !== void 0 ? projectId : ':projectId');
        }
    },
    password: {
        create: '/create-password',
        reset: '/reset-password',
        forgot: '/forgot-password'
    },
    larkiWebsiteUrl: {
        home: process.env.LARKI_MARKETING_URL,
        products: "".concat(process.env.LARKI_MARKETING_URL, "/products"),
        pricing: "".concat(process.env.LARKI_MARKETING_URL, "/pricing"),
        aboutUs: "".concat(process.env.LARKI_MARKETING_URL, "/about"),
        contactUs: "".concat(process.env.LARKI_MARKETING_URL, "/contact-us"),
        privacy: "".concat(process.env.LARKI_MARKETING_URL, "/privacy-policy"),
        supplierTermsCond: "".concat(process.env.LARKI_MARKETING_URL, "/supplier-terms-conditions"),
        aerialProduct: "".concat(process.env.LARKI_MARKETING_URL, "/product/3d-aerial-point-cloud"),
        streetscapeProduct: "".concat(process.env.LARKI_MARKETING_URL, "/product/3d-streetscape-point-cloud"),
        droneProduct: "".concat(process.env.LARKI_MARKETING_URL, "/product/3d-drone-point-cloud-exterior-current-high-res-drone"),
        exteriorProduct: "".concat(process.env.LARKI_MARKETING_URL, "/product/3d-tripod-point-cloud-exterior-current-high-res"),
        interiorProduct: "".concat(process.env.LARKI_MARKETING_URL, "/product/3d-tripod-point-cloud-interior-current-high-res"),
        bimTerrainProduct: "".concat(process.env.LARKI_MARKETING_URL, "/product/bim-terrain"),
        featurePlans2D: "".concat(process.env.LARKI_MARKETING_URL, "/product/2d-feature-plan-exterior"),
        blog: "".concat(process.env.LARKI_MARKETING_URL, "/blog"),
        memberships: "".concat(process.env.LARKI_MARKETING_URL, "/memberships"),
        survey: process.env.LARKI_SURVEY_URL,
        custTermsCond: process.env.LARKI_URL_TERMS
    },
    larkiSocialMedia: {
        instagram: process.env.LARKI_INSTAGRAM_URL,
        youtube: process.env.LARKI_YOUTUBE_URL,
        twitter: process.env.LARKI_TWITTER_URL,
        facebook: process.env.LARKI_FACEBOOK_URL,
        linkedin: process.env.LARKI_LINKEDIN_URL
    },
    accessDenied: '/access-denied',
    quote: {
        accept: function(quoteId) {
            return quoteId ? "/accept-quote/".concat(quoteId) : '/accept-quote/:quoteId';
        }
    },
    fileManager: {
        root: '/file-manager',
        viewProject: function(projectId) {
            return projectId ? "/file-manager/".concat(projectId) : '/file-manager/:projectId';
        }
    },
    project: {
        acceptInvite: '/project/accept-invite/:projectId'
    },
    product: {
        search: '/product/search'
    },
    openBookmark: {
        path: '/open-bookmark/:shareToken',
        withShareToken: function(shareToken) {
            return "/open-bookmark/".concat(shareToken);
        }
    },
    requestAccess: {
        path: '/request-access/:shareToken',
        withShareToken: function(shareToken) {
            return "/request-access/".concat(shareToken);
        }
    },
    projectUser: {
        approve: {
            path: '/project/:projectId/user/:userId/approve',
            url: function(projectId, userId) {
                return "/project/".concat(projectId, "/user/").concat(userId, "/approve");
            }
        },
        deny: {
            path: '/project/:projectId/user/:userId/deny',
            url: function(projectId, userId) {
                return "/project/".concat(projectId, "/user/").concat(userId, "/deny");
            }
        }
    },
    statusPage: {
        path: "/status-page/:status",
        url: function(status) {
            return "/status-page/".concat(status);
        }
    }
};
