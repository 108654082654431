export var DOWNLOAD_ALL_STATUS = {
    IDLE: 'idle',
    ZIPPING: 'zipping',
    DOWNLOADING: 'downloading'
};
export var DOWNLOAD_ALL_URL = 'DOWNLOAD_ALL_URL';
export var DOWNLOAD_ALL_URL_SUCCESS = 'DOWNLOAD_ALL_URL_SUCCESS';
export var DOWNLOAD_ALL_URL_FAILURE = 'DOWNLOAD_ALL_URL_FAILURE';
export var DOWNLOAD_ALL_UPDATE_STATUS = 'DOWNLOAD_ALL_UPDATE_STATUS';
export var DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID = 'DOWNLOAD_ALL_UPDATE_QUEUE_JOB_ID';
export var REGISTER_PROJECT_TRIAL_MEMBERS = 'REGISTER_PROJECT_TRIAL_MEMBERS';
export var REGISTER_PROJECT_TRIAL_MEMBERS_SUCCESS = 'REGISTER_PROJECT_TRIAL_MEMBERS_SUCCESS';
export var LIST_PROJECTS = 'LIST_PROJECTS';
export var LIST_PROJECTS_SUCCESS = 'LIST_PROJECTS_SUCCESS';
export var LIST_PROJECTS_FAILURE = 'LIST_PROJECTS_FAILURE';
export var LIST_PROJECT_USERS = 'LIST_PROJECT_USERS';
export var LIST_PROJECT_USERS_SUCCESS = 'LIST_PROJECT_USERS_SUCCESS';
export var UPDATE_USER_PROJECT_TRIAL = 'UPDATE_USER_PROJECT_TRIAL';
export var UPDATE_USER_PROJECT_TRIAL_SUCCESS = 'UPDATE_USER_PROJECT_TRIAL_SUCCESS';
export var SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT';
export var GET_CURRENT_PROJECT = 'GET_CURRENT_PROJECT';
export var GET_CURRENT_PROJECT_SUCCESS = 'GET_CURRENT_PROJECT_SUCCESS';
export var GET_CURRENT_PROJECT_FAILURE = 'GET_CURRENT_PROJECT_FAILURE';
