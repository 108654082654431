// Common states
export var DEFAULT = 'DEFAULT';
export var LOADING = 'LOADING';
export var SUCCESS = 'SUCCESS';
export var FAILED = 'FAILED';
export var isDefault = function(state) {
    return state && state === DEFAULT;
};
export var isLoading = function(state) {
    return state && state === LOADING;
};
export var isSuccess = function(state) {
    return state && state === SUCCESS;
};
export var isFailed = function(state) {
    return state && state === FAILED;
};
