import { captureError } from './error';
var assert = function(condition, message) {
    for(var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++){
        args[_key - 2] = arguments[_key];
    }
    if (!condition) {
        console.error(args);
        var err = new Error(message || 'Assertion failed');
        if (process.env.NODE_ENV === 'development') {
            throw err;
        } else {
            captureError(err);
        }
    }
};
export default assert;
