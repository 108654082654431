function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
var _require = require('@formatjs/intl'), createIntl = _require.createIntl, createIntlCache = _require.createIntlCache;
var messages = require('./locale_en');
var cache = createIntlCache();
var intl = createIntl({
    locale: 'en',
    defaultLocale: 'en',
    messages: messages
}, cache);
var text = function(id, values) {
    return intl.formatMessage({
        id: id,
        defaultMessage: id
    }, values);
};
// Number should be
export var formatCurrency = function(amount, currency, scale_factor, options) {
    var spaced = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : false;
    if (!isNaN(amount) && currency && scale_factor) {
        var result = intl.formatNumber(amount / scale_factor, _object_spread({
            style: 'currency',
            currency: currency
        }, options));
        return !spaced ? result : result.replace(/^(\D+)/, '$1 ').replace(/\s+/, ' ');
    } else {
        return '';
    }
};
export var formatArea = function(number) {
    return intl.formatNumber(number, {
        style: 'unit',
        unit: 'meter'
    });
};
export var formatSqmArea = function(area) {
    return "".concat(formatArea(area), "\xb2");
};
export default text;
