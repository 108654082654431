import filter from 'lodash/fp/filter';
import reduce from 'lodash/fp/reduce';
export var filterByMultipleConditions = function(conditions) {
    return function(items) {
        return filter(function(item) {
            return reduce(function(result, condition) {
                return result || condition(item);
            }, false)(conditions);
        })(items);
    };
};
