import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import isNull from 'lodash/fp/isNull';
import orderBy from 'lodash/fp/orderBy';
import text from '../text';
import { assign } from 'lodash/fp';
export var isDemoProject = function(project) {
    var _getProjectLatestJob;
    return ((_getProjectLatestJob = getProjectLatestJob(project)) === null || _getProjectLatestJob === void 0 ? void 0 : _getProjectLatestJob.status) === 'demo';
};
export var getProjectName = function(project) {
    return !(project === null || project === void 0 ? void 0 : project.name) || isNull(project === null || project === void 0 ? void 0 : project.name) ? text('untitled') : get('name', project);
};
export var getProjectLatestJob = function(project) {
    return project && !isEmpty(project.jobs) && flow([
        orderBy('updated_at', [
            'desc'
        ]),
        get('0'),
        assign({
            point_clouds: project.point_clouds
        })
    ])(project.jobs);
};
export var isProject3DViewable = function(project) {
    return get('user_project.invite_status', project) === 'accepted' && !isEmpty(project === null || project === void 0 ? void 0 : project.point_clouds);
};
export var isProjectInviteAccepted = function(project) {
    return get('user_project.invite_status', project) === 'accepted';
};
export var isInvitationProjectTrialMember = function(invitation) {
    return invitation.project_role && invitation.project_role === 'project-trial';
};
