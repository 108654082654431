function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { orderBy } from 'lodash';
import flowFP from 'lodash/fp/flow';
import setFP from 'lodash/fp/set';
import { profileConstants } from '../constants';
import { DEFAULT, LOADING, SUCCESS } from '../stateTools';
var defaultProfileState = {
    userProfile: {},
    isUserLoggedIn: false,
    userProfileState: 'idle',
    updateUserProfileState: DEFAULT,
    userCards: {
        isLoading: false,
        value: undefined
    },
    companyProfile: {},
    updateCompanyProfileState: DEFAULT,
    membershipPrices: {
        isLoading: false,
        value: undefined
    },
    membershipReceipt: {
        isLoading: false,
        value: undefined
    },
    membershipDetails: {
        isLoading: false,
        value: undefined
    },
    upsertMembership: {
        isLoading: false
    },
    pretendType: null
};
var profileReducer = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : defaultProfileState, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case profileConstants.FETCH_USER_DETAILS_LOADING:
            return _object_spread_props(_object_spread({}, state), {
                userProfileState: 'loading'
            });
        case profileConstants.FETCH_USER_DETAILS_SUCCESS:
            return _object_spread_props(_object_spread({}, state), {
                userProfileState: 'success',
                userProfile: action.userProfile,
                isUserLoggedIn: true
            });
        case profileConstants.FETCH_USER_DETAILS_FAILURE:
            return _object_spread_props(_object_spread({}, state), {
                userProfileState: 'error'
            });
        case profileConstants.UPDATE_USER_DETAILS_LOADING:
            return _object_spread_props(_object_spread({}, state), {
                updateUserProfileState: LOADING
            });
        case profileConstants.UPDATE_USER_DETAILS_SUCCESS:
            return _object_spread_props(_object_spread({}, state), {
                userProfile: action.userProfile,
                updateUserProfileState: SUCCESS
            });
        case profileConstants.RESET_USER_FORM:
            return _object_spread_props(_object_spread({}, state), {
                updateUserProfileState: DEFAULT
            });
        case profileConstants.UPDATE_USER_DETAILS_ERROR:
            return _object_spread_props(_object_spread({}, state), {
                userProfileState: 'error'
            });
        case profileConstants.SET_COMPANY_DETAILS:
            return _object_spread_props(_object_spread({}, state), {
                companyProfile: action.companyProfile
            });
        case profileConstants.UPDATE_COMPANY_DETAILS_LOADING:
            return _object_spread_props(_object_spread({}, state), {
                updateCompanyProfileState: LOADING
            });
        case profileConstants.UPDATE_COMPANY_DETAILS_SUCCESS:
            return _object_spread_props(_object_spread({}, state), {
                companyProfile: action.companyProfile,
                updateCompanyProfileState: SUCCESS
            });
        case profileConstants.UPDATE_COMPANY_DETAILS_ERROR:
            return _object_spread_props(_object_spread({}, state), {
                companyProfileState: 'error'
            });
        case profileConstants.RESET_COMPANY_FORM:
            return _object_spread_props(_object_spread({}, state), {
                updateCompanyProfileState: DEFAULT
            });
        case profileConstants.UPDATE_MAP_TYPE:
            return _object_spread_props(_object_spread({}, state), {
                userProfile: _object_spread_props(_object_spread({}, state.userProfile), {
                    map_type: action.payload
                })
            });
        case profileConstants.LIST_USER_CARDS:
            {
                return flowFP([
                    setFP([
                        'userCards',
                        'isLoading'
                    ], true)
                ])(state);
            }
        case profileConstants.LIST_USER_CARDS_SUCCESS:
            {
                return flowFP([
                    setFP([
                        'userCards',
                        'isLoading'
                    ], false),
                    setFP([
                        'userCards',
                        'value'
                    ], orderBy(action.payload, [
                        'default_payment_method',
                        'name'
                    ], [
                        'desc',
                        'asc'
                    ]))
                ])(state);
            }
        case profileConstants.GET_MEMBERSHIP_PRICES_LOADING:
            {
                return flowFP([
                    setFP([
                        'membershipPrices',
                        'isLoading'
                    ], true)
                ])(state);
            }
        case profileConstants.GET_MEMBERSHIP_PRICES_SUCCESS:
            {
                return flowFP([
                    setFP([
                        'membershipPrices',
                        'isLoading'
                    ], false),
                    setFP([
                        'membershipPrices',
                        'value'
                    ], action.payload)
                ])(state);
            }
        case profileConstants.GET_MEMBERSHIP_RECEIPT:
            {
                return flowFP([
                    setFP([
                        'membershipReceipt',
                        'isLoading'
                    ], true)
                ])(state);
            }
        case profileConstants.GET_MEMBERSHIP_RECEIPT_SUCCESS:
            {
                return flowFP([
                    setFP([
                        'membershipReceipt',
                        'isLoading'
                    ], false),
                    setFP([
                        'membershipReceipt',
                        'value'
                    ], action.payload)
                ])(state);
            }
        case profileConstants.REFRESH_CUSTOMER_SUCCESS:
            {
                return flowFP([
                    setFP([
                        'userProfile',
                        'customer'
                    ], action.payload)
                ])(state);
            }
        case profileConstants.GET_MEMBERSHIP_DETAILS:
            {
                return flowFP([
                    setFP([
                        'membershipDetails',
                        'isLoading'
                    ], true)
                ])(state);
            }
        case profileConstants.GET_MEMBERSHIP_DETAILS_SUCCESS:
            {
                return flowFP([
                    setFP([
                        'membershipDetails',
                        'isLoading'
                    ], false),
                    setFP([
                        'membershipDetails',
                        'value'
                    ], action.payload)
                ])(state);
            }
        case profileConstants.UPSERT_USER_MEMBERSHIP:
            {
                return flowFP([
                    setFP([
                        'upsertMembership',
                        'isLoading'
                    ], true)
                ])(state);
            }
        case profileConstants.UPSERT_USER_MEMBERSHIP_SUCCESS:
            {
                return flowFP([
                    setFP([
                        'upsertMembership',
                        'isLoading'
                    ], false),
                    setFP([
                        'userProfile',
                        'customer',
                        'memberships',
                        0
                    ], action.payload.membership)
                ])(state);
            }
        case profileConstants.UPSERT_USER_MEMBERSHIP_DONE:
            {
                return flowFP([
                    setFP([
                        'upsertMembership',
                        'isLoading'
                    ], false)
                ])(state);
            }
        case profileConstants.SET_HAS_USED_TRIAL:
            {
                return _object_spread_props(_object_spread({}, state), {
                    userProfile: _object_spread_props(_object_spread({}, state.userProfile), {
                        has_used_trial: action.payload
                    })
                });
            }
        case profileConstants.SET_PRETEND_TYPE:
            return setFP('pretendType', action.payload)(state);
        default:
            return state;
    }
};
export default profileReducer;
