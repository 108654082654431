import { combineReducers } from 'redux';
import activity from './activity';
import alerts from './alert';
import jobsReducer from './jobs';
import layout from './layout';
import mapReducer from './map';
import modal from './modal';
import productsReducer from './products';
import profileReducer from './profile';
import order from './order';
import project from './project';
import coverageReducer from './coverage';
import tourReducer from './tour';
import apiReducer from './api';
var rootReducer = combineReducers({
    layout: layout,
    alerts: alerts,
    jobsReducer: jobsReducer,
    profileReducer: profileReducer,
    productsReducer: productsReducer,
    mapReducer: mapReducer,
    modal: modal,
    activity: activity,
    order: order,
    project: project,
    coverageReducer: coverageReducer,
    tourReducer: tourReducer,
    apiReducer: apiReducer
});
export default rootReducer;
