function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import _ from 'lodash/fp';
import { ORDER_FAILURE_MODAL } from '../../components/modal/OrderFailureModal';
import { QUOTING_FAILURE_MODAL } from '../../components/modal/QuotingFailureModal';
import { client, larkiApi, quoteOrderRoute } from '../../utilities/api';
import { createId } from '../../utilities/map';
import * as metroMapUtils from '../../utilities/metromap';
import { alertConstants, jobsConstants, mapConstants } from '../constants';
import { toggleJobBoundaryHidden, toggleJobBoundaryVisibility } from './jobs';
import { showModal } from './modal';
export var getMapQueryData = function(payload) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            larkiApi.post("/engine/product/query", payload)
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: mapConstants.SET_MAP_DATA_QUERY,
                            payload: data
                        });
                        return [
                            2,
                            data
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch({
                            type: mapConstants.SET_MAP_DATA_QUERY,
                            payload: {}
                        });
                        dispatch({
                            type: alertConstants.ALERT_ERROR,
                            payload: {
                                message: error.response.data.message
                            }
                        });
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getMapDefaultQueryData = function(payload) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            larkiApi.post("/engine/product/query", payload)
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: mapConstants.SET_MAP_DEFAULT_DATA_QUERY,
                            payload: data
                        });
                        return [
                            3,
                            3
                        ];
                    case 2:
                        error = _state.sent();
                        dispatch({
                            type: mapConstants.SET_MAP_DEFAULT_DATA_QUERY,
                            payload: {}
                        });
                        dispatch({
                            type: alertConstants.ALERT_ERROR,
                            payload: {
                                message: error.response.data.message
                            }
                        });
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var quoteOrder = function(jobId, payload, includeFreeTierDiscount, property) {
    var shouldSetUpdater = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : true;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, e;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: jobsConstants.QUOTE_ORDER
                        });
                        return [
                            4,
                            larkiApi.post(quoteOrderRoute(jobId).url, _object_spread_props(_object_spread({}, payload, property && {
                                property: property
                            }), {
                                shouldSetUpdater: shouldSetUpdater
                            }), {
                                params: _object_spread({}, includeFreeTierDiscount && {
                                    includeFreeTierDiscount: includeFreeTierDiscount
                                })
                            })
                        ];
                    case 1:
                        data = _state.sent().data;
                        dispatch({
                            type: jobsConstants.QUOTE_ORDER_SUCCESS,
                            payload: data
                        });
                        return [
                            2,
                            data
                        ];
                    case 2:
                        e = _state.sent();
                        dispatch(showModal(QUOTING_FAILURE_MODAL));
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var commitOrder = function(jobId) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, e;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        return [
                            4,
                            larkiApi.post("/job/".concat(jobId, "/order/commit"))
                        ];
                    case 1:
                        data = _state.sent().data;
                        return [
                            2,
                            data
                        ];
                    case 2:
                        e = _state.sent();
                        dispatch(showModal(ORDER_FAILURE_MODAL));
                        return [
                            2,
                            null
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
var createSmartSelections = function(data) {
    var createSelection = function(type) {
        var _type_coverage_product, _type_coverage, _type_coverage_product1, _type_coverage1, _type_coverage_product2, _type_coverage2, _type_coverage_product3, _type_coverage3;
        return type && type.coverage && type.selection ? {
            id: createId(),
            region: type.selection.geometry,
            product_id: (_type_coverage = type.coverage) === null || _type_coverage === void 0 ? void 0 : (_type_coverage_product = _type_coverage.product) === null || _type_coverage_product === void 0 ? void 0 : _type_coverage_product.product_id,
            category_name: (_type_coverage1 = type.coverage) === null || _type_coverage1 === void 0 ? void 0 : (_type_coverage_product1 = _type_coverage1.product) === null || _type_coverage_product1 === void 0 ? void 0 : _type_coverage_product1.category_name,
            display_name: (_type_coverage2 = type.coverage) === null || _type_coverage2 === void 0 ? void 0 : (_type_coverage_product2 = _type_coverage2.product) === null || _type_coverage_product2 === void 0 ? void 0 : _type_coverage_product2.display_name,
            delivery_method: (_type_coverage3 = type.coverage) === null || _type_coverage3 === void 0 ? void 0 : (_type_coverage_product3 = _type_coverage3.product) === null || _type_coverage_product3 === void 0 ? void 0 : _type_coverage_product3.delivery_method
        } : null;
    };
    var smartSelections = [];
    if (!isEmpty(data.property_vicinity)) {
        data.property_vicinity.forEach(function(vicinity) {
            smartSelections.push(createSelection(vicinity));
        });
    }
    if (!isEmpty(data.neighbourhood.coverage)) {
        smartSelections.push(createSelection(data.neighbourhood));
    }
    if (!isEmpty(data.roads)) {
        var _smartSelections;
        (_smartSelections = smartSelections).push.apply(_smartSelections, _to_consumable_array(data.roads.map(function(road) {
            return _object_spread_props(_object_spread({}, createSelection(road)), {
                ui_state: {
                    type: 'Polyline',
                    line_path: road.selection.raw.coordinates
                }
            });
        })));
    }
    if (data.buildings) {
        var _smartSelections1;
        (_smartSelections1 = smartSelections).push.apply(_smartSelections1, _to_consumable_array(data.buildings.map(function(building) {
            return createSelection(building);
        })));
    }
    return smartSelections;
};
/**
 * Chek if AutomaticSmartSelection is available
 * on the address/coordinate
 *
 * @param {*} place
 * @param {boolean} [isUndefinedAddress=false] - used to empty/null {boundaries, lotArea} from mapState
 * @returns {(dispatch: any) => unknown}
 */ export var checkAutomaticSmartSelectionsAvailability = function(place) {
    var isUndefinedAddress = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, smartSelections, isAutomaticDataAvailable;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        console.assert(!isUndefinedAddress, 'Address is undefined.');
                        dispatch({
                            type: mapConstants.GET_PLACE_SMART_SELECTIONS,
                            payload: {
                                deliveryMethod: 'automatic'
                            }
                        });
                        return [
                            4,
                            client.getSmartSelections(place)
                        ];
                    case 1:
                        data = _state.sent().data;
                        smartSelections = createSmartSelections(data);
                        isAutomaticDataAvailable = smartSelections.some(function(selection) {
                            return selection.delivery_method === 'automatic';
                        });
                        dispatch({
                            type: mapConstants.SET_AUTOMATIC_SMART_SELECTIONS_AVAILABILITY,
                            payload: {
                                boundaries: !isEmpty(smartSelections) && !isUndefinedAddress ? data.property : undefined,
                                lotArea: !isUndefinedAddress ? data.property.area_in_sqm : null,
                                isAutomaticDataAvailable: !isUndefinedAddress ? isAutomaticDataAvailable : false
                            }
                        });
                        return [
                            2,
                            isAutomaticDataAvailable
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var getPlaceSmartSelection = function(place, deliveryMethod, buttonId) {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var data, smartSelections, isAutomaticDataAvailable, filteredSelections, e;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        _state.trys.push([
                            0,
                            2,
                            ,
                            3
                        ]);
                        dispatch({
                            type: mapConstants.GET_PLACE_SMART_SELECTIONS,
                            payload: {
                                deliveryMethod: deliveryMethod,
                                buttonId: buttonId
                            }
                        });
                        return [
                            4,
                            client.getSmartSelections(place)
                        ];
                    case 1:
                        data = _state.sent().data;
                        smartSelections = createSmartSelections(data);
                        isAutomaticDataAvailable = smartSelections.some(function(selection) {
                            return selection.delivery_method === 'automatic';
                        });
                        filteredSelections = smartSelections.filter(function(selection) {
                            return isAutomaticDataAvailable ? deliveryMethod && selection.delivery_method === deliveryMethod : true;
                        });
                        dispatch({
                            type: mapConstants.GET_PLACE_SMART_SELECTIONS_SUCCESS,
                            payload: {
                                boundaries: !isEmpty(filteredSelections) ? data.property : undefined,
                                lotArea: data.property.area_in_sqm,
                                smartSelections: filteredSelections,
                                buttonId: buttonId
                            }
                        });
                        return [
                            2,
                            filteredSelections
                        ];
                    case 2:
                        e = _state.sent();
                        dispatch(showModal(ORDER_FAILURE_MODAL));
                        return [
                            3,
                            3
                        ];
                    case 3:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var clearBoundaries = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                return [
                    2,
                    dispatch({
                        type: mapConstants.CLEAR_BOUNDARIES
                    })
                ];
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var clearSmartSelections = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                return [
                    2,
                    dispatch({
                        type: mapConstants.CLEAR_SMART_SELECTIONS
                    })
                ];
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var debouncedResetToggleBoundaryVisibility = function(dispatch) {
    return debounce(function() {
        return dispatch({
            type: mapConstants.RESET_ZOOM_TOGGLE_BOUNDARY_VISIBILITY
        });
    }, 300);
};
export var setZoomToggle = function() {
    var isZoomToggle = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            return _ts_generator(this, function(_state) {
                dispatch({
                    type: mapConstants.SET_ZOOM_TOGGLE,
                    payload: {
                        isZoomToggle: isZoomToggle
                    }
                });
                if (isZoomToggle) {
                    debouncedResetToggleBoundaryVisibility(dispatch)();
                }
                return [
                    2
                ];
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var loadHighResCollections = function() {
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var collections, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        dispatch({
                            type: mapConstants.LOAD_HIGH_RES_COLLECTIONS
                        });
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            3,
                            ,
                            4
                        ]);
                        return [
                            4,
                            metroMapUtils.getHighResCollections()
                        ];
                    case 2:
                        collections = _state.sent();
                        dispatch({
                            type: mapConstants.LOAD_HIGH_RES_COLLECTIONS_SUCCESS,
                            payload: collections
                        });
                        return [
                            3,
                            4
                        ];
                    case 3:
                        error = _state.sent();
                        console.error(error);
                        dispatch({
                            type: mapConstants.LOAD_HIGH_RES_COLLECTIONS_FAILURE,
                            payload: 'something went wrong'
                        });
                        return [
                            3,
                            4
                        ];
                    case 4:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var loadHighResLayers = function(collectionIds, bbox) {
    var currentDate = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : null;
    return function() {
        var _ref = _async_to_generator(function(dispatch) {
            var layersArr, layers, __keys, latestDate, error;
            return _ts_generator(this, function(_state) {
                switch(_state.label){
                    case 0:
                        dispatch({
                            type: mapConstants.LOAD_HIGH_RES_LAYERS
                        });
                        _state.label = 1;
                    case 1:
                        _state.trys.push([
                            1,
                            3,
                            ,
                            4
                        ]);
                        return [
                            4,
                            Promise.all(collectionIds.map(function() {
                                var _ref = // see https://advancedweb.hu/how-to-use-async-functions-with-array-map-in-javascript/ for how this works
                                _async_to_generator(function(collectionId) {
                                    return _ts_generator(this, function(_state) {
                                        switch(_state.label){
                                            case 0:
                                                return [
                                                    4,
                                                    metroMapUtils.getHighResLayers(collectionId, bbox)
                                                ];
                                            case 1:
                                                return [
                                                    2,
                                                    _state.sent() || []
                                                ];
                                        }
                                    });
                                });
                                return function(collectionId) {
                                    return _ref.apply(this, arguments);
                                };
                            }()))
                        ];
                    case 2:
                        layersArr = _state.sent().flat();
                        layers = metroMapUtils.groupHighResLayersByDate(layersArr);
                        dispatch({
                            type: mapConstants.LOAD_HIGH_RES_LAYERS_SUCCESS,
                            payload: layers
                        });
                        if (!currentDate || !_.has(currentDate, layers)) {
                            ;
                            latestDate = (__keys = _.keys(layers)) === null || __keys === void 0 ? void 0 : __keys[0];
                            dispatch({
                                type: mapConstants.SET_HIGH_RES_CURRENT_DATE,
                                payload: latestDate
                            });
                        }
                        return [
                            3,
                            4
                        ];
                    case 3:
                        error = _state.sent();
                        console.error(error);
                        dispatch({
                            type: mapConstants.LOAD_HIGH_RES_LAYERS_FAILURE,
                            payload: 'something went wrong'
                        });
                        return [
                            3,
                            4
                        ];
                    case 4:
                        return [
                            2
                        ];
                }
            });
        });
        return function(dispatch) {
            return _ref.apply(this, arguments);
        };
    }();
};
export var nextHighResLayer = function() {
    return function(dispatch) {
        dispatch({
            type: mapConstants.NEXT_HIGH_RES_LAYER
        });
    };
};
export var prevHighResLayer = function() {
    return function(dispatch) {
        dispatch({
            type: mapConstants.PREV_HIGH_RES_LAYER
        });
    };
};
export var setBoundaries = function(property) {
    return function(dispatch) {
        dispatch({
            type: mapConstants.SET_BOUNDARIES,
            payload: {
                boundaries: !isEmpty(property) ? property : undefined,
                lotArea: (property === null || property === void 0 ? void 0 : property.area_in_sqm) || null
            }
        });
    };
};
export var clearLotStateArea = function() {
    return function(dispatch) {
        dispatch(setBoundaries({}));
        dispatch(toggleJobBoundaryHidden(true));
        dispatch(toggleJobBoundaryVisibility(false));
    };
};
