import { HIDE_MODAL, SHOW_MODAL } from '../constants/modal';
export var showModal = function(id, modalProps) {
    return {
        type: SHOW_MODAL,
        payload: {
            id: id,
            modalProps: modalProps
        }
    };
};
export var hideModal = function(id) {
    return {
        type: HIDE_MODAL,
        payload: {
            id: id
        }
    };
};
