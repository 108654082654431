function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import _ from 'lodash/fp';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usePreviousDistinct } from 'react-use';
import { addToCoverage } from '../../redux/actions/coverage';
import { getUnseenHashes } from '../../utilities/coverage';
import { isArrayEqual } from '../../utilities/lodash';
import { convertToGeoJson, convertToGoogleGeometry, geoEncodeBounds, getGMGeometryBounds, getRegionBounds } from '../../utilities/map';
import { MapViewContext } from './mapViewContext';
/**
 * `useCoverage` is responsible for adding coverage iteratively to the coverage cache upon specific user actions.
 * It works by awaiting the region selection to stabilize, then encoding the bounds of the region by obtaining a list of geohashes.
 * See https://en.wikipedia.org/wiki/Geohash for more info.
 * Why is this advantageous? It allows us to avoid querying coverage for the same region multiple times.
 * Instead, we can query the cache for coverage data and only request new data for regions that have not been seen before.
 *
 * Note: uses MapViewContext, so ensure that the <MapViewContext.Provider /> is a parent of the component using this hook.
 */ var useCoverage = function() {
    var state = useContext(MapViewContext).state;
    var coverageState = useSelector(function(reduxState) {
        return {
            coverageState: reduxState.coverageReducer
        };
    }).coverageState;
    var dispatch = useDispatch();
    var previousSelections = usePreviousDistinct(state.selections);
    /**
   * Add new hashes to the coverage cache whenever the region selection changes.
   */ useEffect(function() {
        var __values;
        var regionSelection = (__values = _.values(state.debouncedRegionSelection)) === null || __values === void 0 ? void 0 : __values[0];
        if (!regionSelection || state.activeSelection) {
            return;
        }
        var bounds = getGMGeometryBounds(regionSelection);
        var hashes = geoEncodeBounds(bounds);
        var unseenHashes = getUnseenHashes(hashes, coverageState.hashes);
        dispatch(addToCoverage(unseenHashes));
    }, [
        state.debouncedRegionSelection
    ]);
    /**
   * Add new hashes to the coverage cache when selection regions are changed.
   */ useEffect(function() {
        if (state.googleMapsScriptStatus !== 'ready' || !state.selections || isArrayEqual(state.selections, previousSelections, [
            'focused'
        ])) {
            return;
        }
        var unseenHashes = [];
        _.forEach(function(selection) {
            var _unseenHashes;
            var _convertToGeoJson_features;
            var region = (_convertToGeoJson_features = convertToGeoJson([
                selection
            ]).features) === null || _convertToGeoJson_features === void 0 ? void 0 : _convertToGeoJson_features[0].geometry;
            if (!region) {
                return;
            }
            var bounds = convertToGoogleGeometry(region.coordinates[0]).bounds;
            var hashes = geoEncodeBounds(bounds);
            (_unseenHashes = unseenHashes).push.apply(_unseenHashes, _to_consumable_array(getUnseenHashes(hashes, coverageState.hashes)));
        })(state.selections);
        unseenHashes = _.uniq(unseenHashes);
        dispatch(addToCoverage(unseenHashes));
    }, [
        state.selections,
        state.googleMapsScriptStatus
    ]);
    /**
   * Add new hashes to the coverage cache when the default region changes.
   */ useEffect(function() {
        if (!state.defaultRegion) {
            return;
        }
        var bounds = getRegionBounds(state.defaultRegion);
        var hashes = geoEncodeBounds(bounds);
        var unseenHashes = getUnseenHashes(hashes, coverageState.hashes);
        dispatch(addToCoverage(unseenHashes));
    }, [
        state.defaultRegion
    ]);
};
export default useCoverage;
