function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
function _to_primitive(input, hint) {
    if (_type_of(input) !== "object" || input === null) return input;
    var prim = input[Symbol.toPrimitive];
    if (prim !== undefined) {
        var res = prim.call(input, hint || "default");
        if (_type_of(res) !== "object") return res;
        throw new TypeError("@@toPrimitive must return a primitive value.");
    }
    return (hint === "string" ? String : Number)(input);
}
function _to_property_key(arg) {
    var key = _to_primitive(arg, "string");
    return _type_of(key) === "symbol" ? key : String(key);
}
function _type_of(obj) {
    "@swc/helpers - typeof";
    return obj && typeof Symbol !== "undefined" && obj.constructor === Symbol ? "symbol" : typeof obj;
}
import { omit } from 'lodash';
import _ from 'lodash/fp';
import flowFP from 'lodash/fp/flow';
import setFP from 'lodash/fp/set';
import set from 'lodash/set';
import { ACTIVE_STATUS, EXCLUDED_COLUMNS_ON_COPY_JOB } from '../../constants/job';
import { jobsConstants } from '../constants';
import { DEFAULT, FAILED, LOADING, SUCCESS } from '../stateTools';
var defaultJobsState = {
    jobsList: {},
    isJobsListLoading: false,
    listJobFilter: {
        status: ACTIVE_STATUS,
        searchText: ''
    },
    statusCounts: {},
    adminStatusCounts: {},
    receipt: {
        isLoading: false,
        value: undefined
    },
    adminJobList: {},
    isAdminJobListLoading: false,
    pointCloud: undefined,
    isJobCreatedModalOpen: false,
    project: {},
    isQuotingJob: false,
    isGettingJob: false,
    getJobError: null,
    job: null,
    undefinedAddress: false
};
var jobsReducer = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : defaultJobsState, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case jobsConstants.GET_JOB:
            {
                return _object_spread_props(_object_spread({}, state), {
                    isGettingJob: true
                });
            }
        case jobsConstants.GET_JOB_FAILED:
            {
                return _.flow(_.set('isGettingJob', false), _.set('getJobError', action.payload))(state);
            }
        case jobsConstants.GET_JOB_SUCCESS:
            {
                return _.flow(_.set('isGettingJob', false), _.set('getJobError', null), _.set('job', action.payload))(state);
            }
        case jobsConstants.SET_JOB:
            {
                return _object_spread_props(_object_spread({}, state), {
                    job: action.payload,
                    isGettingJob: false
                });
            }
        case jobsConstants.CLEAR_JOB:
            return _object_spread({}, defaultJobsState);
        case jobsConstants.UPDATE_JOB_PROJECT_NAME:
            {
                var _action_payload = action.payload, id = _action_payload.id, isAdmin = _action_payload.isAdmin;
                var property = isAdmin ? 'adminJobList' : 'jobsList';
                return flowFP([
                    setFP([
                        property,
                        id,
                        'ui',
                        'updateState'
                    ], LOADING)
                ])(state);
            }
        case jobsConstants.UPDATE_JOB_PROJECT_NAME_SUCCESS:
            {
                var _action_payload1 = action.payload, project = _action_payload1.project, isAdmin1 = _action_payload1.isAdmin;
                var property1 = isAdmin1 ? 'adminJobList' : 'jobsList';
                return flowFP([
                    setFP([
                        property1,
                        project.id,
                        'ui'
                    ], {
                        updateState: SUCCESS
                    }),
                    setFP([
                        property1,
                        project.id,
                        'project',
                        'name'
                    ], project.name),
                    setFP([
                        property1,
                        project.id,
                        'updated_at'
                    ], project.updated_at),
                    setFP([
                        property1,
                        project.id,
                        'updater'
                    ], project.updater)
                ])(state);
            }
        case jobsConstants.UPDATE_JOB_PROJECT_NAME_FAILED:
            {
                var _action_payload2 = action.payload, projectId = _action_payload2.projectId, isAdmin2 = _action_payload2.isAdmin;
                var property2 = isAdmin2 ? 'adminJobList' : 'jobsList';
                return _object_spread_props(_object_spread({}, state), _define_property({}, property2, _object_spread_props(_object_spread({}, state.jobsList), _define_property({}, projectId, _object_spread_props(_object_spread({}, state.jobsList[projectId]), {
                    ui: {
                        updateState: FAILED
                    }
                })))));
            }
        case jobsConstants.RESET_UPDATE_JOB_PROJECT_NAME:
            {
                var isAdmin3 = action.payload.isAdmin;
                var property3 = isAdmin3 ? 'adminJobList' : 'jobsList';
                return flowFP([
                    setFP([
                        property3,
                        action.payload.id,
                        'ui',
                        'updateState'
                    ], DEFAULT)
                ])(state);
            }
        case jobsConstants.SET_PROJECT:
            return _object_spread_props(_object_spread({}, state), {
                project: action.payload
            });
        case jobsConstants.GET_JOBS_LIST:
            return _object_spread_props(_object_spread({}, state), {
                isJobsListLoading: true
            });
        case jobsConstants.SET_JOBS_LIST:
            {
                return flowFP([
                    setFP('jobsList', action.jobsList),
                    setFP('isJobsListLoading', false)
                ])(state);
            }
        case jobsConstants.GET_JOBS_LIST_DONE:
            return _object_spread_props(_object_spread({}, state), {
                isJobsListLoading: false
            });
        case jobsConstants.GET_ADMIN_JOB_LIST:
            return _object_spread_props(_object_spread({}, state), {
                isAdminJobListLoading: true
            });
        case jobsConstants.SET_ADMIN_JOB_LIST:
            {
                return flowFP([
                    setFP('adminJobList', action.jobsList),
                    setFP('isAdminJobListLoading', false)
                ])(state);
            }
        case jobsConstants.GET_ADMIN_JOB_LIST_DONE:
            return _object_spread_props(_object_spread({}, state), {
                isAdminJobListLoading: false
            });
        case jobsConstants.SET_JOB_STATUS_COUNTS:
            return _object_spread_props(_object_spread({}, state), {
                statusCounts: action.statusCounts
            });
        case jobsConstants.SET_POINT_CLOUD:
            return _object_spread_props(_object_spread({}, state), {
                pointCloud: _object_spread({}, action.pointCloud)
            });
        case jobsConstants.OPEN_JOB_CREATED_DIALOG:
            return _object_spread_props(_object_spread({}, state), {
                isJobCreatedModalOpen: true
            });
        case jobsConstants.CLOSE_JOB_CREATED_DIALOG:
            return _object_spread_props(_object_spread({}, state), {
                isJobCreatedModalOpen: false
            });
        case jobsConstants.DELETE_PROJECT_SUCCESS:
            {
                var _state_jobsList = state.jobsList, _action_payload3 = action.payload, value = _state_jobsList[_action_payload3], rest = _object_without_properties(_state_jobsList, [
                    _action_payload3
                ].map(_to_property_key));
                var _state_adminJobList = state.adminJobList, _action_payload4 = action.payload, adminValue = _state_adminJobList[_action_payload4], adminRest = _object_without_properties(_state_adminJobList, [
                    _action_payload4
                ].map(_to_property_key));
                return _object_spread_props(_object_spread({}, state), {
                    jobsList: rest,
                    adminJobList: adminRest
                });
            }
        case jobsConstants.UPDATE_INVITE:
            {
                var job = state.jobsList[action.payload.id];
                return _object_spread_props(_object_spread({}, state), {
                    jobsList: _object_spread_props(_object_spread({}, state.jobsList), _define_property({}, action.payload.id, _object_spread_props(_object_spread({}, job), {
                        user_project: _object_spread_props(_object_spread({}, job.user_project), {
                            invite_status: action.payload.status
                        })
                    })))
                });
            }
        case jobsConstants.POINTERRA_ID_UPDATED:
            {
                var job1 = set(state.jobsList[action.payload.id], [
                    'point_clouds',
                    '0',
                    'pointerra_id'
                ], action.payload.pointerraId);
                return _object_spread_props(_object_spread({}, state), {
                    jobsList: _object_spread_props(_object_spread({}, state.jobsList), _define_property({}, action.payload.id, _object_spread({}, job1)))
                });
            }
        case jobsConstants.SET_LIST_JOB_STATUS:
            {
                return flowFP([
                    setFP([
                        'listJobFilter',
                        'status'
                    ], action.payload)
                ])(state);
            }
        case jobsConstants.SET_LIST_JOB_SEARCHTEXT:
            {
                return flowFP([
                    setFP([
                        'listJobFilter',
                        'searchText'
                    ], action.payload)
                ])(state);
            }
        case jobsConstants.SET_ADMIN_STATUS_COUNT:
            return _object_spread_props(_object_spread({}, state), {
                adminStatusCounts: action.statusCounts
            });
        case jobsConstants.GET_JOB_RECEIPT:
            {
                return flowFP([
                    setFP([
                        'receipt',
                        'isLoading'
                    ], true)
                ])(state);
            }
        case jobsConstants.GET_JOB_RECEIPT_SUCCESS:
            {
                return flowFP([
                    setFP([
                        'receipt',
                        'isLoading'
                    ], false),
                    setFP([
                        'receipt',
                        'value'
                    ], action.payload)
                ])(state);
            }
        case jobsConstants.ADD_COPIED_JOB:
            {
                var _action_payload5 = action.payload, job2 = _action_payload5.job, isAdmin4 = _action_payload5.isAdmin;
                var duplicatedProject = _object_spread_props(_object_spread({}, job2), {
                    jobs: [
                        getJobColumnsOnly(job2)
                    ]
                });
                var list = isAdmin4 ? {
                    adminJobList: _object_spread_props(_object_spread({}, state.adminJobList), _define_property({}, job2.project.id, duplicatedProject)),
                    adminStatusCounts: _object_spread_props(_object_spread({}, state.adminStatusCounts), {
                        draft: ++state.adminStatusCounts.draft
                    })
                } : {
                    jobsList: _object_spread_props(_object_spread({}, state.jobsList), _define_property({}, job2.project.id, duplicatedProject)),
                    statusCounts: _object_spread_props(_object_spread({}, state.statusCounts), {
                        draft: ++state.statusCounts.draft
                    })
                };
                var updatedState = _object_spread({}, state, list);
                return updatedState;
            }
        case jobsConstants.QUOTE_ORDER:
            {
                return _object_spread_props(_object_spread({}, state), {
                    isQuotingJob: true
                });
            }
        case jobsConstants.QUOTE_ORDER_SUCCESS:
            {
                return _object_spread_props(_object_spread({}, state), {
                    isQuotingJob: false,
                    job: action.payload
                });
            }
        case jobsConstants.TOGGLE_JOB_BOUNDARY_VISIBILITY:
            {
                return flowFP([
                    setFP([
                        'job',
                        'lot_area_state',
                        'visibility'
                    ], action.payload)
                ])(state);
            }
        case jobsConstants.TOGGLE_JOB_BOUNDARY_HIDDEN:
            {
                return flowFP([
                    setFP([
                        'job',
                        'lot_area_state',
                        'hidden'
                    ], action.payload)
                ])(state);
            }
        case jobsConstants.SET_UNDEFINED_ADDRESS:
            {
                return flowFP([
                    setFP([
                        'undefinedAddress'
                    ], action.payload)
                ])(state);
            }
        default:
            return state;
    }
};
var getJobColumnsOnly = function(job) {
    return omit(job, EXCLUDED_COLUMNS_ON_COPY_JOB);
};
export default jobsReducer;
