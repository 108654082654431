import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { profileSelectors } from '../redux/selectors/profile';
import { isDemoJob, jobHasPointCloud } from '../utilities/job';
import { getProjectLatestJob, isDemoProject } from '../utilities/project';
var useProjectPermissions = function(project) {
    var isAdmin = useSelector(profileSelectors.getIsAdmin);
    var isStaff = useSelector(profileSelectors.getIsStaff);
    var is3dViewerAccessibleToUser = useSelector(profileSelectors.get3dViewerEnabled);
    var isDeleteEnabled = useMemo(function() {
        var _project_jobs;
        return isAdmin || (project === null || project === void 0 ? void 0 : (_project_jobs = project.jobs) === null || _project_jobs === void 0 ? void 0 : _project_jobs.every(function(job) {
            return job.status === 'draft';
        }));
    }, [
        isAdmin,
        project
    ]);
    var isPending = useMemo(function() {
        var _project_user_project;
        return (project === null || project === void 0 ? void 0 : (_project_user_project = project.user_project) === null || _project_user_project === void 0 ? void 0 : _project_user_project.invite_status) === 'pending';
    }, [
        project
    ]);
    var latestJob = useMemo(function() {
        return getProjectLatestJob(project);
    }, [
        project
    ]);
    var is2dMapEnabled = useMemo(function() {
        return !!latestJob;
    }, [
        latestJob
    ]);
    var is3dViewerLocked = useMemo(function() {
        return jobHasPointCloud(latestJob) && !is3dViewerAccessibleToUser && !isDemoJob(latestJob);
    }, [
        is3dViewerAccessibleToUser,
        latestJob
    ]);
    var is3dViewerEnabled = useMemo(function() {
        return jobHasPointCloud(project) && !isPending && is3dViewerAccessibleToUser || jobHasPointCloud(project) && isDemoProject(project);
    }, [
        project,
        isPending,
        is3dViewerAccessibleToUser
    ]);
    var isAdministrationEnabled = useMemo(function() {
        return latestJob && latestJob.status === 'demo' ? isAdmin : isStaff;
    }, [
        isAdmin,
        isStaff,
        latestJob
    ]);
    return {
        is2dMapEnabled: is2dMapEnabled,
        is3dViewerLocked: is3dViewerLocked,
        is3dViewerEnabled: is3dViewerEnabled,
        isDeleteEnabled: isDeleteEnabled,
        isAdministrationEnabled: isAdministrationEnabled
    };
};
export default useProjectPermissions;
