import { createSelector } from '@reduxjs/toolkit';
import { first, flatten, uniq } from 'lodash';
import { ENTITLEMENTS } from '../../constants/membership';
import { USER_TYPE } from '../../constants/user';
import { isAdministrator, isStaff, isUserGovernment, isUserUnderTrial, userHasActiveMembership, userHasValidMembership } from '../../utilities/user';
var getUserProfile = function(state) {
    return state.profileReducer.userProfile;
};
var getPretendType = function(state) {
    return state.profileReducer.pretendType;
};
var getActiveMemberships = function(state) {
    var _state_profileReducer_userProfile_customer;
    return (_state_profileReducer_userProfile_customer = state.profileReducer.userProfile.customer) === null || _state_profileReducer_userProfile_customer === void 0 ? void 0 : _state_profileReducer_userProfile_customer.memberships.filter(function(membership) {
        return [
            'active'
        ].includes(membership.status);
    });
};
var getActiveMembership = createSelector(getActiveMemberships, function(memberships) {
    return first(memberships);
});
var hasValidMembership = createSelector(getActiveMemberships, function(memberships) {
    return (memberships === null || memberships === void 0 ? void 0 : memberships.length) > 0;
});
var getCustomer = function(state) {
    return state.profileReducer.userProfile.customer;
};
var getEntitlements = createSelector(getCustomer, function(customer) {
    return uniq(flatten(customer === null || customer === void 0 ? void 0 : customer.memberships.map(function(membership) {
        return membership.entitlements;
    })));
});
var getHasHighResEntitlement = createSelector(getEntitlements, function(entitlements) {
    return entitlements.includes(ENTITLEMENTS.HIGH_RES_MAP);
});
var getHighResEnabled = createSelector(getHasHighResEntitlement, getUserProfile, getPretendType, function(hasHighResEntitlement, userProfile, pretendType) {
    return pretendType ? [
        USER_TYPE.TRIAL,
        USER_TYPE.ESSENTIALS,
        USER_TYPE.GOVERNMENT,
        USER_TYPE.LARKI_SUPPORT
    ].includes(pretendType) : hasHighResEntitlement || [
        'superadministrator',
        'administrator',
        'staff',
        'government'
    ].includes(userProfile.role);
});
var getHas3dViewerEntitlement = createSelector(getEntitlements, function(entitlements) {
    return entitlements.includes(ENTITLEMENTS._3D_VIEWER);
});
var get3dViewerEnabled = createSelector(getHas3dViewerEntitlement, getUserProfile, getPretendType, function(has3dViewerEntitlement, userProfile, pretendType) {
    return pretendType ? [
        USER_TYPE.TRIAL,
        USER_TYPE.ESSENTIALS,
        USER_TYPE.GOVERNMENT,
        USER_TYPE.LARKI_SUPPORT
    ].includes(pretendType) : has3dViewerEntitlement || [
        'superadministrator',
        'administrator',
        'staff',
        'government'
    ].includes(userProfile === null || userProfile === void 0 ? void 0 : userProfile.role);
});
var getCanDeleteAnyProject = createSelector(getUserProfile, getPretendType, function(userProfile, pretendType) {
    return pretendType ? [
        USER_TYPE.LARKI_SUPPORT
    ].includes(pretendType) : isAdministrator(userProfile.role);
});
var getIsAdmin = createSelector(getUserProfile, getPretendType, function(userProfile, pretendType) {
    return userProfile && isAdministrator(userProfile.role) && (!pretendType || pretendType === USER_TYPE.LARKI_SUPPORT);
});
var getIsStaff = createSelector(getUserProfile, getPretendType, function(userProfile, pretendType) {
    return userProfile && isStaff(userProfile.role) && (!pretendType || pretendType === USER_TYPE.LARKI_SUPPORT);
});
var getCanUserStartTrial = createSelector(getUserProfile, getPretendType, function(userProfile, pretendType) {
    return userProfile && !isUserGovernment(userProfile) && !isUserUnderTrial(userProfile) && !userHasValidMembership(userProfile) && !userProfile.has_used_trial && (!isStaff(userProfile.role) || pretendType && [
        USER_TYPE.FREE
    ].includes(pretendType));
});
var getIsUserUnderTrial = createSelector(getUserProfile, getPretendType, function(userProfile, pretendType) {
    return userProfile && isUserUnderTrial(userProfile) && (!isStaff(userProfile.role) || pretendType && [
        USER_TYPE.FREE
    ].includes(pretendType));
});
var getCanUserSeeTrialExpired = createSelector(getUserProfile, getPretendType, function(userProfile, pretendType) {
    return userProfile && (!isStaff(userProfile.role) || pretendType && [
        USER_TYPE.FREE
    ].includes(pretendType));
});
var getCanUserUpgrade = createSelector(getUserProfile, getPretendType, function(userProfile, pretendType) {
    return userProfile && !isUserGovernment(userProfile) && !userHasActiveMembership(userProfile) && (userProfile.has_used_trial || isUserUnderTrial(userProfile)) && (!isStaff(userProfile.role) || pretendType && [
        USER_TYPE.FREE
    ].includes(pretendType));
});
var getIsUpgradeRecommended = createSelector(getUserProfile, getCanUserUpgrade, function(userProfile, canUserUpgrade) {
    return userProfile && canUserUpgrade && (isUserUnderTrial(userProfile) || userProfile.has_used_trial);
});
export var profileSelectors = {
    getUserProfile: getUserProfile,
    getPretendType: getPretendType,
    getHighResEnabled: getHighResEnabled,
    get3dViewerEnabled: get3dViewerEnabled,
    hasValidMembership: hasValidMembership,
    getActiveMembership: getActiveMembership,
    getCanDeleteAnyProject: getCanDeleteAnyProject,
    getIsAdmin: getIsAdmin,
    getIsStaff: getIsStaff,
    getCanUserUpgrade: getCanUserUpgrade,
    getCanUserSeeTrialExpired: getCanUserSeeTrialExpired,
    getCanUserStartTrial: getCanUserStartTrial,
    getIsUserUnderTrial: getIsUserUnderTrial,
    getIsUpgradeRecommended: getIsUpgradeRecommended
};
