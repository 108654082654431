import get from 'lodash/get';
import { USER_TYPE } from '../constants/user';
import { getPriceId } from './membership';
export var isSuperAdministrator = function(role) {
    return role === 'superadministrator';
};
export var isAdministrator = function(role) {
    return role === 'administrator' || isSuperAdministrator(role);
};
export var isStaff = function(role) {
    return role === 'staff' || isAdministrator(role);
};
// NOTE: Please try to make the first arg to be an user object
export var isUserStaff = function(user) {
    return (user === null || user === void 0 ? void 0 : user.role) === 'staff' || isAdministrator(user === null || user === void 0 ? void 0 : user.role);
};
// We assume the first element is the user's current membership
export var getUserMembership = function(user) {
    return get(user, [
        'customer',
        'memberships',
        0
    ]);
};
export var isUserAnonymous = function(user) {
    return user && user.is_anonymous;
};
export var userHasMembershipWithStatus = function(user, status) {
    var _user_customer;
    return user === null || user === void 0 ? void 0 : (_user_customer = user.customer) === null || _user_customer === void 0 ? void 0 : _user_customer.memberships.some(function(membership) {
        return status.includes(membership === null || membership === void 0 ? void 0 : membership.status);
    });
};
export var userHasActiveMembership = function(user) {
    return userHasMembershipWithStatus(user, [
        'active'
    ]);
};
export var userHasValidMembership = function(user) {
    return userHasMembershipWithStatus(user, [
        'active',
        'trialing'
    ]);
};
export var isUserUnderTrial = function(user) {
    return !isStaff(user.role) && userHasMembershipWithStatus(user, [
        'trialing'
    ]);
};
export var getUserPriceId = function(user) {
    var _user_customer;
    return (user === null || user === void 0 ? void 0 : user.customer) && getPriceId((_user_customer = user.customer) === null || _user_customer === void 0 ? void 0 : _user_customer.memberships);
};
export var isUserGovernment = function(user) {
    return (user === null || user === void 0 ? void 0 : user.role) === 'government';
};
export var getUserType = function(user) {
    return isUserStaff(user) ? USER_TYPE.LARKI_SUPPORT : isUserGovernment(user) ? USER_TYPE.GOVERNMENT : userHasValidMembership(user) ? isUserUnderTrial(user) ? USER_TYPE.TRIAL : USER_TYPE.ESSENTIALS : USER_TYPE.FREE;
};
export var canUserUpgrade = function(user) {
    return user.has_used_trial || isUserUnderTrial(user);
};
export var canGiveTrial = function(currentUser, targetUser) {
    if (!currentUser || !targetUser || !isStaff(currentUser.role) || isStaff(targetUser.role)) {
        return false;
    }
    var membership = getUserMembership(targetUser);
    if (!membership) return true;
    return membership.cancel_date || membership.status === 'trialing';
};
