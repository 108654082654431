function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import _ from 'lodash/fp';
export var mapWithKey = _.map.convert({
    cap: false
});
export var getObjectDiff = function(x, y) {
    // Note: only returns the keys in x with values different to those in y
    return _.filter(function(k) {
        return !_.isEqual(x[k], y[k]);
    }, _.keys(x));
};
export var isObjectEqual = function(x, y) {
    var excludeKeys = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [];
    // Note: only returns the keys in x with values different to those in y
    var diff = getObjectDiff(x, y);
    if (_.isEmpty(diff)) {
        return true;
    }
    return _.every(function(k) {
        return excludeKeys.includes(k);
    }, diff);
};
/**
 * Checks if arrays of objects xs and ys are equal.
 * Ignored keys can be passed as an optional 3rd argument.
 */ export var isArrayEqual = function(xs, ys) {
    var excludeKeys = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [];
    if ((xs === null || xs === void 0 ? void 0 : xs.length) !== (ys === null || ys === void 0 ? void 0 : ys.length)) {
        return false;
    }
    return _.every(function(param) {
        var _param = _sliced_to_array(param, 2), x = _param[0], y = _param[1];
        return isObjectEqual(x, y, excludeKeys);
    }, _.zip(xs, ys));
};
