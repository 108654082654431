export var mapConstants = {
    SET_MAP_DATA_QUERY: 'SET_MAP_DATA_QUERY',
    SET_MAP_DEFAULT_DATA_QUERY: 'SET_MAP_DEFAULT_DATA_QUERY',
    CLEAR_MAP_DATA_QUERY: 'CLEAR_MAP_DATA_QUERY',
    GET_PLACE_SMART_SELECTIONS: 'GET_PLACE_SMART_SELECTIONS',
    GET_PLACE_SMART_SELECTIONS_SUCCESS: 'GET_PLACE_SMART_SELECTIONS_SUCCESS',
    SET_AUTOMATIC_SMART_SELECTIONS_AVAILABILITY: 'GET_SMART_SELECTIONS_AUTOMATIC_AVAILABILITY',
    CLEAR_SMART_SELECTIONS: 'CLEAR_SMART_SELECTIONS',
    CLEAR_BOUNDARIES: 'CLEAR_BOUNDARIES',
    SET_ZOOM_TOGGLE: 'SET_ZOOM_TOGGLE',
    AUTO_TOGGLE_BOUNDARY_VISIBILITY: 'AUTO_TOGGLE_BOUNDARY_VISIBILITY',
    RESET_ZOOM_TOGGLE_BOUNDARY_VISIBILITY: 'RESET_ZOOM_TOGGLE_BOUNDARY_VISIBILITY',
    SET_LOT_AREA: 'SET_LOT_AREA',
    RESET_HAS_SMART_SELECTIONS_LOADED: 'RESET_HAS_SMART_SELECTIONS_LOADED',
    LOAD_HIGH_RES_COLLECTIONS: 'LOAD_HIGH_RES_COLLECTIONS',
    LOAD_HIGH_RES_COLLECTIONS_SUCCESS: 'LOAD_HIGH_RES_COLLECTIONS_SUCCESS',
    LOAD_HIGH_RES_COLLECTIONS_FAILURE: 'LOAD_HIGH_RES_COLLECTIONS_FAILURE',
    LOAD_HIGH_RES_LAYERS: 'LOAD_HIGH_RES_LAYERS',
    LOAD_HIGH_RES_LAYERS_SUCCESS: 'LOAD_HIGH_RES_LAYERS_SUCCESS',
    LOAD_HIGH_RES_LAYERS_FAILURE: 'LOAD_HIGH_RES_LAYERS_FAILURE',
    SET_HIGH_RES_CURRENT_DATE: 'SET_HIGH_RES_CURRENT_DATE',
    NEXT_HIGH_RES_LAYER: 'NEXT_HIGH_RES_LAYER',
    PREV_HIGH_RES_LAYER: 'PREV_HIGH_RES_LAYER',
    SET_BOUNDARIES: 'SET_BOUNDARIES'
};
