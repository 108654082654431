import { useEffect } from 'react';
var useWorkerCallback = function(worker, callback) {
    var _window;
    useEffect(function() {
        var _window;
        if (!((_window = window) === null || _window === void 0 ? void 0 : _window.Worker)) {
            return;
        }
        worker.onmessage = function(e) {
            callback(e.data);
        };
        return function() {
            worker.terminate();
        };
    }, [
        (_window = window) === null || _window === void 0 ? void 0 : _window.Worker,
        worker
    ]);
    return function(data) {
        worker.postMessage(data);
    };
};
export default useWorkerCallback;
