import { createBrowserHistory } from 'history';
import routes from './constants';
var history = createBrowserHistory();
if (process.env.PIPEDRIVE_COMPANY_ID && process.env.PIPEDRIVE_PLAYBOOK_ID) {
    var toggleLeadbooster = function(location) {
        var pipdriveLeadbooster = document.querySelector('#LeadboosterContainer');
        if (!pipdriveLeadbooster) {
            return;
        }
        var pipdriveLeadboosterWrapper = document.querySelector('#LeadboosterWrapper');
        if (!pipdriveLeadboosterWrapper) {
            var wrapper = document.createElement('div');
            wrapper.id = 'LeadboosterWrapper';
            pipdriveLeadbooster.parentNode.insertBefore(wrapper, pipdriveLeadbooster);
            wrapper.appendChild(pipdriveLeadbooster);
            pipdriveLeadboosterWrapper = document.querySelector('#LeadboosterWrapper');
        }
        if (location.pathname === routes.dashboard) {
            pipdriveLeadboosterWrapper.classList.add('active');
        } else {
            pipdriveLeadboosterWrapper.classList.remove('active');
        }
    };
    if (LeadBooster) {
        LeadBooster.on('initialized', function() {
            toggleLeadbooster(window.location);
        });
    }
    history.listen(toggleLeadbooster);
}
export default history;
