export var AERIAL = 'aerial';
export var DRONE = 'drone';
export var EXTERIOR = 'exterior';
export var INTERIOR = 'interior';
export var STREETSCAPE = 'streetscape';
export var UNKNOWN = 'unknown';
export var STATUS = {
    COMPLETED: 'completed',
    DRAFT: 'draft',
    IN_PROGRESS: 'inprogress',
    PENDING: 'pending',
    DEMO: 'demo'
};
export var ACTIVE_STATUS = [
    STATUS.IN_PROGRESS,
    STATUS.PENDING
];
export var DETAILED_STATUS = {
    COMPLETED: 'Completed',
    DRAFT: 'Draft',
    IN_PROGRESS: 'In progress',
    PENDING: 'Pending'
};
export var EXCLUDED_COLUMNS_ON_COPY_JOB = [
    'user_project',
    'has_customer',
    'point_clouds',
    'project',
    'coverage',
    'detailed_status',
    'quote',
    'user_order'
];
export var LEAD_CHECKOUT = 'checkout';
export var LEAD_PAYMENT_PAGE = 'payment_page';
export var LEAD_CONFIRMATION_PAGE = 'confirmation_page';
export var LEAD_START_ESSENTIAL = 'start_essential';
