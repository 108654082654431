function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _to_array(arr) {
    return _array_with_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_rest();
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function _ts_generator(thisArg, body) {
    var f, y, t, g, _ = {
        label: 0,
        sent: function() {
            if (t[0] & 1) throw t[1];
            return t[1];
        },
        trys: [],
        ops: []
    };
    return g = {
        next: verb(0),
        "throw": verb(1),
        "return": verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function() {
        return this;
    }), g;
    function verb(n) {
        return function(v) {
            return step([
                n,
                v
            ]);
        };
    }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while(_)try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [
                op[0] & 2,
                t.value
            ];
            switch(op[0]){
                case 0:
                case 1:
                    t = op;
                    break;
                case 4:
                    _.label++;
                    return {
                        value: op[1],
                        done: false
                    };
                case 5:
                    _.label++;
                    y = op[1];
                    op = [
                        0
                    ];
                    continue;
                case 7:
                    op = _.ops.pop();
                    _.trys.pop();
                    continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                        _ = 0;
                        continue;
                    }
                    if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
                        _.label = op[1];
                        break;
                    }
                    if (op[0] === 6 && _.label < t[1]) {
                        _.label = t[1];
                        t = op;
                        break;
                    }
                    if (t && _.label < t[2]) {
                        _.label = t[2];
                        _.ops.push(op);
                        break;
                    }
                    if (t[2]) _.ops.pop();
                    _.trys.pop();
                    continue;
            }
            op = body.call(thisArg, _);
        } catch (e) {
            op = [
                6,
                e
            ];
            y = 0;
        } finally{
            f = t = 0;
        }
        if (op[0] & 5) throw op[1];
        return {
            value: op[0] ? op[1] : void 0,
            done: true
        };
    }
}
var _Math, _Math1;
import * as turf from '@turf/turf';
import axios from 'axios';
import _ from 'lodash/fp';
import { PRODUCT_DATA } from '../components/mapView/constants';
import { computeBuffered, computeIntersection, computeUnionMulti } from '../components/mapView/geometry';
import { drawProductLayers } from '../workers/drawSelectionProducts.worker';
import { getCoverageLabels } from '../workers/getCoverageLabels.worker';
import { computeSurroundingPolygonFromPolylineNoGM } from '../workers/utilities';
import assert from './assert';
import { formatMonth } from './date';
import * as mapUtils from './map';
var MIN_BUFFER_M = 5;
var getBottomLeft = function(xy) {
    return [
        (_Math = Math).min.apply(_Math, _to_consumable_array(xy.map(function(c) {
            return c[0];
        }))),
        (_Math1 = Math).min.apply(_Math1, _to_consumable_array(xy.map(function(c) {
            return c[1];
        })))
    ];
};
var shift = function(xy, dx, dy) {
    return xy.map(function(param) {
        var _param = _sliced_to_array(param, 2), x = _param[0], y = _param[1];
        return [
            x + dx,
            y + dy
        ];
    });
};
var isClockwise = function(xy) {
    var area = 0;
    for(var i = 0; i < xy.length; i++){
        var j = (i + 1) % xy.length;
        area += xy[i][1] * xy[j][0];
        area -= xy[j][1] * xy[i][0];
    }
    return area > 0;
};
// converts lat lng coordinates to pixel values
var latLngToXy = function(projection, scale, ne, sw, coords) {
    var isLatFirst = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : true;
    return coords.map(function(coord) {
        var _ref = _sliced_to_array(isLatFirst ? coord : [
            coord[1],
            coord[0]
        ], 2), lat = _ref[0], lng = _ref[1];
        var point = projection.fromLatLngToPoint(new google.maps.LatLng({
            lat: lat,
            lng: lng
        }));
        return [
            (point.x - sw.x) * scale,
            (point.y - ne.y) * scale
        ];
    });
};
var polygonLatLngToGeojson = function(coords, isLatFirst) {
    return {
        type: 'Polygon',
        coordinates: [
            _to_consumable_array(coords).concat([
                coords[0]
            ]).map(function(c) {
                return isLatFirst ? [
                    c[1],
                    c[0]
                ] : c;
            })
        ]
    };
};
var layerToPaths = function(projection, scale, ne, sw, layer, dx, dy) {
    var _process_polygon = function(polygonCoords) {
        return polygonCoords.map(function(coords) {
            var xy = latLngToXy(projection, scale, ne, sw, coords, false);
            return shift(xy, dx, dy);
        });
    };
    var geom = layer.geometry;
    assert([
        'Polygon',
        'MultiPolygon'
    ].includes(geom.type));
    var paths = geom.type === 'MultiPolygon' ? _.flatMap(_process_polygon, geom.coordinates) : _process_polygon(geom.coordinates);
    return paths;
};
var translateBufferOffset = function(coords, isLatFirst, buffer) {
    var poly = polygonLatLngToGeojson(coords, isLatFirst);
    var distance = buffer * Math.SQRT2;
    var translated = turf.transformTranslate(poly, distance, 135, {
        units: 'meters'
    });
    return translated.coordinates[0];
};
var regionToPaths = function(projection, scale, ne, sw, region, buffer) {
    // convert `coords` to pixels, shifted such that bottom-left pixel coincides with 0,0
    var _calcIncludeXy = function(coords) {
        var isLatFirst = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
        var base = latLngToXy(projection, scale, ne, sw, coords, isLatFirst);
        var translated = latLngToXy(projection, scale, ne, sw, translateBufferOffset(coords, isLatFirst, buffer).slice(0, -1), false);
        var bl = getBottomLeft(base);
        var blT = getBottomLeft(translated);
        var tx = blT[0] - bl[0];
        var ty = blT[1] - bl[1];
        // line up bottom left with 0, 0
        var _ref = [
            -bl[0] + tx,
            -bl[1] + ty
        ], dx = _ref[0], dy = _ref[1];
        translated = shift(base, dx, dy);
        return [
            translated,
            [
                dx,
                dy
            ]
        ];
    };
    var includeXy;
    var dx;
    var dy;
    var excludeXys = [];
    if (region.type === 'Polyline') {
        var surroundingPolygonCoords = computeSurroundingPolygonFromPolylineNoGM(region.include)[0];
        var ref, ref1;
        ref = _sliced_to_array(_calcIncludeXy(surroundingPolygonCoords, false), 2), includeXy = ref[0], ref1 = _sliced_to_array(ref[1], 2), dx = ref1[0], dy = ref1[1], ref1, ref;
    } else if (region.type === 'Polygon' || region.type === 'Rectangle') {
        var _region_exclude;
        var ref2, ref3;
        ref2 = _sliced_to_array(_calcIncludeXy(region.include, true), 2), includeXy = ref2[0], ref3 = _sliced_to_array(ref2[1], 2), dx = ref3[0], dy = ref3[1], ref3, ref2;
        // return value always a MultiPolygon
        var excludeUnion = ((_region_exclude = region.exclude) === null || _region_exclude === void 0 ? void 0 : _region_exclude.length) > 0 ? computeIntersection(// don't double up on same area
        computeUnionMulti(region.exclude.map(function(coords) {
            return polygonLatLngToGeojson(coords);
        })), // make sure exclusions don't leave include geometry
        polygonLatLngToGeojson(region.include)) : null;
        var isIncludeClockwise = isClockwise(includeXy);
        excludeXys = excludeUnion ? excludeUnion.coordinates.map(function(param) {
            var _param = _sliced_to_array(param, 1), exclude = _param[0];
            var xy = shift(latLngToXy(projection, scale, ne, sw, exclude, false), dx, dy);
            // exclusions must be opposite order, source: https://stackoverflow.com/a/11878784
            if (isClockwise(xy) === isIncludeClockwise) {
                xy = xy.reverse();
            }
            return xy;
        }) : [];
    } else {
        throw new Error("unknown region type: ".concat(region.type));
    }
    return {
        paths: [
            includeXy,
            excludeXys
        ],
        displacement: [
            dx,
            dy
        ]
    };
};
var computeBoundsWidthAndHeight = function(bounds) {
    var _ne = bounds.getNorthEast();
    var _sw = bounds.getSouthWest();
    var ne = turf.point([
        _ne.lng(),
        _ne.lat()
    ]);
    var sw = turf.point([
        _sw.lng(),
        _sw.lat()
    ]);
    var nw = turf.point([
        _sw.lng(),
        _ne.lat()
    ]);
    var width = turf.distance(nw, ne, {
        units: 'meters'
    });
    var height = turf.distance(sw, nw, {
        units: 'meters'
    });
    return {
        width: width,
        height: height
    };
};
var computeMapScreenshot = function() {
    var _ref = _async_to_generator(function(map, mapDim, selection, coverage) {
        var projection, bounds, ne, sw, regionBounds, regionSize, buffer, zoom, scale, productCoverage, _mapUtils_convertToGeoJson, _mapUtils_convertToGeoJson_features, _mapUtils_convertToGeoJson_features_, drawRegion, layers, labels, _regionToPaths, regionPaths, _regionToPaths_displacement, dx, dy, layerPaths, regionBuffered, bbox, regionBufferedXy, bboxXy, labelsXy, _mapTileToXy, mapTiles, mapTilesXy;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    projection = map.getProjection();
                    bounds = map.getBounds();
                    ne = projection.fromLatLngToPoint(bounds.getNorthEast());
                    sw = projection.fromLatLngToPoint(bounds.getSouthWest());
                    /* ---------- SHAPES ---------- */ regionBounds = mapUtils.getRegionBounds(selection.region);
                    // we'll set the buffer to the min of 5 and (0.05)*x
                    //  where x is the larger of the width and height of the region
                    regionSize = computeBoundsWidthAndHeight(regionBounds);
                    buffer = Math.max(MIN_BUFFER_M, 0.05 * Math.max(regionSize.width, regionSize.height));
                    zoom = Math.floor(mapUtils.getGMBoundsZoomLevel(regionBounds, mapDim, buffer));
                    scale = Math.pow(2, zoom);
                    /* ---------- DEBUGGING ---------- */ // const TEST_COORD = [-37.810272, 144.962646]; // CBD
                    // const [testXy] = latLngToXy(projection, scale, ne, sw, [TEST_COORD], true);
                    // const [testLatLng] = xyToLatLng(projection, scale, ne, sw, [testXy]);
                    // console.log('SHOULD = TEST_COORD', testXy, testLatLng);
                    // const TEST_SCALE = Math.pow(2, 19);
                    // const [TEST_TILE_X, TEST_TILE_Y] = [473305, 321717];
                    // const testLatLng = tileXyToLatLng(TEST_SCALE, TEST_TILE_X, TEST_TILE_Y);
                    // const testTile = latLngToTile(TEST_SCALE, testLatLng.lat, testLatLng.lng);
                    /* -------------------- */ // get coverage layers
                    productCoverage = _.find([
                        'category_name',
                        selection.category_name
                    ], coverage);
                    _mapUtils_convertToGeoJson = mapUtils.convertToGeoJson([
                        selection
                    ]), _mapUtils_convertToGeoJson_features = _sliced_to_array(_mapUtils_convertToGeoJson.features, 1), _mapUtils_convertToGeoJson_features_ = _mapUtils_convertToGeoJson_features[0], drawRegion = _mapUtils_convertToGeoJson_features_.geometry;
                    layers = drawProductLayers(productCoverage, drawRegion, false, true, true);
                    labels = getCoverageLabels({
                        coverage: coverage,
                        selections: [
                            selection
                        ]
                    });
                    // get paths (for display) and displacement for region
                    _regionToPaths = regionToPaths(projection, scale, ne, sw, selection.region, buffer), regionPaths = _regionToPaths.paths, _regionToPaths_displacement = _sliced_to_array(_regionToPaths.displacement, 2), dx = _regionToPaths_displacement[0], dy = _regionToPaths_displacement[1];
                    // get paths (for display) for layers
                    layerPaths = _.flatMap(function(layer) {
                        return layerToPaths(projection, scale, ne, sw, layer, dx, dy);
                    }, layers);
                    regionBuffered = computeBuffered(drawRegion, buffer);
                    bbox = getBbox(regionBuffered.coordinates[0]);
                    regionBufferedXy = latLngToXy(projection, scale, ne, sw, regionBuffered.coordinates[0], false);
                    bboxXy = getBbox(regionBufferedXy);
                    labelsXy = labels.map(function(label) {
                        var xy = latLngToXy(projection, scale, ne, sw, [
                            label.position
                        ], false);
                        xy = shift(xy, dx, dy);
                        var _xy_ = _sliced_to_array(xy[0], 2), x = _xy_[0], y = _xy_[1];
                        return _object_spread_props(_object_spread({}, label), {
                            x: x,
                            y: y
                        });
                    });
                    /* -------------------- */ /* ---------- TILES ---------- */ _mapTileToXy = function(mapTile) {
                        var _latLngToXy = _sliced_to_array(latLngToXy(projection, scale, ne, sw, [
                            [
                                mapTile.tile.lng,
                                mapTile.tile.lat
                            ]
                        ], false), 1), _latLngToXy_ = _sliced_to_array(_latLngToXy[0], 2), x = _latLngToXy_[0], y = _latLngToXy_[1];
                        var ref, ref1;
                        ref = _sliced_to_array(shift([
                            [
                                x,
                                y
                            ]
                        ], dx, dy), 1), ref1 = _sliced_to_array(ref[0], 2), x = ref1[0], y = ref1[1], ref1, ref;
                        x = Math.floor(x);
                        y = Math.floor(y);
                        return _.flow(_.set([
                            'tile',
                            'x'
                        ], x), _.set([
                            'tile',
                            'y'
                        ], y))(mapTile);
                    };
                    return [
                        4,
                        getMapTilesInBbox(projection, zoom, ne, sw, bbox)
                    ];
                case 1:
                    mapTiles = _state.sent();
                    mapTilesXy = mapTiles.map(_mapTileToXy);
                    /* -------------------- */ return [
                        2,
                        {
                            regionPaths: regionPaths,
                            layerPaths: layerPaths,
                            bbox: bbox,
                            bboxXy: bboxXy,
                            zoom: zoom,
                            mapTilesXy: mapTilesXy,
                            labelsXy: labelsXy
                        }
                    ];
            }
        });
    });
    return function computeMapScreenshot(map, mapDim, selection, coverage) {
        return _ref.apply(this, arguments);
    };
}();
var xyToPathString = function(xy) {
    var _xy = _to_array(xy), _xy_ = _sliced_to_array(_xy[0], 2), x0 = _xy_[0], y0 = _xy_[1], rest = _xy.slice(1);
    return [
        "M".concat(x0, " ").concat(y0)
    ].concat(_to_consumable_array(rest.map(function(param) {
        var _param = _sliced_to_array(param, 2), x = _param[0], y = _param[1];
        return "L".concat(x, " ").concat(y);
    }))).join(' ');
};
var createSvgPath = function(group, paths) {
    var category_name = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : null;
    var color = category_name ? PRODUCT_DATA.entities[category_name].display_color : '#9b9b9b';
    return group.path(paths.map(function(path) {
        return xyToPathString(path);
    }).join(' ')).fill({
        color: color,
        opacity: 0.5
    }).attr('stroke-width', 5.5).stroke({
        color: color,
        opacity: 1.0
    }).attr('fill-rule', 'evenodd'); // use fill-rule=evenodd for exclusions, see https://codepen.io/SitePoint/pen/yLEgqv
};
// we need this cos canvas.toDataURL() not available with OffscreenCanvas
var toDataURL = function() {
    var _ref = _async_to_generator(function(data) {
        return _ts_generator(this, function(_state) {
            return [
                2,
                new Promise(function(ok) {
                    var reader = new FileReader();
                    reader.addEventListener('load', function() {
                        return ok(reader.result);
                    });
                    reader.readAsDataURL(data);
                })
            ];
        });
    });
    return function toDataURL(data) {
        return _ref.apply(this, arguments);
    };
}();
var waitImageLoad = function(img) {
    return new Promise(function(ok, reject) {
        img.onload = function() {
            return ok(img);
        };
        img.onerror = function(err) {
            console.error(err);
            reject(err);
        };
    });
};
var LABEL_STYLES = {
    padding: [
        3,
        3
    ]
};
// returns a data URL that can be passed into downloadFile()
//  takes in a SVG() instance using svg.js
var drawMapScreenshot = function() {
    var _ref = _async_to_generator(function(draw, mapTilesXy, labelsXy) {
        var svgBlob, url, shapeImg, canvas, ctx, mapTileImages, blob;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    labelsXy.forEach(function(label) {
                        var _createText = function(g) {
                            var text = g.text(formatMonth(label.dateAcquired * 1000)).font({
                                family: 'sans-serif',
                                size: 14,
                                weight: 'bold',
                                verticalAlign: 'middle'
                            }).fill('#ffffff').attr('alignment-baseline', 'top');
                            return text;
                        };
                        var g = draw.group();
                        _createText(g);
                        var _ref = [
                            g.width(),
                            g.height()
                        ], w = _ref[0], h = _ref[1];
                        var _LABEL_STYLES_padding = _sliced_to_array(LABEL_STYLES.padding, 2), px = _LABEL_STYLES_padding[0], py = _LABEL_STYLES_padding[1];
                        var _ref1 = [
                            w + 2 * px,
                            h + 2 * py
                        ], rw = _ref1[0], rh = _ref1[1];
                        g.rect(rw, rh) // top-left is 0,0 move
                        .fill(label.displayColor)// .move(label.x - w*0.5, label.y);
                        .move(label.x - rw * 0.5, label.y - rh * 0.5);
                        var text = _createText(draw.group());
                        text.move(label.x - w * 0.5, label.y - h * 0.5);
                        // text.move(0, 0); // this should be top-left
                        console.log(draw.svg());
                    });
                    svgBlob = new Blob([
                        draw.svg()
                    ], {
                        type: 'image/svg+xml'
                    });
                    url = URL.createObjectURL(svgBlob);
                    shapeImg = new Image();
                    shapeImg.width = draw.width();
                    shapeImg.height = draw.height();
                    shapeImg.src = url;
                    return [
                        4,
                        waitImageLoad(shapeImg)
                    ];
                case 1:
                    _state.sent();
                    canvas = new OffscreenCanvas(shapeImg.width, shapeImg.height);
                    ctx = canvas.getContext('2d');
                    return [
                        4,
                        Promise.all(mapTilesXy.map(function(mapTile) {
                            return new Promise(function(ok, reject) {
                                var tileImg = new Image();
                                tileImg.width = TILE_SIZE;
                                tileImg.height = TILE_SIZE;
                                tileImg.src = URL.createObjectURL(mapTile.data);
                                waitImageLoad(tileImg).then(function(img) {
                                    return ok([
                                        mapTile,
                                        img
                                    ]);
                                }).catch(reject);
                            });
                        }))
                    ];
                case 2:
                    mapTileImages = _state.sent();
                    mapTileImages.forEach(function(param) {
                        var _param = _sliced_to_array(param, 2), tile = _param[0].tile, tileImg = _param[1];
                        ctx.drawImage(tileImg, tile.x, tile.y, TILE_SIZE, TILE_SIZE);
                    });
                    ctx.drawImage(shapeImg, 0, 0, shapeImg.width, shapeImg.height);
                    return [
                        4,
                        canvas.convertToBlob({
                            type: 'image/jpeg',
                            quality: 1
                        })
                    ];
                case 3:
                    blob = _state.sent();
                    return [
                        4,
                        toDataURL(blob)
                    ];
                case 4:
                    return [
                        2,
                        _state.sent()
                    ];
            }
        });
    });
    return function drawMapScreenshot(draw, mapTilesXy, labelsXy) {
        return _ref.apply(this, arguments);
    };
}();
var getBbox = function(path) {
    var xMin = null;
    var yMin = null;
    var xMax = null;
    var yMax = null;
    path.forEach(function(param) {
        var _param = _sliced_to_array(param, 2), x = _param[0], y = _param[1];
        if (xMin === null || x < xMin) xMin = x;
        if (yMin === null || y < yMin) yMin = y;
        if (xMax === null || x > xMax) xMax = x;
        if (yMax === null || y > yMax) yMax = y;
    });
    return {
        xMin: xMin,
        yMin: yMin,
        xMax: xMax,
        yMax: yMax
    };
};
var rad2deg = function(x) {
    return x * (180 / Math.PI);
};
var TILE_SIZE = 256;
var latLngToTile = function(scale, lat, lng) {
    var project = // The mapping between latitude, longitude and pixels is defined by the web
    // mercator projection.
    //  source: https://developers.google.com/maps/documentation/javascript/examples/map-coordinates
    function project(latLng) {
        var sinY = Math.sin(latLng.lat() * Math.PI / 180);
        // Truncating to 0.9999 effectively limits latitude to 89.189. This is
        // about a third of a tile past the edge of the world tile.
        sinY = Math.min(Math.max(sinY, -0.9999), 0.9999);
        return new google.maps.Point(TILE_SIZE * (0.5 + latLng.lng() / 360), TILE_SIZE * (0.5 - Math.log((1 + sinY) / (1 - sinY)) / (4 * Math.PI)));
    };
    var worldCoord = project(new google.maps.LatLng({
        lat: lat,
        lng: lng
    }));
    var pixelCoord = new google.maps.Point(// it might be technically more accurate to remove Math.floor here but the difference is negligible
    Math.floor(worldCoord.x * scale), Math.floor(worldCoord.y * scale));
    var px = pixelCoord.x;
    var py = pixelCoord.y;
    var xTile = Math.floor(px / TILE_SIZE);
    var yTile = Math.floor(py / TILE_SIZE);
    return {
        lat: lat,
        lng: lng,
        xTile: xTile,
        yTile: yTile
    };
};
var tileXyToLatLng = function(scale, xTile, yTile) {
    var invProject = function invProject(worldCoord) {
        var latRad = 2 * Math.atan(Math.exp(2 * Math.PI * (0.5 - worldCoord.y / TILE_SIZE))) - Math.PI / 2;
        return {
            lng: 360.0 * (worldCoord.x / TILE_SIZE - 0.5),
            lat: rad2deg(latRad)
        };
    };
    var pixelCoord = new google.maps.Point(xTile * TILE_SIZE, yTile * TILE_SIZE);
    var worldCoord = new google.maps.Point(pixelCoord.x / scale, pixelCoord.y / scale);
    var _invProject = invProject(worldCoord), lat = _invProject.lat, lng = _invProject.lng;
    return {
        lat: lat,
        lng: lng
    };
};
var bboxToTiles = function(projection, scale, ne, sw, bbox) {
    var swTile = latLngToTile(scale, bbox.yMin, bbox.xMin);
    var neTile = latLngToTile(scale, bbox.yMax, bbox.xMax);
    var tiles = [];
    for(var xTile = swTile.xTile; xTile <= neTile.xTile; xTile++){
        for(var yTile = neTile.yTile; yTile <= swTile.yTile; yTile++){
            var _tileXyToLatLng = tileXyToLatLng(scale, xTile, yTile), lat = _tileXyToLatLng.lat, lng = _tileXyToLatLng.lng;
            tiles.push({
                lat: lat,
                lng: lng,
                xTile: xTile,
                yTile: yTile
            });
        }
    }
    return tiles;
};
var getMapTileDataAt = function() {
    var _ref = _async_to_generator(function(xTile, yTile, zoom) {
        var resp;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    return [
                        4,
                        axios.get("".concat(process.env.AEROMETREX_TILES_URL, "/").concat(process.env.AEROMETREX_TILES_KEY, "/service?SERVICE=WMTS&REQUEST=GetTile&LAYER=Australia_latest&FORMAT=png&VERSION=1.0.0&STYLE=default&TileMatrixSet=webmercator&TileMatrix=").concat(zoom, "&TileRow=").concat(yTile, "&TileCol=").concat(xTile), {
                            responseType: 'blob',
                            headers: {
                                'Access-Control-Allow-Origin': '*'
                            }
                        })
                    ];
                case 1:
                    resp = _state.sent();
                    return [
                        2,
                        resp.data
                    ];
            }
        });
    });
    return function getMapTileDataAt(xTile, yTile, zoom) {
        return _ref.apply(this, arguments);
    };
}();
var getMapTilesInBbox = function() {
    var _ref = _async_to_generator(function(projection, zoom, ne, sw, bbox) {
        var zoomFloored, scale, mapTiles;
        return _ts_generator(this, function(_state) {
            switch(_state.label){
                case 0:
                    zoomFloored = Math.floor(zoom);
                    scale = Math.pow(2, zoomFloored);
                    return [
                        4,
                        Promise.all(bboxToTiles(projection, scale, ne, sw, bbox).map(function(tile) {
                            return new Promise(function(ok, reject) {
                                return getMapTileDataAt(tile.xTile, tile.yTile, zoomFloored).then(function(data) {
                                    return ok({
                                        tile: tile,
                                        data: data
                                    });
                                }).catch(function(err) {
                                    return reject(err);
                                });
                            });
                        }))
                    ];
                case 1:
                    mapTiles = _state.sent();
                    return [
                        2,
                        mapTiles
                    ];
            }
        });
    });
    return function getMapTilesInBbox(projection, zoom, ne, sw, bbox) {
        return _ref.apply(this, arguments);
    };
}();
// source: POSIX "Fully portable filenames" in https://en.wikipedia.org/wiki/Filename
var toValidFileStem = function(str) {
    var DEFAULT = 'Shape';
    var r = /[^\w\-. ]/g;
    var proposed = str.replace(r, '').trim();
    if (proposed.length === 0) {
        // empty file name isn't valid
        return DEFAULT;
    }
    return proposed;
};
var mapScreenshotUtils = {
    computeMapScreenshot: computeMapScreenshot,
    createSvgPath: createSvgPath,
    drawMapScreenshot: drawMapScreenshot,
    toValidFileStem: toValidFileStem
};
export default mapScreenshotUtils;
