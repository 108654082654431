function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { TOURS, TOUR_MODAL_START_CONFIG, hightlightedAreaStyling, popoverCustomDefaultStyling, MODAL_TOUR_NAMES } from './config';
import { TOUR_2D_MAP } from './tour2dMap';
import { TOUR_3D_VIEWER } from './tour3dViewer';
import { TOUR_FILE_MANAGER } from './tourFileManager';
var _obj;
export var GET_STARTED_TOURS = (_obj = {}, _define_property(_obj, TOURS.TOUR_2D_MAP, TOUR_2D_MAP), _define_property(_obj, TOURS.TOUR_FILE_MANAGER, TOUR_FILE_MANAGER), _define_property(_obj, TOURS.TOUR_3D_VIEWER, TOUR_3D_VIEWER), _obj);
export { TOURS, hightlightedAreaStyling, popoverCustomDefaultStyling, TOUR_MODAL_START_CONFIG, MODAL_TOUR_NAMES };
